import Link from 'next/link'
import { CompetitionUrlPart } from '../../../api'
import st from './SidebarBreadcrumbs.module.scss'

type Props = {
    competition: CompetitionUrlPart[],
}

export const SidebarBreadcrumbs = ({competition}: Props) => {
    const countryPart = competition.find((i) => i.type === 'country')
    const competitionPart = competition.find((i) => i.type === 'competition')
    return (
        <nav className={st.container}>
            <ul className='flex flex-align'>
                {countryPart && (
                    <li className={`${st.crumb} flex flex-align`}>
                        <Link href={countryPart.url}>
                            {countryPart.title}
                        </Link>
                    </li>
                )}
                {competitionPart && (
                    <li className={`${st.crumb} flex flex-align`}>
                        {competitionPart.title}
                    </li>
                )}
            </ul>
        </nav>
    )
}