import { RatingUefaTeam } from '../../../api'
import st from './TableRating.module.scss'
import { Logo } from '../../../components/logo'
import { TeamName } from '../../../components/team'
import clsx from 'clsx'
import { MatchesLastBadges } from '../../../components/match'
import { useScreen } from '../../../hooks'
import Image from 'next/image'
import search_not_found from '../../../public/img/search-not-found.svg'

type Props = {
    ranking: RatingUefaTeam[],
    groupSelected: number,
    search: string,
    onMatchSelect: (id: number) => void,
}

export const TableRating = ({ranking, groupSelected, search, onMatchSelect}: Props) => {
    const {width} = useScreen()
    const isMatchSidebarVisible = width > 1150

    let rankingFiltered = ranking ?? []

    if (groupSelected) {
        rankingFiltered = rankingFiltered.filter((item) => item.group_id === groupSelected)
    }
    if (search) {
        const searchString = search.toLowerCase()
        rankingFiltered = rankingFiltered.filter((item) => item.team1.title.toLowerCase().startsWith(searchString))
    }

    return (
        <>
            <div className={st.header}>
                <span className={st.headerLabel}>
                    рейтинг ФИФА
                    {!!groupSelected && (
                        <>
                            {' | '}
                            <span className='color-black'>
                                конференции
                            </span>
                        </>
                    )}
                </span>
                <span className={clsx(st.headerLabel, st.teamPointsWidth, 'txt-align-center')}>
                    Очки
                </span>
            </div>
            {rankingFiltered.length > 0 && (
                <div className={st.container}>
                    {rankingFiltered.map((item, index) => (
                        <div
                            className={st.team}
                            key={index}
                        >
                            <div className={clsx(st.teamPositionContainer, groupSelected && st.isGroupSelected,)}>
                                <div
                                    className={clsx(
                                        'flex flex-center',
                                        st.teamPosition,
                                    )}
                                >
                                    {item.position}
                                </div>
                                {!!groupSelected && (
                                    <>
                                        <div className={st.positionDivider} />
                                        <div className={st.teamIndex}>
                                            {index + 1}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='grid-with-overflow-ltr flex-align grid--12 mr-auto'>
                                <Logo
                                    type='country'
                                    size={80}
                                    dimension={28}
                                    logoId={item.team1.logo.split('flag')[1]}
                                />
                                <TeamName
                                    team={item.team1}
                                    className={st.teamName}
                                    displayDefault
                                />
                            </div>
                            <MatchesLastBadges
                                lastMatches={item.last_matches}
                                nextMatch={item.next_match}
                                onNextMatchClick={isMatchSidebarVisible && onMatchSelect}
                            />
                            <div className={clsx(st.teamPoints, st.teamPointsWidth)}>
                                {item.points}
                            </div>
                        </div>
                    ))}
                </div>
            )}
            {rankingFiltered.length === 0 && (
                <div className={st.containerNotFound}>
                    <Image
                        src={search_not_found}
                        alt=''
                    />
                    <span>
                        Ничего не найдено
                    </span>
                </div>
            )}
        </>
    )
}