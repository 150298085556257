import Image from 'next/image';
import star from '../../public/img/star.svg';
import {useCustomTranslation} from '../../hooks/useCustomTranslation';
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../hooks';
import moment from 'moment';
import {
    selectFilterDate,
    selectFilterMatchesType,
    selectFilterTime,
    setFilterMatchesType,
} from '../../features/filters';
import NoSsr from '../NoSsr';
import { Button } from '../Button'

type Props = {
    liveMatchesAmount: number
}

export const FilterMatchesType = ({liveMatchesAmount}: Props) => {
    const dispatch = useAppDispatch()
    const { t } = useCustomTranslation('common')

    const matchesDate = useSelector(selectFilterDate)
    const matchesType = useSelector(selectFilterMatchesType)
    const matchesTime = useSelector(selectFilterTime)

    const today = moment().format('YYYY-MM-DD')
    return (
        <NoSsr>
            {!matchesTime && (
                <>
                    <Button
                        isActive={matchesType === 'all'}
                        onClick={() => dispatch((setFilterMatchesType('all')))}
                    >
                        {t('main.filter_all')}
                    </Button>
                    {matchesDate === today && (
                        <Button
                            isActive={matchesType === 'live'}
                            onClick={() => dispatch((setFilterMatchesType('live')))}
                            disabled={liveMatchesAmount === 0}
                            badge={liveMatchesAmount}
                        >
                            <span className='no-wrap'>
                                {t('main.filter_live')}
                            </span>
                        </Button>
                    )}

                    {matchesDate >= today && (
                        <Button
                            isActive={matchesType === 'upcoming'}
                            onClick={() => dispatch((setFilterMatchesType('upcoming')))}
                        >
                            {t('main.filter_upcoming')}
                        </Button>
                    )}
                    {matchesDate <= today && (
                        <Button
                            isActive={matchesType === 'finished'}
                            onClick={() => dispatch((setFilterMatchesType('finished')))}
                        >
                            {t('main.filter_finished')}
                        </Button>
                    )}
                </>
            )}
            <Button
                isActive={matchesType === 'fav'}
                onClick={() => dispatch((setFilterMatchesType('fav')))}
            >
                <Image
                    src={star}
                    alt='favorite'
                    width={16}
                    height={16}
                />
                {t('main.filter_favourites')}
            </Button>
        </NoSsr>
    )
}