import React from 'react'
import Image from 'next/image'
import redcard from '../public/img/incindent/redcard.svg'
import qual from '../public/img/incindent/qual.svg'
import clsx from 'clsx';
import {ElementAnimated} from './animation';

type Props = {
    incindent: {
        text: string
        type: string
    }
    nextIncident: {
        text: string
        type: string
    }
    isLive?: boolean,
}

export default function Incident({ incindent, nextIncident, isLive }: Props) {
    function getType(type: string) {
        switch (type) {
            case 'redcard':
                return redcard
            case 'qual':
                return qual
            default:
                null
        }
    }

    return (
        <div
            className={clsx(
                'wrapper-card-ico',
                incindent.type == 'redcard' && nextIncident?.type == 'redcard' && 'wrapper-card-ico--margin'
            )}
        >
            <ElementAnimated
                value={incindent.type}
                renderElement={(
                     ref,
                     value,
                     cls,
                 ) => (
                     <span
                         ref={ref}
                         className={clsx(cls)}
                     >
                         <Image
                             src={getType(value)}
                             className={'team-ico team-ico--card'}
                             alt='kc'
                             width={14}
                             height={14}
                         />
                     </span>
                 )}
                animation='animationFlash'
                animateCondition={isLive && incindent.type == 'redcard'}
             />
            <div className='tooltip'>
                <span
                    className='tooltip__title'
                    dangerouslySetInnerHTML={{ __html: incindent.text }}
                />
            </div>
        </div>
    )
}
