import moment from 'moment'
import React, {useState, useRef, useEffect, lazy} from 'react'
import Image from 'next/image'
import clsx from 'clsx';
import arrow_left_gray from '../../public/img/arrow_left_gray.svg'
import arrow_right_gray from '../../public/img/arrow_right_gray.svg'
import { useRouter } from 'next/router'
import calendar_ico from '../../public/img/calendar.svg'
import {CalendarProps} from 'react-calendar';
import ico_reset_date_filter from '../../public/img/reset-date-filter.svg';
import {MATCHES_TIME_PERIOD, MatchesTimePeriodValue} from '../../api';
import {useCustomTranslation} from '../../hooks/useCustomTranslation';
import useOutsideAlerter from '../../hooks/outsideClick';
import {useAppDispatch} from '../../hooks';
import {useSelector} from 'react-redux';
import {selectFilterDate, selectFilterTime, setFilterDate, setFilterTime} from '../../features/filters';
import NoSsr from '../NoSsr';

type CalendarComponentWithProps = (props: CalendarProps) => JSX.Element

const MIN_DATE = moment('2000-01-01')
const MAX_DATE = moment(new Date()).add(2, 'M')

export const Calendar = () => {
    const router = useRouter()
    const dispatch = useAppDispatch()
    const { t } = useCustomTranslation('common')

    const matchesDate = useSelector(selectFilterDate)
    const matchesTime = useSelector(selectFilterTime)

    const TIME_PERIODS = [
        {
            id: MATCHES_TIME_PERIOD.ONE_HOUR,
            label: t('dates.1_hour'),
        },
        {
            id: MATCHES_TIME_PERIOD.THREE_HOURS,
            label: t('dates.3_hours'),
        },
        {
            id: MATCHES_TIME_PERIOD.TWELVE_HOURS,
            label: t('dates.12_hours'),
        },
    ]

    const [open, setOpen] = useState<boolean>(false)

    const [CalendarComponent, setCalendarComponent] = useState<CalendarComponentWithProps | null>(null)

    useEffect(() => {
        if (open && !CalendarComponent) {
            const component = lazy(() => import('react-calendar'))
            setCalendarComponent(component)
        }
    }, [open])

    const wrapperRef = useRef(null)
    useOutsideAlerter(wrapperRef, () => setOpen(false))

    const onDateSelect = (newDate: Date) => {
        setOpen(false)
        dispatch(setFilterDate(moment(newDate).format('YYYY-MM-DD')))
    }
    const onTimeSelect = (newPeriod: MatchesTimePeriodValue) => {
        setOpen(false)
        dispatch(setFilterTime(newPeriod))
    }
    const onTimeReset = () => {
        dispatch(setFilterTime(null))
    }

    const setNextDay = () => {
        const nextDay = moment(matchesDate).add(1, 'days')
        if (nextDay.isSameOrBefore(MAX_DATE)) {
            dispatch(setFilterDate(nextDay.format('YYYY-MM-DD')))
        }
    }
    const setPrevDay = () => {
        const prevDay = moment(matchesDate).subtract(1, 'days')
        if (prevDay.isSameOrAfter(MIN_DATE)) {
            dispatch(setFilterDate(prevDay.format('YYYY-MM-DD')))
        }
    }

    const timePeriodLabel = TIME_PERIODS.find(
        (p) => p.id === matchesTime
    )?.label

    return (
        <NoSsr>
            <div className='date-filter' ref={wrapperRef}>
                {timePeriodLabel
                    ? (
                        <div className='date-filter__input-time flex grid--4'>
                            <div
                                className='flex flex-align grid--4 date-filter__content'
                                onClick={() => setOpen(!open)}
                            >
                                <Image
                                    src={calendar_ico}
                                    width={16}
                                    alt=''
                                />
                                <span className='date-filter__title'>
                                    {timePeriodLabel}
                                </span>
                            </div>
                            <button
                                className='flex flex-align'
                                onClick={() => onTimeReset()}
                            >
                                <Image
                                    src={ico_reset_date_filter}
                                    width={16}
                                    alt=''
                                />
                            </button>
                        </div>
                    )
                    : (
                        <div className='date-filter__input'>
                            <button className='date-filter__button' onClick={setPrevDay}>
                                <Image src={arrow_left_gray} alt='' width={14}/>
                            </button>
                            <div
                                className='flex flex-align grid grid--4 date-filter__content'
                                onClick={() => setOpen(!open)}
                            >
                                <Image src={calendar_ico} alt='' width={16}/>
                                <span className='date-filter__title'>
                                    {moment(matchesDate).format('DD/MM')}
                                </span>
                            </div>
                            <button className='date-filter__button' onClick={setNextDay}>
                                <Image src={arrow_right_gray} alt='' width={14}/>
                            </button>
                        </div>
                    )
                }
                {open && (
                    <div className='date-filter__wrapper-calendar'>
                        {CalendarComponent
                            ? (
                                <>
                                    <CalendarComponent
                                        className={matchesTime && 'calendar-time-active'}
                                        value={new Date(matchesDate)}
                                        onChange={onDateSelect}
                                        formatMonthYear={(locale: string, date: Date) =>
                                            moment(date).locale(locale).format('MMMM YY')
                                        }
                                        formatShortWeekday={(locale: string, date: Date) =>
                                            moment(date).locale(locale).format('dd')
                                        }
                                        locale={router.locale}
                                        minDate={MIN_DATE.toDate()}
                                        maxDate={MAX_DATE.toDate()}
                                        defaultView={'month'}
                                        prev2Label={null}
                                        next2Label={null}
                                        prevLabel={
                                            <Image
                                                src={arrow_left_gray}
                                                alt='arrow left'
                                                width={14}
                                            />
                                        }
                                        nextLabel={
                                            <Image
                                                src={arrow_right_gray}
                                                alt='arrow right'
                                                width={14}
                                            />
                                        }
                                        minDetail='year'
                                    />
                                    <div className='date-filter__footer'>
                                        <div className='flex flex-justify grid--4'>
                                            {TIME_PERIODS.map((period) => (
                                                <button
                                                    key={period.id}
                                                    title={period.label}
                                                    className={clsx(
                                                        'date-filter__time-control',
                                                        period.id === matchesTime && 'date-filter__time-control_active'
                                                    )}
                                                    onClick={() => onTimeSelect(period.id)}
                                                >
                                                    {period.label}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className='preloader date-filter__preloader'/>
                            )}
                    </div>
                )}
            </div>
        </NoSsr>
    )
}
