import React from 'react'
import {MatchPlayoffStage} from '../../../../../api';
import { PlayoffPairCard } from './PlayoffPairCard';
import styles from './PlayoffDesktop.module.scss';
import clsx from 'clsx';

type Props = {
    data: MatchPlayoffStage[],
    className?: string,
}

export const PlayoffDesktop = ({ data, className }: Props) => {
    const stagesWithoutThirdPlace = data.filter((i) => i.stage.index !== 0)
    const stageThirdPlace = data.find((i) => i.stage.index === 0)
    return (
        <div className={clsx(styles.grid, styles[`stages_${stagesWithoutThirdPlace.length}`], className)}>
            {stagesWithoutThirdPlace.map((stage, index) => (
                <div
                    className={clsx(styles.column, styles[`columnStage_${stage.stage.index}`])}
                    key={stage.stage.index}
                >
                    <span className={styles.columnTitle}>
                        {stage.stage.title}
                    </span>
                    {stage.pairs.map((pair) => (
                        <PlayoffPairCard
                            key={pair.pair_num}
                            pair={pair}
                        />
                    ))}
                    {/* Display third place in the last column */}
                    {stageThirdPlace && index === stagesWithoutThirdPlace.length - 1 && (
                        <div className={styles.pairThirdPlace}>
                            <span className={styles.columnTitle}>
                                {stageThirdPlace.stage.title}
                            </span>
                            <PlayoffPairCard
                                pair={stageThirdPlace.pairs[0]}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}