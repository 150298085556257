import * as Dialog from '@radix-ui/react-dialog';
import { FU } from '../../../types'
import st from './Drawer.module.scss'
import Image from 'next/image'
import close_modal from '../../../public/img/close-modal.svg'

type Props = {
    title: string,
    content: JSX.Element,
}

export const Drawer: FU<Props> = ({
    children,
    title,
    content,
}) => {
    return (
        <Dialog.Root>
            <Dialog.Trigger asChild>
                {children}
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className={st.overlay} />
                <Dialog.Content className={st.container}>
                    <div className={st.header}>
                        {title}
                        <Dialog.Close
                            className={st.headerCloseButton}
                            aria-label='Close'
                        >
                            <Image
                                src={close_modal}
                                alt='X'
                                width={16}
                                height={16}
                                priority
                            />
                        </Dialog.Close>
                    </div>
                    {content}
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    )
}