import React from 'react'
import clsx from 'clsx';
import Image from 'next/image'
import logo from '../public/img/logotype.svg'
import { useTranslation } from 'next-i18next'

const VirtualBookmaker = ({ odds, full }: any) => {
    const { t } = useTranslation('common')

    return (
        <div className={`maker-block ${ !full ? 'maker-block--small' : null}`}>
            <div className="maker-block__header">
                <div className="flex flex-align grid grid--8">
                    <Image
                        src={logo}
                        alt="Picture of the author"
                        height={22}
                        width={106}
                    />
                    <div className="square-fig"></div>
                    <h5 className="maker-block__title">
                        {t('global.betting_contest')}
                    </h5>
                </div>
            </div>
            <div className="maker-block__content grid grid--8">
                <div className="maker-block__row">
                    <span className="maker-block__subtitle">
                        {t('global.place_bet')}
                    </span>
                    <div className="flex flex-align grid grid--4">
                        {odds?.odds_selects?.map((bet: any, index: number) => (
                            <div
                                key={`odds-maker-${index}`}
                                className={`bet-wrapper bet-wrapper--odds bet-wrapper--maker-odd`}
                            >
                                <span
                                    className={`bet-title ${
                                        !odds?.maker ? 'bet-title--gray' : ''
                                    }`}
                                >
                                    {odds?.maker
                                        ? odds?.maker[index]?.display
                                        : '–'}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="maker-block__row maker-block__row--small">
                    <span className="maker-block__subtitle">
                        {t('match_odds.betting_stats')}
                    </span>
                    <div className="flex flex-align grid grid--4">
                        {odds?.odds_selects?.map((_: any, index: number) => (
                            <div
                                key={`odds-maker-${index}`}
                                className='bet-wrapper bet-wrapper--maker'
                            >
                                <span className={clsx('bet-title', !odds?.maker && 'bet-title--gray')}>
                                    {odds?.maker
                                        ? odds?.maker[index]?.percent + '%'
                                        : '–'}
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VirtualBookmaker
