import { MatchNext, MatchResultCode } from '../../../api'
import st from './MatchesLastBadges.module.scss'
import clsx from 'clsx'
import Link from 'next/link'
import { Logo } from '../../logo'

type Props = {
    lastMatches: MatchResultCode[],
    className?: string,
    nextMatch?: MatchNext,
    onNextMatchClick?: (matchId: number) => void,
}

export const MatchesLastBadges = ({
    lastMatches,
    className,
    nextMatch,
    onNextMatchClick,
}: Props) => {
    return (
        <div className={clsx(st.matchesResults, className)}>
            {lastMatches.map((match, index) => (
                <div
                    className={clsx(st.matchLogo, getMatchResultColor(match))}
                    key={index}
                >
                    {match.toUpperCase()}
                </div>
            ))}
            {nextMatch && (
                onNextMatchClick
                    ? (
                        <button
                            className={clsx(st.matchLogo, st.nextMatch)}
                            onClick={() => onNextMatchClick(nextMatch.match_id)}
                        >
                            <Logo
                                type='teams'
                                size={80}
                                logoId={nextMatch.team.logo}
                                dimension={16}
                            />
                        </button>
                    )
                    : (
                        <Link href={nextMatch.url}>
                            <div className={clsx(st.matchLogo, st.nextMatch)}>
                                <Logo
                                    type='teams'
                                    size={80}
                                    logoId={nextMatch.team.logo}
                                    dimension={16}
                                />
                            </div>
                        </Link>
                    )
            )}
            {!nextMatch && (
                <div className={st.matchLogo} />
            )}
        </div>
    )
}

function getMatchResultColor(matchResult: MatchResultCode) {
    return matchResult === 'w'
        ? st.colorWin
        : matchResult === 'l'
            ? st.colorLose
            : ''
}