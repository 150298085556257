import Link from 'next/link'
import Image from 'next/image'
import female_icon from '../public/img/female.svg'
import React from 'react'

export default function CompetitionItem({ competition }: any) {
    return (
        (competition && (
            <div className="list__item list__item--sidebar">
                <Link
                    href={competition.url}
                    className={`list__link list__link--inside list__link--sidebar ${
                        (competition.gender === 'female' &&
                            'list__link--image') ||
                        null
                    }`}
                >
                    {competition.gender === 'female' ? (
                        <Image
                            className="list__link--icon"
                            src={female_icon}
                            width={20}
                            height={20}
                            alt="female icon"
                        />
                    ) : null}
                    {competition.title}
                </Link>
            </div>
        )) ||
        null
    )
}
