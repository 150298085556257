import React, { forwardRef } from 'react'
import clsx from 'clsx';
import PreloadSidebar from './PreloaderSidebar'

type Props = {
    full?: boolean
    children?: any
    loading?: boolean
    side: 'right' | 'left',
    className?: string,
}

export default forwardRef(function Sidebar(
    { full, children, loading, side, className = '' }: Props,
    ref: any
) {
    return (
        <div
            className={clsx(
                'sidebar',
                `sidebar--${side}`,
                full && 'sidebar--full',
                className
            )}
            ref={ref}
        >
            {loading ? (
                <div className="sidebar__wrapper-block">
                    <PreloadSidebar />
                </div>
            ) : children}
        </div>
    )
})
