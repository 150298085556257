import React from 'react'
import st from './Page404.module.scss'
import clsx from 'clsx';
import arrow_first from '../../public/img/arrow_black.svg'
import imgDesktop from '../../public/img/404-desktop.png'
import imgMobile from '../../public/img/404-mobile.png'
import Image from 'next/image';
import Link from 'next/link';
import {useScreen} from '../../hooks';

export const Page404 = () => {
    const {width} = useScreen()
    return (
        <div className={st.container}>
            {width > 600
                ? (
                    <Image
                        src={imgDesktop}
                        width={600}
                        height={300}
                        alt=""
                    />
                )
                : (
                    <Image
                        src={imgMobile}
                        width={288}
                        height={184}
                        alt=""
                    />
                )
            }
            <h1 className={st.title}>404</h1>
            <div className={clsx(st.description, 'flex flex-column flex-align grid--8')}>
                <p>произошла ошибка</p>
                <p className={st.subtitle}>Простите, но данная страница не найдена</p>
            </div>
            <Link href='/'>
                <button className={clsx(st.button, 'button button--primary-color grid--4')}>
                    <Image
                        src={arrow_first}
                        className={st.buttonIcon}
                        width={12}
                        height={12}
                        alt=''
                    />
                    вернуться на главную
                </button>
            </Link>
        </div>
    )
}