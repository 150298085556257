import React, { useRef, useState, useEffect } from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import logo_book_ocean from '../public/img/logo-book-ocean.svg'
import arrow from '../public/img/arrow-exp.svg'
import {BookmakerLinkRedirect} from './BookmakerLinkRedirect';
import { Logo } from './logo'
type Props = {
    item: any
    match_id: string
    full: boolean
}

export default function Serie({ item, match_id }: Props) {
    const { t } = useTranslation('common')

    const [active, setActive] = useState(false)
    const content = useRef(null)

    const title = useRef(null)
    const subtitle = useRef(null)

    const [animated] = useState(false)

    const [setHeight, setHeightState] = useState('0px')

    // function getSizeElement(titleHeight: number, subtitleHeight: number) {
    //     if (titleHeight > 16 && subtitleHeight > 16) {
    //         return '95px'
    //     } else if (
    //         (titleHeight > 16 && subtitleHeight == 16) ||
    //         (titleHeight == 16 && subtitleHeight > 16)
    //     ) {
    //         return '79px'
    //     } else {
    //         return '68px'
    //     }
    // }

    useEffect(() => {
        setHeightState(active ? `${content.current.scrollHeight}px` : '0px')
    }, [active])

    // useEffect(() => {
    //     setAnimated(false);
    //     setHeightState(active ? `${content.current.scrollHeight}px` : getSizeElement(title.current?.clientHeight, subtitle.current?.clientHeight));
    //     setTimeout(() => {
    //         setAnimated(true);
    //     }, 100);

    // }, [full]);

    return (
        <div
            className={`series__item ${
                animated ? 'series__item--animated' : ''
            }`}
            onClick={() => setActive(!active)}
        >
            <div className="series__item-wrapper">
                <Logo
                    type='teams'
                    size={60}
                    dimension={30}
                    logoId={item.team.logo}
                />
                <div className="">
                    <p className="series__title" ref={title}>
                        {item.serie}
                    </p>
                    <p className="series__subtitle" ref={subtitle}>
                        {t('match_series.stake')} {item.bet}
                    </p>
                </div>
                <button
                    className={`series__expand ${
                        active ? 'series__expand--active' : ''
                    }`}
                >
                    <Image src={arrow} alt="Arrow" height={12} width={12} />
                </button>
            </div>

            <div
                ref={content}
                className="series__bets-wrapper full-width"
                style={{ height: setHeight }}
            >
                {item?.odd && (
                    <>
                        <h5 className="forecast__bets-title series__bookmaker-title  series__bookmaker-title--first">
                            {t('global.best_odd')}
                        </h5>
                        <div className="forecast__bet-wrapper forecast__bet-wrapper--middle mb-16">
                            <div className="flex flex-align">
                                <BookmakerLinkRedirect
                                    bookmakerId={item?.odd.bookmaker.id}
                                    bookmakerLogoId={item?.odd.bookmaker.logo_id}
                                    bookmakerColor={item?.odd.bookmaker.color}
                                >
                                    <div
                                        className="bookmaker-badge"
                                        style={{
                                            backgroundColor: `#${item?.odd.bookmaker?.color}`,
                                        }}
                                    >
                                        <Logo
                                            type='bookmaker_original'
                                            logoId={item?.odd.bookmaker.id}
                                            size={72}
                                            height={24}
                                        />
                                    </div>
                                </BookmakerLinkRedirect>
                            </div>
                            <div className="flex flex-align grid grid--8">
                                <div className="bet">
                                    <span className="bet__title">
                                        {item?.odd.display}
                                    </span>
                                </div>
                                <BookmakerLinkRedirect
                                    bookmakerId={item?.odd.bookmaker.id}
                                    bookmakerLogoId={item?.odd.bookmaker.logo_id}
                                    bookmakerColor={item?.odd.bookmaker.color}
                                    matchId={Number(match_id)}
                                    oddType={item?.odd.odd_type}
                                    oddSelect={item?.odd.odd_select}
                                    period={item?.odd.period}
                                    handicap={item?.odd.handicap}
                                    className="button button--rounded button--upper button--primary-color button--forecast-bet"
                                >
                                    {t('global.place_bet')}
                                </BookmakerLinkRedirect>
                            </div>
                        </div>
                    </>
                )}

                {item?.odd.maker && (
                    <>
                        <h5 className="forecast__bets-title series__bookmaker-title mb-4">
                            {t('global.betting_contest')}
                        </h5>
                        <div className="forecast__bet-wrapper">
                            <Image
                                className="flex flex-align"
                                src={logo_book_ocean}
                                alt="odds ocean"
                                height={30}
                                width={80}
                            />
                            {item?.odd?.maker.value ? (
                                <div className="flex flex-align grid grid--8">
                                    {item?.odd?.maker.value && (
                                        <div className="bet bet__title">
                                            {item?.odd?.maker.display}
                                        </div>
                                    )}
                                    <button className="button button--rounded button--upper button--primary-color button--forecast-bet button--virt-book">
                                        {t('global.to_betslip')}
                                    </button>
                                </div>
                            ) : (
                                <div className="button button--rounded button--upper  button--forecast-bet button--not-avalaible flex flex-align">
                                    {t('global.odd_unavailable')}
                                </div>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
