import Image from 'next/image'
import arrow_first from '../public/img/arrow-gray-new.svg'

interface IProps {
    title?: string
    className?: string
    subtitle?: string
}

export default function HeaderDropdownGray({
    title,
    className,
    subtitle,
}: IProps) {
    return (
        <div className={`dropdown-matches ${className}`}>
            <span className="dropdown-matches__title">{title}</span>
            <div className="flex flex-align grid grid--8">
                {subtitle !== undefined && (
                    <div className="handicap-wrapper">{subtitle}</div>
                )}
                <Image
                    src={arrow_first}
                    className="dropdown-matches__ico"
                    width={12}
                    height={12}
                    alt="arrow-first"
                />
            </div>
        </div>
    )
}
