import React from 'react'
import clsx from 'clsx';
import moment from 'moment'
import {Tooltip} from './Tooltip';

interface IProps {
    match: any
    dark?: boolean
}

export default function StatusBadge({ match, dark }: IProps) {
    if (!match) return null
    const endedMatch = !match.live && moment.utc(new Date()) > moment(match.date_start * 1000)
    const statusDesktop = match.status === 'normal' && match.score?.livetime
        ? match.score.livetime[0]
        : match.status
    const statusMobile = match.status === 'normal' && match.score?.livetime
        ? match.score.livetime[1]
        : match.status
    return (
        <Tooltip content={statusDesktop}>
            <div
                className={clsx(
                    'badge badge--matchday',
                    match.live && 'badge--matchday-live',
                    (endedMatch || match.status !== 'normal') && 'badge--match-live-end',
                    dark && 'badge--dark',
                )}
            >
                <span className='badge__desktop'>
                    {statusDesktop}
                </span>
                <span className='badge__mobile'>
                    {statusMobile}
                </span>
            </div>
        </Tooltip>
    )
}
