import React from 'react'
import Image from 'next/image'

type Props = {
    player: any
}

export default function PlayerTooltipField({ player }: Props) {
    return (
        <div className="flex grid--8">
            <div className="flex flex-align">
                <Image
                    src={`https://i.oddsocean.com/img/logos/teams/${player.flag}.png`}
                    alt="flag-player"
                    width={20}
                    height={20}
                />
            </div>
            <div className="flex flex-column flex-center">
                <p className="field__player-title-tooltip">{player.fullname}</p>
                {player.pos[1] && (
                    <p className="field__player-subtitle-tooltip">
                        {player.pos[1]}
                    </p>
                )}
            </div>
        </div>
    )
}
