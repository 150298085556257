import React from 'react'
import clsx from 'clsx';
import {FU} from '../../../../types';
import st from './ContainerContentSub.module.scss'

/** Wrap content from tabs on League page */
export const ContainerContentSub: FU = ({children}) => (
    <div className={clsx(st.container, 'mt-12')}>
        {children}
    </div>
)