import React, { useEffect, useState } from 'react'
import Image from 'next/image'
import goal from '../../public/img/incindent_ico_color/goal.svg'
import football_yellow from '../../public/img/incindent_ico_color/football_yellow.svg'
import football_substitution from '../../public/img/incindent_ico_color/football_substitution.svg'
import football_yellow_red from '../../public/img/incindent_ico_color/football_yellow_red.svg'
import arrow_right_white from '../../public/img/arrow-right-white.svg'
import football_own_goal from '../../public/img/incindent_ico_color/football_own_goal.svg'
import football_red from '../../public/img/incindent_ico_color/football_red.svg'
import no_events from '../../public/img/no_events.svg'
import football_penalty_miss from '../../public/img/incindent_ico_color/football_penalty_miss.svg'
import football_penalty from '../../public/img/incindent_ico_color/football_penalty.svg'
import { useTranslation } from 'next-i18next'
import MatchIncidents from './MatchIncidents'

type Props = {
    items: any[]
    full: boolean
}

const EVENTS_MAIN = [
    'football_goal',
    'football_penalty',
    'football_own_goal',
    'football_yellow_red',
    'football_red',
]

export default function MatchEvents({ items, full: fullPage }: Props) {
    const { t } = useTranslation('common')

    const [full, setFull] = useState(true)
    const [isBigScreen, setIsBigScreen] = useState(false)
    const refWrapper = React.useRef<HTMLDivElement>(null)

    function toggleFull() {
        setFull(!full)
    }

    useEffect(() => {
        const resizeListener = () => {
            const blockWidth = refWrapper.current.clientWidth
            const newIsBigScreenValue = fullPage ? blockWidth > 600 : false
            setIsBigScreen(newIsBigScreenValue)
        }

        resizeListener()
        window.addEventListener('resize', resizeListener)

        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [fullPage])

    function getIcon(value: string) {
        switch (value) {
            case 'football_goal':
                return goal
            case 'football_yellow':
                return football_yellow
            case 'football_substitution':
                return football_substitution
            case 'football_yellow_red':
                return football_yellow_red
            case 'football_own_goal':
                return football_own_goal
            case 'football_red':
                return football_red
            case 'football_penalty_miss':
                return football_penalty_miss
            case 'football_penalty':
                return football_penalty
        }
    }

    return (
        <div
            ref={refWrapper}
            className="sidebar__wrapper-block"
        >
            <div className="block rating-list">
                <div className="rating-list__header">
                    <h5 className="rating-list__title">
                        {t('match_events.title')}
                    </h5>
                    <button
                        className="button button--rounded button--rating grid grid--4 button--events"
                        style={{ display: 'none' }}
                        onClick={() => toggleFull()}
                    >
                        <span>{t('match_events.all')}</span>
                        <Image
                            src={arrow_right_white}
                            alt="video"
                            width={14}
                            height={14}
                        />
                    </button>
                </div>
                <div className="match-events grid grid--16">
                    {items?.map((period: any, index: number) => (
                        <div
                            key={`incindents-period-${index}`}
                            className="match-events__period-wrapper"
                        >
                            <div className="match-events__period-header">
                                <div className="match-events__line" />
                                <div className="match-events__period-header-content grid--8">
                                    <h6 className="match-events__period-title">
                                        {period.title}
                                    </h6>
                                    <div className="match-events__score-wrapper">
                                        <div className="match-events__score-wrapper-inner grid grid--4">
                                            <div className="match-events__score match-events__score--left">
                                                {period.score[0]}
                                            </div>
                                            <div>:</div>
                                            <div className="match-events__score match-events__score--right">
                                                {period.score[1]}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="match-events__line" />
                            </div>
                            {period.additional_time && (
                                <div className="match-events__add-time-wrapper">
                                    <span>
                                        {t('match_events.extra_time_pre')}
                                        {period.additional_time}
                                        {t('match_events.extra_time_post')}
                                    </span>
                                </div>
                            )}
                            <div className="match-events__content-wrapper grid grid--4">
                                {period.incidents.length > 0 ? (
                                    period.incidents
                                        .filter((event: any) =>
                                            !full
                                                ? EVENTS_MAIN.indexOf(
                                                      event.type
                                                  ) !== -1
                                                : true
                                        )
                                        .map(
                                            (
                                                incindent: any,
                                                indexIncindent: number
                                            ) => (
                                                <div
                                                    key={`incindents-period-${index}-incindent-${indexIncindent}`}
                                                    className={`match-events__event grid grid--8 match-events__event--team-${incindent.team}`}
                                                >
                                                    {isBigScreen ? (
                                                        <span className="match-events__title-player">
                                                            {incindent.full[0]}
                                                            {incindent
                                                                .full[1] ? (
                                                                    <b> / {incindent.full[1]}</b>
                                                            ) : null}
                                                        </span>
                                                    ) : (
                                                        <span className="match-events__title-player">
                                                            {incindent.name}
                                                        </span>
                                                    )}

                                                    {incindent.time && (
                                                        <div className="match-events__title-time">
                                                            <span>
                                                                {incindent.time}
                                                                ´{' '}
                                                                {incindent.additional_time && (
                                                                    <span className="match-events__title-addtime">
                                                                        +
                                                                        {
                                                                            incindent.additional_time
                                                                        }
                                                                    </span>
                                                                )}
                                                            </span>
                                                        </div>
                                                    )}

                                                    <div className="match-events__incindent-ico-wrapper">
                                                        <Image
                                                            src={getIcon(
                                                                incindent.type
                                                            )}
                                                            alt="ico"
                                                            width={16}
                                                            height={16}
                                                        />
                                                        <div className="match-events__tooltip">
                                                            <h5 className="match-events__tooltip-title">
                                                                {
                                                                    incindent.title
                                                                }
                                                            </h5>
                                                            <MatchIncidents
                                                                incindent={
                                                                    incindent
                                                                }
                                                                indexIncindent={
                                                                    indexIncindent
                                                                }
                                                                index={index}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        )
                                ) : (
                                    <div className="flex flex-align flex-column grid grid--8 match-events__add-time-wrapper match-events__title-no-events">
                                        <Image
                                            src={no_events}
                                            alt="ico"
                                            width={32}
                                            height={32}
                                        />
                                        <span className="match-events__title-no-events">
                                            {t('match_events.no_events')}
                                        </span>
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
