import React from 'react'

type Props = {
    type?: string
}

export default function PreloadTopCountries({ type }: Props) {
    return (
        <div
            className={`preloader preloader--top-countries grid grid--4 ${
                type == 'section' ? 'preloader--top-countries-section' : ''
            }`}
        >
            {type !== 'section' && (
                <div className="preloader__item preloader__item--main"></div>
            )}
            {[0, 1, 2, 3, 4, 5, 6].map((index) => (
                <div
                    key={`preloader_item-` + index}
                    className="preloader__item"
                ></div>
            ))}
            {type !== 'section' &&
                [0, 1, 2, 3, 4, 5, 6, 7, 8].map((index) => (
                    <div
                        key={`preloader_item-main` + index}
                        className="preloader__item preloader__item--main"
                    ></div>
                ))}
            {type !== 'section' && <div className="preloader__button"></div>}
        </div>
    )
}
