import React, {useEffect} from 'react'
import clsx from 'clsx'
import {MatchPlayoffPair, MatchPlayoffStage} from '../../../../../api';
import { PlayoffPairCardMobile } from './PlayoffPairCardMobile';
import styles from './PlayoffMobile.module.scss';

type Props = {
    data: MatchPlayoffStage[],
    isSmallContainer: boolean,
    className?: string,
}

type Stage = {
    title: string,
    pairs: MatchPlayoffPair[],
}

export const PlayoffMobile = ({ data, isSmallContainer, className }: Props) => {
    const stagesWithoutThirdPlace = data.filter((i) => i.stage.index !== 0)
    const stageThirdPlace = data.find((i) => i.stage.index === 0)
    let stageMiddleIndex = 0
    const stages = stagesWithoutThirdPlace.reduce((acc, stage, index) => {
        if (stage.pairs.length < 2) {
            stageMiddleIndex = index
            acc.splice(acc.length * 0.5, 0,  { title: stage.stage.title, pairs: stage.pairs })
            return acc
        }

        const fst = stage.pairs.slice(0, stage.pairs.length * 0.5)
        const snd = stage.pairs.slice(fst.length)

        if (acc.length === 0) {
            acc.push({
                title: stage.stage.title,
                pairs: fst,
            })
            acc.push({
                title: stage.stage.title,
                pairs: snd,
            })
            return acc
        }

        acc.splice(acc.length * 0.5, 0, { title: stage.stage.title, pairs: fst })
        acc.splice(Math.round(acc.length * 0.5), 0, { title: stage.stage.title, pairs: snd })

        return acc
    }, [] as Stage[])

    // As PlayoffMobile's showed in many variants (on desktop too, check MainPage sidebar), it's easier to
    // update css var with js, than build "tricky flags and class-names" flow
    const blockRef = React.useRef<HTMLDivElement>()
    useEffect(() => {
        const updateContainerWidthProp = () => {
            blockRef.current.style.setProperty('--container-width-max', blockRef.current.clientWidth + 'px')
        }

        if (blockRef.current) updateContainerWidthProp()

        window.addEventListener('resize', updateContainerWidthProp);

        return () => {
            window.removeEventListener('resize', updateContainerWidthProp);
        }
    }, []);

    return (
        <div
            className={clsx(
                styles.grid,
                styles[`stages_${stagesWithoutThirdPlace.length}`],
                isSmallContainer && styles.isSmall,
                className,
            )}
            ref={blockRef}
        >
            {stages.map((stage, index) => (
                <div
                    className={clsx(
                        styles.column,
                        styles[`rowPairs_${stage.pairs.length}`],
                        index < stageMiddleIndex ? styles.rowConnectorsDown :
                            index > stageMiddleIndex ? styles.rowConnectorsUp : styles.rowMiddle,
                    )}
                    key={index}
                >
                    {stage.pairs.map((pair) => (
                        <PlayoffPairCardMobile
                            key={pair.pair_num}
                            pair={pair}
                        />
                    ))}
                    <span className={styles.columnTitle}>
                        {stage.title}
                    </span>
                    {/* Display third place in the central column */}
                    {stageThirdPlace && index == stageMiddleIndex && (
                        <div className={styles.pairThirdPlace}>
                            <span className={styles.columnTitle}>
                                {stageThirdPlace.stage.title}
                            </span>
                            <PlayoffPairCardMobile
                                pair={stageThirdPlace.pairs[0]}
                            />
                        </div>
                    )}
                </div>
            ))}
        </div>
    )
}