import React from 'react'
import Image from 'next/image'
import venue from '../public/img/details/venue.svg'
import referee from '../public/img/details/referee.svg'
import { useTranslation } from 'next-i18next'
type Props = {
    items: any[]
}

export default function MatchDetails({ items }: Props) {
    const { t } = useTranslation('common')

    function getIcon(value: string) {
        switch (value) {
            case 'venue':
                return venue
            case 'referee':
                return referee
        }
    }

    return (
        <div className="sidebar__wrapper-block">
            <div className="block rating-list">
                <h5 className="rating-list__header rating-list__title">
                    {t('match_info.title')}
                </h5>
                <div className="match-details">
                    {items?.map((info: any, index: number) => (
                        <div
                            className="match-details__item grid--8"
                            key={`match-detail-item-${index}`}
                        >
                            <Image
                                src={getIcon(info.icon)}
                                alt="ico"
                                width={24}
                                height={24}
                            />
                            <div>
                                <p className="match-details__title">
                                    {info.title}
                                </p>
                                <p className="match-details__subtitle">
                                    {info.value}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
