import React, {useEffect, useRef} from 'react';
import clsx from 'clsx';
import st from './ElementAnimated.module.scss'

type Props<Value> = {
    value: Value,
    // Look for animation classes in scss file above
    animation: 'animationFlash' | 'animationPulse' | 'animationMatchScore',
    renderElement: (ref:  React.RefObject<any>, value: Value, cls: string | false) => JSX.Element,
    animateCondition: boolean,
}

/** Component for re-playable css animations. Animation re-play made only with css-variable, you should just reassign
 * it's value, from 'none' to 'initial'. Values meanings:
 * - 'none': css-variable has defined value, no animation is applied
 * - 'initial': aka undefined, this value uses fallback, and fallback is animation value you need to run
 * */
export const ElementAnimated = <Value,>({
    value,
    animation,
    renderElement,
    animateCondition,
}: Props<Value>) => {
    const refDomElement = React.useRef<HTMLElement>(null)
    return animateCondition
        ? (
            <Component
                refDomElement={refDomElement}
                value={value}
            >
                {renderElement(
                    refDomElement,
                    value,
                    clsx(st.animationRoot, st[animation])
                )}
            </Component>
        ) : (
            renderElement(
                refDomElement,
                value,
                false,
            )
        )
}

type ComponentProps<Value>  = {
    children: React.ReactNode,
    value: Value,
    refDomElement:  React.RefObject<any>,
}

function Component<Value>({value, refDomElement, children}: ComponentProps<Value>) {
    const refValuePrev = useRef(value)
    // Replay animation on each value update
    useEffect(() => {
        if (refValuePrev.current === value) return
        refValuePrev.current = value
        const animateValue = refDomElement.current.style.getPropertyValue('--no-animate')
        if (animateValue === 'initial') refDomElement.current.style.setProperty('--no-animate', 'none')
        setTimeout(() =>  refDomElement.current.style.setProperty('--no-animate', 'initial'))
    }, [value]);

    return <>{children}</>
}