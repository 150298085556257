import React from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useTranslation } from 'next-i18next'

// recources
import arrow_right_white from '../public/img/arrow-right-white.svg'
import ava_rating from '../public/img/ava-rating.png'

type Props = {
    items: any[]
}

const AVATAR_COLOR = [
    'e17076',
    'a695e7',
    '7bc862',
    'ee7aae',
    '65aadd',
    'faa774',
    '6ec9cb',
    '6e8acb',
    'cbca6e',
    'b86ecb',
]

export default function RatingVirtualBookmaker({ items }: Props) {
    const { t } = useTranslation('common')

    return (
        <div className="sidebar__wrapper-block">
            <div className="block rating-list">
                <div className="rating-list__header">
                    <h5 className="rating-list__title">
                        {t('contest_rating.title')}
                    </h5>
                </div>
                <div className="rating-list__subheader">
                    <div>
                        <span className="rating-list__subtitle">
                            {t('contest_rating.place')}
                        </span>
                    </div>
                    <div className="flex flex-align grid grid--18">
                        <span className="rating-list__subtitle">
                            {t('contest_rating.prizes')}
                        </span>
                        <span className="rating-list__subtitle">
                            {t('contest_rating.balance')}
                        </span>
                    </div>
                </div>
                <div className="rating-list__content">
                    {items.map((item, key) => (
                        <Link
                            href={item.url}
                            className="rating-list__link"
                            key={`virtual-bookmaker-rating-${key}`}
                        >
                            <div
                                className={`rating-list__row rating-list__row--virtual${
                                    item.user
                                        ? 'rating-list__row--last-virtual'
                                        : ''
                                }`}
                            >
                                <div className="flex flex-align  rating-list__left">
                                    <div className="rating-list__td rating-list__td--pos">
                                        <span>{item.rank}</span>
                                    </div>
                                    <div className="rating-list__td rating-list__td--overflow  grid grid--8">
                                        <div className="rating-list__avatar-wrapper">
                                            {item.avatar[0] && (
                                                <Image
                                                    src={`https://i.oddsocean.com/img/avatars/${item.avatar[0]}.jpg`}
                                                    alt="video"
                                                    width={24}
                                                    height={24}
                                                />
                                            )}
                                            {!item.avatar[0] && (
                                                <div
                                                    className="rating-list__avatar-letter"
                                                    style={{
                                                        backgroundColor: !item
                                                            .avatar[0]
                                                            ? '#' +
                                                              AVATAR_COLOR[
                                                                  item.avatar[2]
                                                              ]
                                                            : 'transparent',
                                                    }}
                                                >
                                                    {item.avatar[1]}
                                                </div>
                                            )}
                                        </div>
                                        <span className="rating-list__span">
                                            {item.login}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-align  rating-list__right">
                                    <div className="rating-list__td rating-list__td--price">
                                        {item.prize && <span className="rating-list__title-price">
                                            {item.prize}
                                        </span>}
                                    </div>
                                    <div className="rating-list__td rating-list__td--total">
                                        {item.balance}
                                    </div>
                                </div>
                            </div>
                        </Link>
                    ))}

                    <div
                        className="rating-list__row rating-list__row--virtual rating-list__row--last-virtual"
                        style={{ display: 'none' }}
                    >
                        <div className="flex flex-align  rating-list__left">
                            <div className="rating-list__td rating-list__td--pos">
                                <span>1023</span>
                            </div>
                            <div className="rating-list__td rating-list__td--overflow grid grid--8">
                                <div className="rating-list__avatar-wrapper">
                                    <Image
                                        src={ava_rating}
                                        alt="video"
                                        width={24}
                                        height={24}
                                    />
                                </div>
                                <span className="rating-list__span">
                                    Cofia_Carl
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-align rating-list__right">
                            <div className="rating-list__td rating-list__td--price">
                                <span className="rating-list__title-price">
                                    5€
                                </span>
                            </div>
                            <div className="rating-list__td rating-list__td--total">
                                1 219¢
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rating-list__footer">
                    <button className="button button--rounded button--rating grid grid--4">
                        <span>{t('contest_rating.full')}</span>
                        <Image
                            src={arrow_right_white}
                            alt="video"
                            width={14}
                            height={14}
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}
