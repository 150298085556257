import PreloadBestOffers from './PreloaderBestOffers'
import { OddsPopupParams, useGetOddsPopupQuery } from '../api'
import { useTranslation } from 'next-i18next'
import { BookmakerLinkRedirect } from './BookmakerLinkRedirect'
import Image from 'next/image'
import starActive from '../public/img/star-active.svg'
import { useSelector } from 'react-redux'
import { selectFavourites } from '../features/app'
import logobook from '../public/img/logobook.svg'

export const BestOffersPopup = ({
    matchId,
    oddType,
    oddSelect,
    period,
    handicap,
}: OddsPopupParams) => {
    const { i18n } = useTranslation()

    const {bookmaker: bookmakersFavourite} = useSelector(selectFavourites)

    const {data, isFetching} = useGetOddsPopupQuery({
        locale: i18n.language,
        matchId,
        oddType,
        oddSelect,
        period,
        handicap,
    })

    const bookmakers = data?.data?.bookmakers ?? []

    if (isFetching) {
        return (
            <>
                <div className='best-offers__content'>
                    <PreloadBestOffers count={3} />
                </div>
                <div className='best-offers__footer'>
                    <div className='best-offers__footer-top'>
                        <p className='best-offers__title best-offers__title--footer'>
                            ВИРТУАЛЬНЫЙ БУКМЕКЕР
                        </p>
                    </div>
                </div>
                <div className='best-offers__footer-bottom'>
                    <div className='best-offers__item best-offers__item--virtual'>
                        <PreloadBestOffers count={1} />
                    </div>
                </div>
            </>
        )
    }

    if (bookmakers.length === 0 && !data?.data?.maker) {
        return (
            <div className='best-offers__content'>
                <div className='flex flex-align flex-center flex-grow'>
                    <p
                        className='best-offers__text'
                        style={{ alignSelf: 'center' }}>
                        Нет предложений
                    </p>
                </div>
            </div>
        )
    }

    return (
        <>
            {bookmakers.length > 0 && (
                <div className='best-offers__content'>
                    {bookmakers.map((item, index) => (
                        <BookmakerLinkRedirect
                            key={index}
                            bookmakerId={item.bookmaker.id}
                            bookmakerLogoId={item.bookmaker.logo_id}
                            bookmakerColor={item.bookmaker.color}
                            matchId={matchId}
                            oddType={oddType}
                            oddSelect={oddSelect}
                            period={period}
                            handicap={handicap}
                        >
                            <div className='best-offers__item'>
                                <div className='best-offers__item-content'>
                                    <Image
                                        src={`https://i.oddsocean.com/img/logos/bookmakers/svg/${item.bookmaker.logo_id}_grey.svg`}
                                        alt='logo'
                                        width={72}
                                        height={24}
                                    />
                                    <Image
                                        src={`https://i.oddsocean.com/img/logos/bookmakers/svg/${item.bookmaker.logo_id}_original.svg`}
                                        alt='logo'
                                        width={72}
                                        height={24}
                                        className='best-offers__img-hovered'
                                    />
                                    <div className='best-offers__bet'>
                                        <span className='best-offers__text'>{item.display}</span>
                                    </div>
                                </div>
                                <div
                                    className='best-offers__item-overlay'
                                    style={{ background: '#' + item.bookmaker.color }}
                                />
                                {bookmakersFavourite.includes(item.bookmaker.id) && (
                                    <div className='best-offers__item-star flex flex-center flex-align'>
                                        <Image
                                            src={starActive}
                                            alt='fav'
                                            loading='eager'
                                            width={12}
                                            height={12}
                                        />
                                    </div>
                                )}
                            </div>
                        </BookmakerLinkRedirect>
                    ))}
                </div>
            )}
            {data?.data?.maker && (
                <div className='best-offers__footer'>
                    <div className='best-offers__footer-top'>
                        <p className='best-offers__title best-offers__title--footer'>
                            ВИРТУАЛЬНЫЙ БУКМЕКЕР
                        </p>
                    </div>
                    <div className='best-offers__footer-bottom'>
                        <div className='best-offers__item best-offers__item--virtual'>
                            <Image
                                src={logobook}
                                alt='logobook'
                                width={80}
                                height={30}
                            />
                            <div className='best-offers__bet'>
                                <span className='best-offers__text'>
                                    {data?.data?.maker.display}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}