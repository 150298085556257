import { MatchLegend } from '../../../../api'
import st from './StandingsLegend.module.scss'

type Props = {
    legend: MatchLegend[],
}

export const StandingsLegend = ({legend}: Props) => {
    if (legend.length === 0) return null

    return (
        <div className={st.container}>
            {legend.map((item) => (
                <div
                    className={st.badge}
                    key={item.id}
                    style={{
                        borderColor: item.color && '#' + item.color,
                    }}
                >
                    {item.tilte}
                </div>
            ))}
        </div>
    )
}