import React from 'react'
import clsx from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import { MatchPlayoffPair, TeamInMatch } from '../../../../../api';
import { Logo } from '../../../../logo';
import { getTeamIcon } from './helpers';
import styles from './PlayoffMobile.module.scss';

type Props = {
    pair: MatchPlayoffPair,
}

export const PlayoffPairCardMobile = ({ pair }: Props) => {
    const matchFirst = pair.matches && pair.matches[0]
    const matchSecond = pair.matches && pair.matches[1]
    return (
        <div className={clsx(styles.card, pair.live && styles.live)}>
            <div className="flex flex-justify grid--4 mb-4">
                <Team
                    team={pair.team1}
                    isWinner={pair.next_round === 1 || pair.next_round === null}
                />
                <Team
                    team={pair.team2}
                    isWinner={pair.next_round === 2 || pair.next_round === null}
                />
            </div>
            {/*Display thumb, if no matches found*/}
            {!matchFirst && (
                <div className={styles.scoreBox}>
                    <span className={styles.score}>
                        -
                    </span>
                    :
                    <span className={styles.score}>
                        -
                    </span>
                </div>
            )}
            {matchFirst && (
                <Link
                    className={clsx(
                        styles.scoreBox,
                        styles.hover,
                        matchSecond && styles.first,
                        pair.live === 1 && styles.live
                    )}
                    href={matchFirst.url}
                >
                    <span className={styles.score}>
                        {matchFirst?.score[0] ?? '‒'}
                    </span>
                    :
                    <span className={styles.score}>
                        {matchFirst?.score[1] ?? '‒'}
                    </span>
                </Link>
            )}
            {matchSecond && (
                <>
                    <div className={styles.divider}/>
                    <Link
                        className={clsx(
                            styles.scoreBox,
                            styles.hover,
                            styles.second,
                            pair.live === 2 && styles.live
                        )}
                        href={matchSecond.url}
                    >
                        <span className={styles.score}>
                            {matchSecond?.score[0] ?? '‒'}
                        </span>
                        :
                        <span className={styles.score}>
                            {matchSecond?.score[1] ?? '‒'}
                        </span>
                    </Link>
                </>
            )}
            {pair.icons[0].length > 0
                ? (
                    <div className={styles.iconContainer}>
                        <Image
                            src={getTeamIcon(pair.icons[0][0])}
                            alt=""
                            width={10}
                            height={10}
                        />
                    </div>
                )
                : pair.icons[1].length > 0
                    ? (
                        <div className={styles.iconContainer}>
                            {pair.icons[1][0]}
                        </div>
                    )
                    : null
            }
            <div className={clsx(styles.connector, styles.connectorIn)}/>
            <div className={clsx(styles.connector, styles.connectorOut)}/>
        </div>
    )
}

type TeamProps = {
    team: TeamInMatch | null,
    isWinner: boolean,
}

function Team({team, isWinner}: TeamProps) {
    return (
        <div className={clsx(styles.teamsContainer, 'flex flex-column flex-align grid--4')}>
            {team
                ? (
                    <>
                        <Logo
                            type="teams"
                            size={36}
                            logoId={team.logo}
                            dimension={26}
                            withBorder
                        />
                        <span className={clsx(styles.teamTitle, isWinner && styles.isWinner)}>
                            {team.short}
                        </span>
                    </>
                )
                : <TeamThumbnail />
            }
        </div>
    )
}

function TeamThumbnail() {
    return (
        <>
            <div className={styles.teamLogoThumb} />
            <div className={styles.teamTitleThumb} />
        </>
    )
}