import React, { useRef, useEffect, useState } from 'react'
import Link from 'next/link'
type Props = {
    items: any[]
}

export default function Breadcrumbs({ items }: Props) {
    const wrapperRef = useRef(null)
    const [maxWidth, setMaxWidth] = useState<number>(0)
    const [firstRender, setFirstRender] = useState<boolean>(false)

    let curWidth = 0
    let widthLastElement = 0

    useEffect(() => {
        ;[...wrapperRef.current.children].map((item: any, index: number) => {
            if (index == wrapperRef.current.children.length - 1)
                widthLastElement = item.clientWidth
            else curWidth += item.clientWidth
        })

        resizeListener()
        setFirstRender(true)

        window.addEventListener('resize', resizeListener)

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener)
        }
    }, [])

    function resizeListener() {
        const widthSidebar =
            document.querySelector('.sidebar--right').clientWidth - 24
        const MAX_WIDTH = widthSidebar - 155
        if (MAX_WIDTH > 0) {
            if (curWidth / 2 + widthLastElement > MAX_WIDTH) {
                setMaxWidth(MAX_WIDTH - curWidth / 2)
            }
        }
    }

    return (
        <ol className="breadcrumb breadcrumb--block" ref={wrapperRef}>
            {items.map((item: any, index: number) => (
                <li
                    key={`matchday-breadcrumb-${index}`}
                    className="breadcrumb-item"
                    aria-current="page"
                >
                    <Link
                        className="breadcrumb__link"
                        style={{
                            maxWidth:
                                !firstRender ||
                                index !== items.length - 1 ||
                                maxWidth == 0
                                    ? 'auto'
                                    : maxWidth,
                        }}
                        href={item?.url ? item?.url : '#'}
                    >
                        {item?.title}
                    </Link>
                </li>
            ))}
        </ol>
    )
}
