import React, {ReactNode, useState} from 'react'
import * as RadixTooltip from '@radix-ui/react-tooltip';
import st from './Tooltip.module.scss'

type Props = {
    children: ReactNode,
    content: ReactNode,
    // Condition, to render children without tooltip trigger
    skipTooltip?: boolean,
}

export const Tooltip = ({
    children,
    content,
    skipTooltip,
}: Props) => {
    // Use controllable open with onClick -> be able to show tooltip on touch screens.
    // By default, RadixTooltip triggers only by hover on desktop.
    const [open, setOpen] = useState(false)

    if (skipTooltip) {
        return (
            <>
                {children}
            </>
        )
    }

    return (
        <RadixTooltip.Provider delayDuration={0}>
            <RadixTooltip.Root
                open={open}
                onOpenChange={setOpen}
            >
                <RadixTooltip.Trigger
                    asChild
                    onClick={() => setOpen(true)}
                >
                    {children}
                </RadixTooltip.Trigger>
                <RadixTooltip.Portal>
                    <RadixTooltip.Content
                        className={st.content}
                        side='bottom'
                        sideOffset={5}
                        collisionPadding={4}
                    >
                        {content}
                    </RadixTooltip.Content>
                </RadixTooltip.Portal>
            </RadixTooltip.Root>
        </RadixTooltip.Provider>
    );
};