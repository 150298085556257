import { useEffect, useState } from 'react'
import { CompetitionStandingsMatch } from '../../../../api'
import { StandingsMatch } from './StandingsMatch'
import styles from './StandingsMatchesCollapsible.module.scss'

type MatchToRender = {
    isHidden?: boolean,
} & CompetitionStandingsMatch

type Props = {
    matches: CompetitionStandingsMatch[],
    matchesShowInitial: number,
    className: string,
}

export const StandingsMatchesCollapsible = ({
    matches,
    matchesShowInitial,
    className,
}: Props) => {
    const [showAll, setShowAll] = useState(false)
    const [matchesToRender, setMatchesToRender] = useState<MatchToRender[]>([])

    const isCollapseActive = matches.length > matchesShowInitial

    useEffect(() => {
        if (showAll || !isCollapseActive) {
            setMatchesToRender(matches)
            return
        }

        setMatchesToRender(matches.map((match, index) => ({
            ...match,
            isHidden: (index + 1) > matchesShowInitial,
        })))
    }, [showAll])

    return (
        <>
            {matchesToRender.map((match) => (
                match.isHidden
                    ? null
                    : (
                        <StandingsMatch
                            key={match.id}
                            match={match}
                            className={className}
                        />
                    )
            ))}
            <div className={styles.buttonContainer}>
                {isCollapseActive && (
                    <button
                        className={styles.button}
                        onClick={() => setShowAll((s) => !s)}
                    >
                        {showAll ? 'Свернуть' : 'Показать все матчи'}
                    </button>
                )}
            </div>
        </>
    )
}