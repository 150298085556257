// withLogging.js
import React from 'react'
import {useSelector} from 'react-redux';
import { useAppDispatch } from '../hooks'
import Cookies from 'js-cookie'
import {addFavorites, removeFavorites, selectFavourites, setFavorites} from '../features/app'

export type WithFavoriteProps = {
    toggleFavorite: (type: string, id: string | number) => void,
    favorites: any,
}

const withFavorite = <T,>(WrappedComponent: any) => {
    const WithFavorite = (props: T) => {
        const dispatch = useAppDispatch()
        const favorites = useSelector(selectFavourites)

        function toggleFavorite(type: string, id: string | number) {
            if (!id) return

            const copy = JSON.parse(localStorage.getItem('favorites'))
            const token = Cookies.get('auth-token')
            if (favorites[type].indexOf(id) !== -1) {
                // remove
                copy[type].splice(copy[type].indexOf(id), 1)
                if (token) dispatch(removeFavorites({ type, id }))
            } else {
                // add
                copy[type].push(id)
                if (token) dispatch(addFavorites({ type, id }))
            }
            dispatch(setFavorites(copy))
        }

        return (
            <WrappedComponent
                {...props}
                toggleFavorite={toggleFavorite}
                favorites={favorites}
            />
        )
    }

    return WithFavorite
}

export default withFavorite
