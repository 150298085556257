import React from 'react'
import {MatchPlayoffStage} from '../../../../api';
import { PlayoffDesktop, PlayoffMobile } from './playoff-parts';

type Props = {
    data: MatchPlayoffStage[] | false,
    blockWidth: number,
    className?: string,
}

export const Playoff = ({ data, blockWidth, className }: Props) => {
    if (!data) return null

    return blockWidth < 750
        ? (
            <PlayoffMobile
                data={data}
                isSmallContainer={blockWidth < 421}
                className={className}
            />
        )
        : (
            <PlayoffDesktop
                data={data}
                className={className}
            />
        )
}