import React from 'react'
import Image from 'next/image'
import star_active from '../public/img/star-active.svg'
import arrow_right_white from '../public/img/arrow-right-white.svg'
import star_white from '../public/img/star-white.svg'
import star from '../public/img/star.svg'
import { useTranslation } from 'next-i18next'
import withFavorite from '../hoc/favorite';
import {BookmakerLinkRedirect} from './BookmakerLinkRedirect';

type Props = {
    items: any[]
    favorites: any
    toggleFavorite: Function
}

function RatingBookmakers({
    items,
    favorites,
    toggleFavorite,
}: Props) {
    const { t } = useTranslation('common')

    function _onClickRating(e: any, id: number) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        toggleFavorite('bookmaker', id)
    }

    return (
        <div className="sidebar__wrapper-block">
            <div className="block rating-list">
                <div className="rating-list__header">
                    <h5 className="rating-list__title">
                        {t('bookie_rating.title')}
                    </h5>
                    <span className="rating-list__subtitle rating-list__subtitle--left">
                        {t('bookie_rating.bonus')}
                    </span>
                </div>
                <div className="rating-list__hader-table"></div>
                <div className="rating-list__content rating-list__content--grid">
                    {items.map((bookmaker, index) => (
                        <div
                            key={`bookmaker-${index}`}
                            className="rating-list__link"
                        >
                            <div className="rating-list__row">
                                <div className="flex flex-align grid grid--16">
                                    <BookmakerLinkRedirect
                                        bookmakerId={bookmaker.id}
                                        bookmakerLogoId={bookmaker.logo_id}
                                        bookmakerColor={bookmaker.color}
                                    >
                                        <div
                                            className="bookmaker-badge"
                                            style={{backgroundColor: `#${bookmaker.color}`}}
                                        >
                                            <Image
                                                src={`https://i.oddsocean.com/img/logos/bookmakers/svg/${bookmaker.logo_id}_original.svg`}
                                                alt="winline"
                                                width={80}
                                                height={24}
                                            />
                                        </div>
                                    </BookmakerLinkRedirect>
                                    <div className="flex flex-align grid grid--4">
                                        <button
                                            onClick={(e) =>
                                                _onClickRating(e, bookmaker.id)
                                            }
                                            className={`button button--view button--view-sidebar ${
                                                favorites.bookmaker.indexOf(
                                                    bookmaker.id
                                                ) !== -1
                                                    ? 'button--active-favorite'
                                                    : ''
                                            }`}
                                        >
                                            <Image
                                                src={star_white}
                                                className="button__ico button__ico--view-white button__ico--abs"
                                                alt="star"
                                                priority={true}
                                                width={16}
                                                height={16}
                                            />
                                            <Image
                                                src={
                                                    favorites.bookmaker.indexOf(
                                                        bookmaker.id
                                                    ) !== -1
                                                        ? star_active
                                                        : star
                                                }
                                                className="button__ico button__ico--view button__ico--abs button__ico--odd-bookmaker"
                                                alt="star"
                                                priority={true}
                                                width={16}
                                                height={16}
                                            />
                                        </button>
                                        <span className="rating-list__price">
                                            {bookmaker.rating.toFixed(1)}
                                        </span>
                                    </div>
                                </div>
                                <div className="flex flex-align grid grid--8">
                                    {bookmaker.exclusive && (
                                        <span className="badge badge--ex">
                                            Exclusive
                                        </span>
                                    )}
                                    <span className="rating-list__price">
                                        {bookmaker.bonus}
                                    </span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="rating-list__footer">
                    <button className="button button--rounded button--rating grid grid--4">
                        <span>{t('bookie_rating.all')}</span>
                        <Image
                            src={arrow_right_white}
                            alt="video"
                            width={14}
                            height={14}
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}

const RatingBookmakersWithFavorite = withFavorite<any>(RatingBookmakers)
export default RatingBookmakersWithFavorite