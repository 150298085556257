import React from 'react'

import Image from 'next/image'
import goal from '../public/img/incindent_ico_color/goal.svg'
import football_yellow from '../public/img/incindent_ico_color/football_yellow.svg'
import football_substitution from '../public/img/incindent_ico_color/football_substitution.svg'
import football_yellow_red from '../public/img/incindent_ico_color/football_yellow_red.svg'
import football_own_goal from '../public/img/incindent_ico_color/football_own_goal.svg'
import football_red from '../public/img/incindent_ico_color/football_red.svg'
import football_penalty_miss from '../public/img/incindent_ico_color/football_penalty_miss.svg'
import football_penalty from '../public/img/incindent_ico_color/football_penalty.svg'
import injured from '../public/img/incindent_ico_color/injured.svg'
import plane from '../public/img/incindent_ico_color/football_plane.svg'
import sub_in from '../public/img/incindent_ico_color/sub_in.svg'
import sub_out from '../public/img/incindent_ico_color/sub_out.svg'
// substitution
import football_substitution_team_1 from '../public/img/incindent_ico_color/football_substitution_team_1.svg'
import football_substitution_team_2 from '../public/img/incindent_ico_color/football_substitution_team_2.svg'
type Props = {
    player: any
    indexPlayer: number
}
export interface Option {
    value: string
    title: string
    ico?: any
}

function LineupsRow({ player, indexPlayer }: Props) {
    function getIcon(value: string) {
        switch (value) {
            case 'football_goal':
                return goal
            case 'football_yellow':
                return football_yellow
            case 'football_substitution':
                return football_substitution
            case 'football_yellow_red':
                return football_yellow_red
            case 'football_own_goal':
                return football_own_goal
            case 'football_red':
            case 'suspended':
                return football_red
            case 'football_penalty_miss':
                return football_penalty_miss
            case 'football_penalty':
                return football_penalty
            case 'football_substitution_in':
                return sub_in
            case 'football_substitution_out':
                return sub_out
            case 'injured':
                return injured
            case 'national':
                return plane
        }
    }

    function getIcoSubstitution(type: string) {
        switch (type) {
            case 'football_substitution_out':
                return football_substitution_team_1
            case 'football_substitution_in':
                return football_substitution_team_2
        }
    }

    return (
        <div
            className={`rating-list__event rating-list__event--${
                player.block
            } rating-list__event--${indexPlayer % 2 === 0 ? 'even' : 'odd'}`}
        >
            <div
                className={`rating-list__row rating-list__row--${player.block} rating-list__row--lineups`}
            >
                <div className="flex flex-align grid grid--8 flex-justify full-width">
                    <div className="flex flex-align">
                        <div className="rating-list__td rating-list__td--flag">
                            <div className="rating-list__avatar-wrapper rating-list__avatar-wrapper--lineups">
                                <Image
                                    src={`https://i.oddsocean.com/img/ocean/36x36/comp/flag${player.flag}.png`}
                                    alt="flag-player"
                                    width={18}
                                    height={18}
                                />
                            </div>
                            <div className="match-events__tooltip">
                                <span className="match-events__tooltip-title match-events__tooltip-title--lineups">
                                    {player.nat}
                                </span>
                            </div>
                        </div>
                        <div className="rating-list__td rating-list__td--number">
                            {player.block == 'coach' ? '' : player.number}
                        </div>
                        <div className="flex flex-align tooltip-hover">
                            <div className="rating-list__td rating-list__td--sub">
                                {player?.incidents
                                    ?.filter(
                                        (item: any) =>
                                            [
                                                'football_substitution_out',
                                                'football_substitution_in',
                                            ].indexOf(item.type) !== -1
                                    )
                                    .map(
                                        (
                                            incindent: any,
                                            indexIncindent: number
                                        ) => (
                                            <div
                                                className="match-events__incindent-wrapper"
                                                key={`incindent-main-player-${player.indexPlayer}-${indexIncindent}`}
                                            >
                                                <Image
                                                    src={getIcon(
                                                        incindent.type
                                                    )}
                                                    alt="ico"
                                                    width={18}
                                                    height={18}
                                                />
                                                <div className="match-events__tooltip">
                                                    <h5 className="match-events__tooltip-title match-events__tooltip-title--lineups">
                                                        {incindent.time}
                                                    </h5>
                                                    <div className="match-events__tooltip-content grid grid--4">
                                                        <div className="match-events__tooltip-row grid grid--8">
                                                            <Image
                                                                src={getIcoSubstitution(
                                                                    incindent.type
                                                                )}
                                                                alt="ico"
                                                                width={16}
                                                                height={16}
                                                                priority
                                                            />
                                                            <span>
                                                                {incindent.text}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    )}
                            </div>
                            <div className="rating-list__td rating-list__td--name">
                                {player.fullname}
                            </div>
                        </div>

                        <div className="flex flex-align">
                            {player?.incidents
                                ?.filter(
                                    (item: any) =>
                                        [
                                            'football_substitution_out',
                                            'football_substitution_in',
                                            'suspended',
                                            'injured',
                                            'national'
                                        ].indexOf(item.type) == -1
                                )
                                .map(
                                    (
                                        incindent: any,
                                        indexIncindent: number
                                    ) => (
                                        <div
                                            key={`incindent-sub-player-${player.indexPlayer}-${indexIncindent}`}
                                            className="match-events__incindent-wrapper match-events__incindent-wrapper--lineups"
                                        >
                                            <Image
                                                src={getIcon(incindent.type)}
                                                alt="ico"
                                                width={16}
                                                height={16}
                                            />
                                            <div className="match-events__tooltip">
                                                <h5 className="match-events__tooltip-title match-events__tooltip-title--lineups">
                                                    {incindent.time}
                                                </h5>
                                                <div className="match-events__tooltip-content grid grid--4">
                                                    <div className="match-events__tooltip-row grid grid--8">
                                                        <span>
                                                            {incindent.text}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                )}
                        </div>
                    </div>
                    <div className="flex flex-align grid grid--8">
                        {player?.incidents
                            ?.filter(
                                (item: any) =>
                                    item.type == 'suspended' ||
                                    item.type == 'injured' ||
                                    item.type == 'national'
                            )
                            .map((incindent: any, indexIncindent: number) => (
                                <div
                                    key={`incindent-dnp-player-${player.indexPlayer}-${indexIncindent}`}
                                    className="match-events__incindent-ico-wrapper match-events__incindent-wrapper--lineups"
                                >
                                    <Image
                                        src={getIcon(incindent.type)}
                                        alt="ico"
                                        width={16}
                                        height={16}
                                    />
                                    <div className="match-events__tooltip">
                                        <span className="match-events__tooltip-title match-events__tooltip-title--lineups">
                                            {incindent.title}
                                        </span>
                                    </div>
                                </div>
                            ))}
                        <div className="rating-list__td rating-list__td--pos-lineup">
                            {player?.pos && player?.pos[0]}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default React.memo(LineupsRow)
