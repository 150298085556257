import React, { useState, useRef, useEffect, useMemo, forwardRef } from 'react'
import LineupsRow from './LineupsRow'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import PlayerTooltipField from './PlayerTooltipField'
import {Logo} from './logo';
import {Tooltip} from './Tooltip';
import { Button } from './Button'
type Props = {
    lineups: any
    sidebarView?: boolean
    full: boolean
}
export interface Option {
    value: string
    title: string
    ico?: any
}

export default forwardRef(function LineupsBlock(
    { lineups, sidebarView, full }: Props,
    ref: any
) {
    const staticPath = 'https://i.oddsocean.com/img/logos/teams/36x36/'
    const sidebar_block = useRef(null)

    const field = useRef(null)
    const [type, setType] = useState('list')
    const { t } = useTranslation('common')

    //@todo Refactoring
    const [widthField, setWidthField] = useState(0)
    const [heightField, setHeightField] = useState(0)

    // HORIZONTAL
    const MAX_SIZE = 370
    const FULL_WIDTH = 744
    const WIDTH_OBJECT = 56
    const HEIGHT_OBJECT = 47

    // VERTICAL
    const WIDTH = 370
    const HEIGHT = 624

    const [scale, setScale] = useState(1)

    const [activeTeam, setActiveTeam] = useState<any>(0)

    const [widthWindow, setWidthWindow] = useState(0)

    const playersOnFieldTeam1 = useMemo(
        () =>
            lineups &&
            lineups[0]?.lineup?.filter((player: any) => player.onfield),
        [lineups]
    )
    const playersOnFieldTeam2 = useMemo(
        () =>
            lineups &&
            lineups[1]?.lineup?.filter((player: any) => player.onfield),
        [lineups]
    )

    // use memo
    const noField = useMemo(
        () => lineups && !lineups[0]?.formation && !lineups[1]?.formation,
        [lineups]
    )

    const [widthPlayer, setWidthPlayer] = useState(WIDTH_OBJECT)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [heightPlayer, setHeightPlayer] = useState(HEIGHT_OBJECT)

    const resizeListener = () => {
        // change width from the state object
        setWidthWindow(sidebar_block?.current?.clientWidth - 24)
    }

    useEffect(() => {
        if (type == 'field') {
            // init window size
            setWidthWindow(sidebar_block?.current?.clientWidth - 24)
            window.addEventListener('resize', resizeListener)
            setWidthField(
                sidebarView
                    ? field?.current?.clientHeight
                    : field?.current?.clientWidth
            )
            setHeightField(
                sidebarView
                    ? field?.current?.clientWidth
                    : field?.current?.clientHeight / 2
            )
            if (sidebarView) {
                const new_height = (widthWindow * HEIGHT) / WIDTH
                setScale(new_height / HEIGHT)
            } else {
                const NEW_ASPECT = widthWindow / FULL_WIDTH
                setScale(NEW_ASPECT)
            }
        } else {
            window.removeEventListener('resize', resizeListener)
        }
    }, [type, sidebarView])

    useEffect(() => {
        if (type === 'field') {
            if (sidebarView) {
                const new_height = (widthWindow * 624) / 370
                setScale(new_height / 624)
            } else {
                const NEW_ASPECT = widthWindow / FULL_WIDTH
                setScale(NEW_ASPECT)
            }
        }
    }, [widthWindow])
    // change scale
    useEffect(() => {
        if (sidebarView) {
            setHeightField(MAX_SIZE * scale)
            setWidthField(624 * scale)
            //setWidthPlayer(WIDTH_OBJECT * scale);
        } else {
            setHeightField(MAX_SIZE * scale)
            setWidthField(FULL_WIDTH * scale)
            setWidthPlayer(WIDTH_OBJECT * scale)
            setHeightPlayer(HEIGHT_OBJECT * scale)
        }
    }, [scale])

    function _onChangeType(type: string) {
        setType(type)
    }
    function interpolate(x: any, type: string, from: number, to: number) {
        let add = 45 * scale
        if (sidebarView) add = 65

        const x1 = from
        const x2 = to
        const y1 = 0
        const y2 =
            type == 'y'
                ? sidebarView
                    ? widthField / 2 - add
                    : widthField / 2
                : sidebarView
                    ? heightField
                    : widthField / 2 - add

        if (x > 90) x = sidebarView ? 95 : 90

        return y1 + (x - x1) * ((y2 - y1) / (x2 - x1))
    }

    return (
        lineups && (
            <div ref={ref}>
                <div className='sidebar__wrapper-block' ref={sidebar_block}>
                    <div className={'block rating-list surebet'}>
                        <div className='rating-list__header'>
                            <h5 className='rating-list__title'>
                                {t('match_lineup.title')}
                            </h5>
                            <div className='flex flex-align grid grid--4'>
                                {!noField && (
                                    <>
                                        <Button
                                            onClick={() => _onChangeType('list')}
                                            mode='dark'
                                            isActive={type === 'list'}
                                        >
                                            {t('match_lineup.list')}
                                        </Button>
                                        <Button
                                            onClick={() => _onChangeType('field')}
                                            mode='dark'
                                            isActive={type === 'field'}
                                        >
                                            {t('match_lineup.on_field')}
                                        </Button>
                                    </>
                                )}
                            </div>
                        </div>
                        {type == 'field' && !noField && (
                            <div
                                className={`field ${
                                    sidebarView ? 'field--vertical' : ''
                                }`}
                            >
                                <div
                                    className='field__wrapper flex flex-justify'
                                    ref={field}
                                    style={{
                                        height: sidebarView
                                            ? 624 * scale
                                            : MAX_SIZE * scale,
                                    }}
                                >
                                    <div className='rating-list__team-wrapper--field  rating-list__team-wrapper--field-left'>
                                        <Logo
                                            type='teams'
                                            size={80}
                                            dimension={40}
                                            logoId={lineups[0]?.team?.logo}
                                            withBorder
                                        />
                                    </div>

                                    <div className='rating-list__team-wrapper--field  rating-list__team-wrapper--field-right'>
                                        <Logo
                                            type='teams'
                                            size={80}
                                            dimension={40}
                                            logoId={lineups[1]?.team?.logo}
                                            withBorder
                                        />
                                    </div>
                                    {lineups[0]?.formation && (
                                        <div className='rating-list__formation rating-list__formation--left'>
                                            <span>{lineups[0]?.formation}</span>
                                        </div>
                                    )}
                                    {lineups[1]?.formation && (
                                        <div className='rating-list__formation rating-list__formation--right'>
                                            <span>{lineups[1]?.formation}</span>
                                        </div>
                                    )}
                                    {widthField > 0 && heightField > 0 && (
                                        <div
                                            style={{
                                                width: '50%',
                                                height: sidebarView
                                                    ? '50%'
                                                    : '100%',
                                                transformOrigin: 'left',
                                            }}
                                        >
                                            {playersOnFieldTeam1.map((player: any, index: number) => (
                                                <Tooltip
                                                    key={index}
                                                    content={<PlayerTooltipField player={player} />}
                                                >
                                                    <div
                                                        className='field__player'
                                                        style={{
                                                            transform: `translate3d(${
                                                                interpolate(
                                                                    player
                                                                        .onfield[
                                                                            sidebarView
                                                                                ? 1
                                                                                : 0
                                                                        ],
                                                                    'x',
                                                                    0,
                                                                    100,
                                                                ) +
                                                                    (sidebarView
                                                                        ? -46 / 2
                                                                        : (widthPlayer -
                                                                            12) /
                                                                        2)
                                                            }px, ${
                                                                interpolate(
                                                                    player
                                                                        .onfield[
                                                                            sidebarView
                                                                                ? 0
                                                                                : 1
                                                                        ],
                                                                    'y',
                                                                    0,
                                                                    100,
                                                                ) +
                                                                    (sidebarView
                                                                        ? 56 / 2
                                                                        : -56 / 2)
                                                            }px, 0) scale(${scale})`,
                                                        }}
                                                    >
                                                        <div className='field__player-tshirt'>
                                                            <span>
                                                                {player.number}{' '}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className='field__player-label field__player-label--team1'>
                                                            <span>
                                                                {player.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            )
                                            )}
                                        </div>
                                    )}
                                    {widthField > 0 && heightField > 0 && (
                                        <div
                                            style={{
                                                width: '50%',
                                                height: sidebarView
                                                    ? '50%'
                                                    : '100%',
                                                transformOrigin: 'right',
                                            }}
                                        >
                                            {playersOnFieldTeam2.map((player: any, index: number) => (
                                                <Tooltip
                                                    key={index}
                                                    content={<PlayerTooltipField player={player}/>}
                                                >
                                                    <div
                                                        className='field__player'
                                                        style={{
                                                            transform: `translate3d(${
                                                                interpolate(
                                                                    player
                                                                        .onfield[
                                                                            sidebarView
                                                                                ? 1
                                                                                : 0
                                                                        ],
                                                                    'x',
                                                                    100,
                                                                    0,
                                                                ) +
                                                                (sidebarView
                                                                    ? -46 / 2
                                                                    : -56 / 2)
                                                            }px, ${
                                                                interpolate(
                                                                    player
                                                                        .onfield[
                                                                            sidebarView
                                                                                ? 0
                                                                                : 1
                                                                        ],
                                                                    'y',
                                                                    100,
                                                                    0,
                                                                ) +
                                                                (sidebarView
                                                                    ? -10
                                                                    : -56 / 2)
                                                            }px, 0)  scale(${scale})`,
                                                        }}
                                                    >
                                                        <div
                                                            className='field__player-tshirt field__player-tshirt--yellow field__player-tshirt--black'>
                                                            <span>
                                                                {player.number}{' '}
                                                            </span>
                                                        </div>
                                                        <div
                                                            className='field__player-label  field__player-label--team2'>
                                                            <span>
                                                                {player.name}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </Tooltip>
                                            )
                                            )}
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                        {type == 'list' && (
                            <div
                                className={`rating-list__content rating-list__content--grid rating-list__content--lineups ${
                                    !full
                                        ? 'rating-list__content--lineups-sidebar'
                                        : ''
                                }`}
                            >
                                {!full && (
                                    <div className='last-matches__commands'>
                                        {lineups?.map((team: any, index: number) => (
                                            <Button
                                                key={index}
                                                mode='dark'
                                                onClick={() => setActiveTeam(index)}
                                                isActive={activeTeam == index}
                                            >
                                                <Image
                                                    src={`${staticPath}${team?.team?.logo}.png`}
                                                    alt='ico'
                                                    width={18}
                                                    height={18}
                                                />
                                                {team.team?.title}
                                            </Button>
                                        ))}
                                    </div>
                                )}
                                {lineups?.map((team: any, index: number) => (
                                    <div
                                        className={`rating-list__column rating-list__column--left ${
                                            activeTeam == index
                                                ? 'active-team-tab'
                                                : 'hidden-team-tab'
                                        }`}
                                        key={`lineup-team-${index}`}
                                    >
                                        <div className='rating-list__row-subheader rating-list__row-subheader--lineup grid grid--8'>
                                            <div className='rating-list__team-wrapper'>
                                                <Image
                                                    src={`${staticPath}${team?.team?.logo}.png`}
                                                    alt={team?.team?.title}
                                                    width={18}
                                                    height={18}
                                                />
                                            </div>
                                            <h6 className='rating-list__team-title'>
                                                {team?.team?.title}
                                            </h6>
                                        </div>

                                        <div className='rating-list__subheader rating-list__subheader--lineups'>
                                            <div className='flex'>
                                                <div className='rating-list__td rating-list__td--flag'>
                                                    <span className='rating-list__subtitle'>
                                                        {t(
                                                            'match_lineup.nationality'
                                                        )}
                                                    </span>
                                                </div>

                                                <div className='rating-list__td rating-list__td--number'>
                                                    <span className='rating-list__subtitle'>
                                                        {t('match_lineup.no')}
                                                    </span>
                                                </div>
                                                <div className='rating-list__td rating-list__td--sub'></div>
                                                <div className='rating-list__td rating-list__td--name'>
                                                    <span className='rating-list__subtitle'>
                                                        {t('match_lineup.name')}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className='flex flex-align grid grid--18'>
                                                <span className='rating-list__subtitle'>
                                                    {t('match_lineup.position')}
                                                </span>
                                            </div>
                                        </div>

                                        <div className='main-lineup'>
                                            {team.lineup
                                                ?.filter(
                                                    (player: any) =>
                                                        player.block == 'main'
                                                )
                                                .map(
                                                    (
                                                        player: any,
                                                        indexPlayer: number
                                                    ) => (
                                                        <LineupsRow
                                                            player={player}
                                                            indexPlayer={
                                                                indexPlayer
                                                            }
                                                            key={`lineup-main-${indexPlayer}`}
                                                        />
                                                    )
                                                )}
                                        </div>

                                        {team.lineup?.filter(
                                            (player: any) =>
                                                player.block == 'sub'
                                        ).length > 0 && (
                                            <div className='rating-list__column-subheader'>
                                                <h5 className='rating-list__title rating-list__title--odds'>
                                                    {t('match_lineup.subs')}
                                                </h5>
                                            </div>
                                        )}
                                        <div className='sub-lineup'>
                                            {team.lineup
                                                ?.filter(
                                                    (player: any) =>
                                                        player.block == 'sub'
                                                )
                                                .map(
                                                    (
                                                        player: any,
                                                        indexPlayer: number
                                                    ) => (
                                                        <LineupsRow
                                                            player={player}
                                                            indexPlayer={
                                                                indexPlayer
                                                            }
                                                            key={`lineup-sub-${indexPlayer}`}
                                                        />
                                                    )
                                                )}
                                        </div>
                                        <div className='coach-lineup'>
                                            {team.lineup
                                                ?.filter(
                                                    (player: any) =>
                                                        player.block == 'coach'
                                                )
                                                .map(
                                                    (
                                                        player: any,
                                                        indexPlayer: number
                                                    ) => (
                                                        <LineupsRow
                                                            player={player}
                                                            indexPlayer={
                                                                indexPlayer
                                                            }
                                                            key={`lineup-coach-${indexPlayer}`}
                                                        />
                                                    )
                                                )}
                                        </div>
                                        {team.lineup?.filter(
                                            (player: any) =>
                                                player.block == 'dnp'
                                        ).length > 0 && (
                                            <div className='rating-list__column-subheader'>
                                                <h5 className='rating-list__title rating-list__title--odds'>
                                                    {t(
                                                        'match_lineup.do_not_play'
                                                    )}
                                                </h5>
                                            </div>
                                        )}
                                        <div className='dnp-lineup'>
                                            {team.lineup
                                                ?.filter(
                                                    (player: any) =>
                                                        player.block == 'dnp'
                                                )
                                                .map(
                                                    (
                                                        player: any,
                                                        indexPlayer: number
                                                    ) => (
                                                        <LineupsRow
                                                            player={player}
                                                            indexPlayer={
                                                                indexPlayer
                                                            }
                                                            key={`lineup-dnp-${indexPlayer}`}
                                                        />
                                                    )
                                                )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        )
    )
})
