import React from 'react'

type Props = {}

export default function PreloadSidebar({}: Props) {
    return (
        <div className={`preloader preloader--matchday`}>
            <div className="preloader__matchday"></div>

            <div className="preloader__block-sidebar-wrapper">
                <div className="preloader__block-sidebar-header">
                    <div className="preloader__block-sidebar preloader__block-sidebar--title"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--subtitle"></div>
                </div>
                <div className="preloader__block-sidebar-content grid grid--4">
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                </div>
                <div className="flex preloader__wrapper-button">
                    <div className="preloader__block-sidebar preloader__block-sidebar--button"></div>
                </div>
            </div>

            <div className="preloader__block-sidebar-wrapper">
                <div className="preloader__block-sidebar-header">
                    <div className="preloader__block-sidebar preloader__block-sidebar--title"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--subtitle"></div>
                </div>
                <div className="preloader__block-sidebar-content grid grid--4">
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                    <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                </div>
                <div className="flex preloader__wrapper-button">
                    <div className="preloader__block-sidebar preloader__block-sidebar--button"></div>
                </div>
            </div>
        </div>
    )
}
