import { Button } from '../Button'

type Props = {
    value: string,
    onChange: (value: string) => void,
    options: {
        id: string,
        label: string,
    }[],
}

export const SwitchButtons = ({options, value, onChange}:Props) => {
    return (
        <div className='flex grid--4'>
            {options.map((option) => (
                <Button
                    key={option.id}
                    mode='dark'
                    isActive={option.id === value}
                    onClick={() => onChange(option.id)}
                >
                    {option.label}
                </Button>
            ))}
        </div>
    )
}