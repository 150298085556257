import React from 'react'
import { useState } from 'react'
import arrow_black from '../public/img/arrow_black.svg'
import arrow from '../public/img/arrow.svg'
import Image from 'next/image'
import { useAppSelector } from '../hooks'
import PreloadTopCountries from './PreloaderTopCountries'
import Accordion from './Accordion'
import AccordionSection from './AccordionSection'
import { useTranslation } from 'react-i18next'
import { useGetCountriesQuery, useUpdateCountriesMutation } from '../api/competitionsApi'
import CompetitionItem from './CompetitionItem'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function CountriesBlock() {
    const { i18n } = useTranslation()
    const { t } = useTranslation('common')

    const countriesCompetitionsQuery = useGetCountriesQuery({
        locale: i18n.language,
    })
    const countries = countriesCompetitionsQuery.data?.data?.countries ?? []
    const loading = countriesCompetitionsQuery.isFetching

    const [isTop, setTop] = useState(true)
    const countriesFiltered = isTop
        ? countries.slice().filter((item: any) => item.top)
        : countries.slice().sort(function (x: any, y: any) {
            return x.top == y.top ? 0 : x.top ? -1 : 1
        })
    const countriesCompetitionLoading = useAppSelector((state) => state.app.status.competitions_country)

    const [updateCountries, {}] = useUpdateCountriesMutation()

    return (
        <div className="sidebar__block">
            <h3 className="sidebar__title">{t('left.countries')}</h3>
            {loading && <PreloadTopCountries />}
            {!loading && (
                <>
                    <Accordion className="list">
                        {countriesFiltered.map((country: any, key: number) => (
                            <AccordionSection
                                key={`top-country-${key}`}
                                data={country.competitions}
                                countriesCompetitionLoading={countriesCompetitionLoading}
                                preload={() =>
                                    !country.competitions &&
                                    updateCountries({
                                        sportId: 1,
                                        countryId: country.id,
                                        locale: i18n.language,
                                    })
                                }
                                header={
                                    <div className="list__item list__item--sidebar">
                                        <div className="list__link list__link--sidebar flex-justify">
                                            <div>
                                                <Image
                                                    src={`https://i.oddsocean.com/img/logos/teams/40x40/flag${country.flag}.png`}
                                                    alt={country.title}
                                                    className="list__ico"
                                                    width={40}
                                                    height={40}
                                                />
                                                <span>{country.title}</span>
                                            </div>
                                            <Image
                                                src={arrow_black}
                                                className="list__link-ico"
                                                alt="ava"
                                                width={12}
                                            />
                                        </div>
                                    </div>
                                }
                                content={
                                    <div className="list">
                                        {country.competitions?.map(
                                            (
                                                competitions: any,
                                                index: number
                                            ) => (
                                                <CompetitionItem
                                                    key={`competitions-${key}-${index}`}
                                                    competition={competitions}
                                                />
                                            )
                                        )}
                                    </div>
                                }
                            />
                        ))}
                    </Accordion>

                    {isTop && (
                        <button
                            className="button button--show-all"
                            onClick={() => setTop(false)}
                        >
                            <span className="button__text button__text--show-all">
                                {t('left.show_all')}
                            </span>
                            <Image
                                src={arrow}
                                alt="arrow"
                                loading="lazy"
                                className="button__ico button__ico--show-all"
                                width={17}
                                height={17}
                            />
                        </button>
                    )}
                </>
            )}
        </div>
    )
}
