import React from 'react'
import {CompetitionMenuIndex, useGetCompetitionPlayoffQuery} from '../../../../api';
import {Playoff} from '../../../../components/match/standings/parts';
import Dropdown from '../../../../components/Dropdown';
import {useContainerWidth} from '../../../../hooks';
import HeaderDropdown from '../../../../components/HeaderDropdown';
import {LoaderTemp} from '../../../../components/LoaderTemp';
import st from './LeaguePlayoff.module.scss'
import Image from 'next/image';
import full_screen from '../../../../public/img/full-screen.svg';
import clsx from 'clsx';

type Props = {
    competitionId: number,
    season: string,
    locale: string,
    changePageTab?: (index: CompetitionMenuIndex) => void,
    isInSidebar?: boolean,
}

/** Playoff block, with support for selection multiple playoffs */
export const LeaguePlayoff = ({
    competitionId,
    season,
    locale,
    isInSidebar,
    changePageTab,
}: Props) => {
    const [activeIndex, setActiveIndex] = React.useState(0)

    const {
        containerRef,
        blockWidth,
    } = useContainerWidth()

    const {data, isFetching } = useGetCompetitionPlayoffQuery({
        competitionId,
        season,
        locale,
    })

    const playoffsList = (data?.data?.playoffs || []).map(({title}, index) => ({
        title: String(title),
        value: String(index),
    }))

    return (
        <div
            className={st.container}
            ref={containerRef}
        >
            {isFetching && <LoaderTemp />}
            {!isFetching && data?.data?.playoffs && (
                <>
                    <div className='flex flex-column grid--8'>
                        {isInSidebar && (
                            <div className='flex flex-align flex-justify grid--8 mt-12'>
                                <span className={st.title}>
                                    ПЛЕЙ-ОФФ
                                </span>
                                <button
                                    className='button button--matchday-menu button--matchday-menu-top'
                                    onClick={() => {
                                        if (changePageTab) changePageTab('playoff')
                                    }}
                                >
                                    <Image
                                        src={full_screen}
                                        alt=''
                                        width={16}
                                        height={16}
                                    />
                                </button>
                            </div>
                        )}
                        {playoffsList.length > 1 && (
                            <Dropdown
                                className={clsx('dropdown-matches--competition', !isInSidebar && st.dropdownWidthFixed)}
                                options={playoffsList}
                                value={String(activeIndex)}
                                onChange={({value}: any) => setActiveIndex(Number(value))}
                                header={<HeaderDropdown/>}
                            />
                        )}
                    </div>
                    <Playoff
                        className={st.playoff}
                        data={data.data.playoffs[activeIndex].playoff}
                        blockWidth={blockWidth}
                    />
                </>
            )}
        </div>
    )
}