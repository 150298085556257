import React from 'react'
import {MatchFixtures} from '../../../../api';
import {ICommandsMatch} from '../../../../types';
import {MatchItem} from '../../../MatchItem';
import styles from './Fixtures.module.css'

type Props = {
    data: MatchFixtures,
}

export const Fixtures = ({data}: Props) => (
    <div className='sidebar-content-container'>
        {data.stage && (
            <h6 className={styles.title}>
                {data.stage}
            </h6>
        )}
        <div className='flex flex-column grid--4'>
            {data.matches.map((match) => (
                <MatchItem
                    key={match.match_id}
                    match={match as unknown as ICommandsMatch}
                />
            ))}
        </div>
    </div>
)