import Image from 'next/image'
import { useRouter } from 'next/router'
import React, { useState, useRef, useEffect, useMemo } from 'react'
import { useTranslation } from 'next-i18next'
import moment from 'moment'
import {
    getCompetition,
    getCountries,
    useGetCompetitionQuery,
} from '../../../api/competitionsApi'
import {useAppDispatch, useAppSelector} from '../../../hooks'
import Dropdown from '../../../components/Dropdown'
import {
    removeFavorites,
    addFavorites,
    setFavorites,
    setFull, selectRoutePrev,
} from '../../../features/app'
import {SidebarBreadcrumbs} from './SidebarBreadcrumbs';
import PreloadCenterContent from '../../../components/PreloaderCenterContent'
import Sidebar from '../../../components/Sidebar'
import {
    CompetitionMenuIndex, CompetitionMenusArray,
    getMatches,
    getTopCompetitions, matchesApi,
    useGetMatchesQuery,
    useUpdateCompetitionMatchesMutation,
} from '../../../api'
import CountriesBlock from '../../../components/CountriesBlock'
import HeaderDropdown from '../../../components/HeaderDropdown'
import Cookies from 'js-cookie'
import CompetitionMatchesBlock from '../../../components/CompetitionMatchesBlock'
import HeaderDropdownGray from '../../../components/HeaderDropdownGray'
import MatchesNotFound from '../../../components/MatchesNotFound'
import Head from 'next/head'
import {LeaguesInCountry} from '../../../components/league';
import {MatchDetailBlocks} from '../../../components/match-details';
import {isRequestFromBot} from '../../../api';
import clsx from 'clsx';
import {LeaguePlayoff, LeagueStandings} from './containers';
import {ContainerContentSub, SidebarContentContainer} from './parts';
import st from './LeaguePage.module.scss';
import { useSelector } from 'react-redux'

/**
 * @todo - Refactor render content -> divide into components
 * @todo - Add adpative for skeleton
 * **/
export const LeaguePage = (_props: any) => {
    const today = moment(new Date()).format('YYYY-MM-DD')
    const { i18n } = useTranslation()
    const dispatch = useAppDispatch()

    const router = useRouter()

    // Url example: football/russia/premier-league/24-25/fixtures
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [querySportType, queryCountry, queryLeague,
        querySeason,
        queryRoundOrTab,
    ] = router.query.slug as string[]

    /** State */

    const favorites = useAppSelector((state) => state.app.favorites)
    const isSidebarFullSize = useAppSelector((state) => state.app.full)

    const [updateCompetitionMatches, { }] = useUpdateCompetitionMatchesMutation()

    // Matches

    const [matchSelectedId, setMatchSelectedId] = useState(0)
    const [oddType, setOddType] = useState('3w')
    const [openedCompetitions, setCompetitions] = useState([])
    const [round, setRound] = useState('choice')

    // Filters

    const [competitionId, setCompetitionId] = useState(_props.id)
    const [sport] = useState('1')
    const [season, setSeason] = useState(_props.season);
    const [matchesType, setMatchesType] = useState(querySeason ? 'season' : 'actual')
    const [tabActive, setTabActive] = useState<CompetitionMenuIndex>('matches')

    const refIsMounted = useRef(false)

    const routePrev = useSelector(selectRoutePrev)

    // Reset sidebar full-size
    useEffect(() => {
        // Works on clicking breadcrumbs in MatchDay full-size
        if (isSidebarFullSize && !querySeason) {
            dispatch(setFull(false))
        }
    }, [querySeason])

    // Reset state
    useEffect(() => {
        if (!refIsMounted.current) return

        setCompetitionId(_props.id)
        setMatchSelectedId(0)
        setSeason(_props.season)
        setOddType('3w')
        setRound('choice')
        setTabActive('matches')
    }, [_props.id])

    /** Loading data */

    const competitionQuery = useGetCompetitionQuery({
        locale: i18n.language,
        id: competitionId,
        season: season,
    })
    const isPlayoffAvailable = competitionQuery.data?.data?.menu &&
        competitionQuery.data.data.menu.find((i) => i.index === 'playoff')
    const isFixturesAvailable = competitionQuery.data?.data?.menu &&
        competitionQuery.data.data.menu.find((i) => i.index === 'fixtures')

    const matchesQuery = useGetMatchesQuery({
        sport: sport,
        oddType: oddType,
        type: matchesType,
        date: season,
        locale: i18n.language,
        competition_id: competitionId,
    }, {
        // Wait until competition is loaded -> set actual season for new competition
        skip: competitionQuery.isFetching,
    })

    /** Local data */

    const oddTypes = (matchesQuery.data?.data?.odds_types || []).map((type: any) => ({
        value: type.type,
        title: type.title,
    }))

    const oddsSelects = matchesQuery.data?.data?.odds_selects

    const seasons =   (competitionQuery.data?.data?.seasons || []).map((type) => ({
        value: type.index,
        title: type.title,
    }))

    // Show Actual filter only for the first season in list
    const isSeasonActualSelected = season === seasons[0]?.value

    const rounds =  (competitionQuery.data?.data?.rounds || []).map((type) => ({
        value: type.index,
        title: type.title,
    }))

    //@todo
    const competitionPrepared = useMemo(() => {
        const obj: any = {}
        competitionQuery.data?.data?.competition.forEach((comp) => {
            if (comp.type == 'gender') {
                obj.gender = { ...comp }
            }
            if (comp.type == 'country') {
                obj.country = { ...comp }
            }
            if (comp.type == 'competition') {
                obj.competition = { ...comp }
            }
        })
        return obj
    }, [competitionQuery?.data?.data])

    const filtersUrlString = useMemo(() => getLeagueFiltersUrlString(
        season,
        round,
        tabActive,
        isSeasonActualSelected,
    ), [competitionQuery.isFetching, competitionId, season, round, tabActive])

    // On each filer update -> update page url
    // On sidebar close -> restore filters in ulr
    useEffect(() => {
        if (!refIsMounted.current
            || isSidebarFullSize
            || !competitionPrepared.competition
            || competitionQuery.isFetching
        ) return

        const newUrl = competitionPrepared.competition.url + filtersUrlString
        if (newUrl === router.asPath) return
        router.push(
            newUrl,
            undefined,
            {
                locale: i18n.language,
                shallow: true,
                scroll: true,
            }
        )
    }, [filtersUrlString, isSidebarFullSize, competitionQuery.isFetching])

    // On each url change -> update filters
    useEffect(() => {
        if (isSidebarFullSize) return

        setSeason(querySeason ?? _props.season)

        if (!queryRoundOrTab) {
            setRound('choice')
            setTabActive('matches')
            return
        }

        if (CompetitionMenusArray.includes(queryRoundOrTab as CompetitionMenuIndex)) {
            setTabActive(queryRoundOrTab as CompetitionMenuIndex)
        } else {
            setRound(queryRoundOrTab)
            setTabActive('matches')
        }
    }, [routePrev])

    /** Hooks */

    const parentRef = useRef(null);

    useEffect(() => {
        refIsMounted.current = true
        return () => {
            refIsMounted.current = false
        }
    }, []);

    /** Functions */

    function _toggleFavorite(type: string, id: string) {
        const copy = JSON.parse(localStorage.getItem('favorites'))
        const token = Cookies.get('auth-token')
        if (favorites[type].indexOf(id) !== -1) {
            // remove
            copy[type].splice(copy[type].indexOf(id), 1)
            if (token) dispatch(removeFavorites({ type, id }))
        } else {
            // add
            copy[type].push(id)
            if (token) dispatch(addFavorites({ type, id }))
        }
        dispatch(setFavorites(copy))
        localStorage.setItem('favorites', JSON.stringify(copy))
    }

    async function _loadCompetion(id: string) {
        if (openedCompetitions.includes(id)) {
            const updatedItems = openedCompetitions.filter(
                (existingItem) => existingItem !== id
            )
            setCompetitions(updatedItems)
        } else {
            setCompetitions([...openedCompetitions, id])
        }
        await updateCompetitionMatches({
            sport: sport,
            oddType: oddType,
            type: matchesType,
            date: today,
            competitionId: id,
            locale: i18n.language,
            competitions: openedCompetitions,
        })
    }

    function _setTypeMatches(type: string) {
        setCompetitions([])
        setRound('choice');
        setMatchesType(type)
    }

    function selectMatch(id: string, live: boolean, url: string) {
        if (window.innerWidth <= 1150) {
            router.push(router.locale + url, '', {
                locale: router.locale,
                shallow: false,
            })
            return
        }

        setMatchSelectedId(Number(id))
    }

    function handleTabActiveChange(index: CompetitionMenuIndex) {
        setTabActive(index)
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    return (
        <>
            <Head>
                <title>{_props?.meta?.title}</title>
                <meta name='description' content={_props?.meta?.description} />
            </Head>
            <>
                <div className='flex grid grid--12 container container--mobile'>
                    <Sidebar side='left' className={'sidebar--leagues'}>
                        {competitionPrepared.country && (
                            <LeaguesInCountry
                                country={competitionPrepared.country}
                                sportId={Number(sport)}
                                locale={i18n.language}
                                competitionCurrentId={competitionPrepared.competition.id}
                            />
                        )}
                        <CountriesBlock />
                    </Sidebar>
                    <div className='content flex content--league'>
                        <div className={clsx('league-header grid grid--16', isSidebarFullSize && 'league-header--hidden')}>
                            {competitionQuery.isFetching && (
                                <div className={clsx(st.skeletonHeaderContainer, 'preloader preloader--matchday')}>
                                    <div className='league-header__image loading-background' />
                                    <div className='league-header__col flex flex-justify flex-column full-width'>
                                        <div className='flex flex-justify full-width league-header__top-list'>
                                            <div className='league-header__content flex flex-align grid grid--8'>
                                                <div className='league-header__country loading-background' />
                                                <div className='flex flex-column grid--2 league-header__col-d'>
                                                    <div className='loading-pill' />
                                                    <div className='loading-pill' />
                                                </div>
                                            </div>
                                            <div className='loading-pill height-full' />
                                        </div>
                                        <div className={st.skeletonHeaderRowSecondGrid}>
                                            <div className='loading-pill' />
                                            <div className='loading-pill' />
                                            <div className='loading-pill' />
                                            <div className='loading-pill' />
                                            <div className='loading-pill' />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!competitionQuery.isFetching && (
                                <>
                                    <div className='league-header__col'>
                                        <div className='league-header__image'>
                                            <Image
                                                src={`https://i.oddsocean.com/img/ocean/260x260/${competitionQuery.data.data.logo_bw}.png`}
                                                alt='logo'
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                    </div>
                                    <div className='league-header__col flex flex-justify flex-column full-width'>
                                        <div className='flex flex-justify full-width league-header__top-list'>
                                            <div className='league-header__content flex flex-align grid grid--8'>
                                                {competitionPrepared?.country && (
                                                    <div className='league-header__country'>
                                                        <Image
                                                            src={`https://i.oddsocean.com/img/ocean/40x40/${competitionQuery.data?.data?.logo}.png`}
                                                            alt='ava'
                                                            width={24}
                                                            height={24}
                                                        />
                                                    </div>
                                                )}
                                                <div className='flex flex-column league-header__col-d'>
                                                    <p className='league-header__title'>
                                                        {competitionPrepared?.competition?.title}
                                                    </p>
                                                    <p className='league-header__subtitle'>
                                                        {competitionPrepared?.country?.title}
                                                    </p>
                                                </div>
                                            </div>
                                            <Dropdown
                                                value={season}
                                                className={'dropdown-matches--competition'}
                                                options={seasons}
                                                onChange={(item: any) => {
                                                    setSeason(item.value)
                                                    if (item.value !== rounds[0]?.value) {
                                                        _setTypeMatches('season')
                                                    }
                                                }}
                                                header={<HeaderDropdown className={''} />}
                                            />
                                        </div>
                                        <div className='menu menu--top menu--white menu--competition flex grid grid--24'>
                                            {competitionQuery.data?.data?.menu.map((item) => (
                                                <div
                                                    key={item.index}
                                                    className={clsx('menu__item', item.index === tabActive && 'menu__item--active')}
                                                    onClick={() => setTabActive(item.index)}
                                                >
                                                    {item.title}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                        {tabActive === 'matches' && (
                            <div className={clsx('layout-main-with-sidebar', isSidebarFullSize && 'layout-main-with-sidebar--full')}>
                                <div className={clsx('content__main', isSidebarFullSize && 'content__main--hidden')}>
                                    <div className='submenu submenu--content-filter'>
                                        {isSeasonActualSelected && (
                                            <button
                                                className={clsx(
                                                    'button button--filter-content',
                                                    matchesType == 'actual' && round == 'choice' && 'button--filter-content-active',
                                                )}
                                                onClick={() => _setTypeMatches('actual')}
                                            >
                                                Актуальные
                                            </button>
                                        )}
                                        <button
                                            className={clsx(
                                                'button button--filter-content',
                                                matchesType == 'season' && round == 'choice' && 'button--filter-content-active',
                                            )}
                                            onClick={() => _setTypeMatches('season')}
                                        >
                                            Весь сезон
                                        </button>
                                        {rounds.length > 0 && (
                                            <Dropdown
                                                value={round}
                                                className={clsx(
                                                    'dropdown-matches--competition dropdown-matches--competition-rounds',
                                                    round !== 'choice' && 'dropdown-matches--competition-rounds-active',
                                                )}
                                                options={rounds}
                                                all={'Выбор тура'}
                                                onChange={(e: any) => {
                                                    setRound(e.value)
                                                    // Load all season matches, when switching from Actual to round filter
                                                    if (matchesType === 'actual') {
                                                        setMatchesType('season')
                                                        setCompetitions([])
                                                    }
                                                }}
                                                header={<HeaderDropdownGray />}
                                                useAbsolutePosition
                                                fitContainerWidth
                                            />
                                        )}
                                    </div>
                                    <div
                                        className='wrapper'
                                        ref={parentRef}
                                    >
                                        {matchesQuery.isFetching
                                            ? <PreloadCenterContent/>
                                            : matchesQuery.data && (
                                                <>
                                                    <div className='content-header flex'>
                                                        <div className='flex flex-align grid grid--12'>
                                                            <h4 className='content-header__title'>
                                                                Матчи
                                                            </h4>
                                                        </div>
                                                        {matchesQuery.data.data.competitions.length > 0 &&
                                                        <div className='dropdown-wrapper'>
                                                            <Dropdown
                                                                value={oddType}
                                                                options={oddTypes}
                                                                onChange={(e: any) => {
                                                                    setOddType(e.value)
                                                                }}
                                                                header={<HeaderDropdown/>}
                                                            />
                                                        </div>
                                                        }
                                                    </div>
                                                </>
                                            )
                                        }
                                        {!matchesQuery.isFetching && matchesQuery.data?.data.competitions.length == 0 && <MatchesNotFound/>}
                                        {!matchesQuery.isFetching &&
                                            matchesQuery.data?.data.competitions.map(
                                                (competition: any, index: number) => (
                                                    <CompetitionMatchesBlock
                                                        onClickMatch={selectMatch}
                                                        competition={competition}
                                                        key={`competition-${matchesType}-${competition.id}`}
                                                        index={index}
                                                        matchesType={matchesType}
                                                        odds_selects={oddsSelects}
                                                        favorites={favorites}
                                                        round={round}
                                                        onClickFavorite={_toggleFavorite}
                                                        onClickCompetition={_loadCompetion}
                                                        oddType={oddType}
                                                        groupByRounds={rounds.length > 0 && round === 'choice'}
                                                    />
                                                )
                                            )}
                                    </div>
                                </div>
                                {matchSelectedId
                                    ? (
                                        <MatchDetailBlocks
                                            key={matchSelectedId}
                                            matchId={matchSelectedId}
                                            isBlockFullSize={isSidebarFullSize}
                                        />
                                    )
                                    : (
                                        <Sidebar side='right'>
                                            <SidebarBreadcrumbs
                                                competition={competitionQuery.data?.data?.competition ?? []}
                                            />
                                            <SidebarContentContainer>
                                                {isPlayoffAvailable && (
                                                    <LeaguePlayoff
                                                        competitionId={competitionId}
                                                        season={season}
                                                        locale={i18n.language}
                                                        changePageTab={handleTabActiveChange}
                                                        isInSidebar
                                                    />
                                                )}
                                                {isFixturesAvailable && (
                                                    <LeagueStandings
                                                        competitionId={competitionId}
                                                        season={season}
                                                        locale={i18n.language}
                                                        changePageTab={handleTabActiveChange}
                                                        isInSidebar
                                                    />
                                                )}
                                            </SidebarContentContainer>
                                        </Sidebar>
                                    )
                                }
                            </div>
                        )}
                        {tabActive === 'playoff' && (
                            <ContainerContentSub>
                                <LeaguePlayoff
                                    competitionId={competitionId}
                                    season={season}
                                    locale={i18n.language}
                                />
                            </ContainerContentSub>
                        )}
                        {tabActive === 'fixtures' && (
                            <ContainerContentSub>
                                <LeagueStandings
                                    competitionId={competitionId}
                                    season={season}
                                    locale={i18n.language}
                                />
                            </ContainerContentSub>
                        )}
                    </div>
                </div>
            </>

        </>

    )
}

function getLeagueFiltersUrlString(
    season: string,
    round: string,
    tabActive: CompetitionMenuIndex,
    isSeasonActualSelected: boolean,
) {
    const filterSecond = tabActive !== 'matches' ? `/${tabActive}`
        : round === 'choice' ? '' : `/${round}`
    const filterSeason = !filterSecond && isSeasonActualSelected ? '' : `/${season}`

    return `${filterSeason}${filterSecond}`
}

LeaguePage.getSSRProps = (async (store: any, context: any) => {
    const id = context.query.id[0];
    const season = context.query.id[1];

    const r1 = await store.dispatch(
        getCompetition.initiate({
            locale: context.locale,
            id: id,
            season: season,
        })
    );
    const meta = r1.data?.meta;

    if (isRequestFromBot(context)) {
        const requests = [
            store.dispatch(
                getMatches.initiate({
                    sport: '1',
                    oddType: '3w',
                    type: 'season',
                    date: season,
                    locale: context.locale,
                    competition_id: id,
                })
            ),
            store.dispatch(
                getTopCompetitions.initiate({locale: context.locale})
            ),
            store.dispatch(
                getCountries.initiate({locale: context.locale})
            ),
        ]
        const requestPromses = Promise.all(requests)
        const storePromises = Promise.all(
            store.dispatch(matchesApi.util.getRunningQueriesThunk())
        )
        await Promise.all([requestPromses, storePromises])
    }

    return {
        id: id,
        season: season,
        meta: meta,
    }
});
