import React from 'react'

type Props = {

}

export default function MatchesNotFound({

}: Props) {

    return (
        <div className='matches-not-found'>
            <span className='matches-not-found__title'>На данный момент нет матчей с выбранным фильтром.</span>
        </div>
    )
}
