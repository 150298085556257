import { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import MatchRow from './MatchRow'
import PreloaderMatches from './PreloaderMatches'
import clsx from 'clsx'

export default function CompetitionMatchesBlock({
    index,
    odds_selects,
    competition,
    favorites,
    onClickMatch,
    onClickFavorite,
    round,
    oddType,
    groupByRounds,
    matchesType,
}: any) {
    const [expanded, setExpanded] = useState<boolean>(true)
    const [hidden, setHidden] = useState<boolean>(false)

    const { t } = useTranslation('common')
    // check expanded first load
    const timer: any = null
    const el = useRef(null)
    useEffect(() => {
        if (competition.matches.length == 0) setExpanded(false)
        // else {
        //     // setExpanded(true)
        // }
    }, [competition.matches])

    useEffect(() => {
        el.current.addEventListener(
            'transitionend',
            function (e: any) {
                if (
                    expanded &&
                    e.propertyName == 'max-height' &&
                    e.target.offsetHeight > 0
                ) {
                    setHidden(false)
                }
            },
            false
        )
    })

    useEffect(() => {
        clearTimeout(timer)
        if (!expanded) {
            setHidden(true)
        }
    }, [expanded])

    function _onClickFavorite(e: any, type: string, id: string) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        onClickFavorite(type, id as string)
    }

    function compareFnDate(a: any, b: any) {
        if (a.date_start < b.date_start) {
            return -1
        } else if (a.date_start > b.date_start) {
            return 1
        }
    }

    const roundStage = round.includes('matchday')
        ? round.split('-')[1] ?? 'choice'
        : round
    const isRoundSelected = round !== 'choice'

    const competitionFiltered = matchesType === 'actual'
        ? competition.matches
        : round === 'choice'
            ? competition.matches.slice().sort(compareFnDate)
            : competition.matches.slice().sort(compareFnDate).filter((i: any) => i?.stage?.id === roundStage)

    function checkTwoDatesInSameDay(dateFirst: Date, dateSecond: Date) {
        return dateFirst.getDate() === dateSecond.getDate()
            && dateFirst.getMonth() === dateSecond.getMonth()
    }

    return (
        <div
            className={`content-block  ${index === 0 ? 'content-block--first' : ''
            } ${index >= 9 ? 'content-block--visibility-auto' : ''}`}
        >
            <div className="table table--match table--competition">
                <div
                    ref={el}
                    className={`table__content ${hidden ? 'table__content--hidden' : ''
                    }`}
                >
                    {competitionFiltered.map((match: any, index: number) => {
                        const isOrderPrevDiff = isRoundSelected && match.stage?.order !== competitionFiltered[index - 1]?.stage?.order
                        const isDatePrevDiff = index > 0 && !checkTwoDatesInSameDay(new Date(match.date_start * 1000), new Date(competitionFiltered[index - 1].date_start * 1000))
                        const isDateNextDiff = index === competitionFiltered.length - 1 ||
                            !!competitionFiltered[index + 1] && !checkTwoDatesInSameDay(new Date(match.date_start * 1000), new Date(competitionFiltered[index + 1].date_start * 1000))
                        return (
                            <div
                                className={clsx('competition-match-row', isDateNextDiff && 'competition-match-row__last-row')}
                                key={index}
                            >
                                {groupByRounds
                                    && match.stage?.title
                                    && (index === 0 || (index > 0 && isOrderPrevDiff))
                                    && (
                                        <div
                                            className={clsx('badge badge--tour ', index === 0 && 'badge--tour-first')}
                                            key={index}
                                        >
                                            {match.stage.title}
                                        </div>
                                    )
                                }
                                {index === 0 ||
                                isOrderPrevDiff ||
                                isDatePrevDiff
                                    ? (
                                        <div
                                            className={`wrapper-header-match ${(index == 0 && round !== 'choice') ? 'wrapper-header-match--no' : ''}`}
                                            key={`wrapper-header-${index}`}
                                        >
                                            <div
                                                className={`table__header ${expanded
                                                    ? 'table__header--expanded'
                                                    : ''
                                                } ${competition.matches.length > 0
                                                    ? 'table__header--opened'
                                                    : ''
                                                } table__header--match flex flex-align`}
                                            >
                                                <div
                                                    className="flex flex-align grid grid--8"
                                                    style={{
                                                        overflow: 'hidden',
                                                        width: '100%',
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            overflow: 'hidden',
                                                            whiteSpace: 'nowrap',
                                                            width: '100%',
                                                            marginRight: 25,
                                                            height: 16,
                                                            position: 'relative',
                                                        }}
                                                    >
                                                        <div className="">
                                                            <div className="table__header-title flex grid grid--2">
                                                                <span>
                                                                    {t(
                                                                        'competition.day',
                                                                        {
                                                                            date: new Date(
                                                                                match?.date_start
                                                                            ),
                                                                        }
                                                                    )}
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {competition.matches.length > 0 && (
                                                        <div className="flex flex-align grid grid--4 matches-bets">
                                                            {odds_selects.map(
                                                                (
                                                                    select: any,
                                                                    _index: number
                                                                ) => (
                                                                    <div
                                                                        key={`competition-${competition.id}-odd-${_index}`}
                                                                        className="table__header-col table__header-col--bet"
                                                                    >
                                                                        {
                                                                            select.odd_select_display
                                                                        }
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        ''
                                    )}
                                {index == 0 && (
                                    <div
                                        className={`subheader subheader--mobile ${expanded ? 'subheader--expanded' : ''
                                        }`}
                                    >
                                        <div className="flex flex-align grid grid--4 subheader__container">
                                            {odds_selects.map((select: any, _index: number) => (
                                                <div
                                                    key={`competition-${competition.id}-odd-${_index}`}
                                                    className="table__header-col table__header-col--bet"
                                                >
                                                    {select.odd_select_display}
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                )}
                                <MatchRow
                                    competition={competition}
                                    match={match}
                                    key={`match-row-competition-${index}`}
                                    index={index}
                                    onClickMatch={onClickMatch}
                                    _onClickFavorite={_onClickFavorite}
                                    odds_selects={odds_selects}
                                    favorites={favorites}
                                    oddType={oddType}
                                />
                            </div>
                        )
                    })}
                    {competition.matches.length == 0 && (
                        <PreloaderMatches count={competition.matches_count} />
                    )}
                </div>
            </div>
        </div>
    )
}
