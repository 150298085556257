import React, { useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import logo_book_ocean from '../public/img/logo-book-ocean.svg'
import calculator from '../public/img/calculator-ico.svg'
import { useCustomTranslation } from '../hooks/useCustomTranslation'
import clsx from 'clsx';
import {BookmakerLinkRedirect} from './BookmakerLinkRedirect';
import { Logo } from './logo'
type Props = {
    forecast?: any
    openedForecast?: boolean
    full?: boolean
    typeMatch: string
}

export default function Forecast({
    forecast,
    full = false,
    openedForecast = false,
    typeMatch,
}: Props) {
    const [fullText, setFullText] = useState<boolean>(false)
    const { t } = useCustomTranslation('common')

    const staticPath: string = 'https://i.oddsocean.com/img/'

    useEffect(() => {
        if (openedForecast) setFullText(true)
    }, [openedForecast])

    function getResult(result: string) {
        switch (result) {
        case 'win':
            return 'прогноз прошел'
        case 'lose':
            return 'прогноз не прошел'
        case 'back':
            return 'Возврат по прогнозу'
        }
    }

    const text: string[] = React.useMemo(() => (forecast?.fulltext || '')
        .split('<br>')
        .filter(Boolean)
        .filter((i: string) => i !== '\r')
    , [forecast?.match_id]
    )

    return (
        <div className="slider__slide">
            {typeMatch !== 'match' && (
                <div className="slider__slide-part slider__slide-part--top">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb breadcrumb--block">
                            {forecast?.competition.map(
                                (competition: any, index: number) => (
                                    <li
                                        key={`forecast-${forecast.match_id}-competition-breadcrumb-${index}`}
                                        className="breadcrumb-item"
                                        aria-current="page"
                                    >
                                        {competition.title}
                                    </li>
                                )
                            )}
                            {forecast?.stage && (
                                <li
                                    className="breadcrumb-item"
                                    aria-current="page"
                                    key={'match-stage'}
                                >
                                    <Link
                                        className="breadcrumb__link"
                                        href={forecast?.stage}
                                    >
                                        {forecast?.stage}
                                    </Link>
                                </li>
                            )}
                        </ol>
                    </nav>
                    <Link href={forecast?.url + '#forecast-block'}>
                        <div className="flex flex-align slider__content-top">
                            <div className="flex flex-align slider__left">
                                <Image
                                    src={`${staticPath}logos/teams/80x80/${forecast?.team1.logo}.png`}
                                    alt={forecast?.team1.title}
                                    className="slider__ava"
                                    width={40}
                                    height={40}
                                />
                                <span className="slider__text slider__text--left">
                                    {forecast?.team1.title}
                                </span>
                            </div>
                            <div className="flex flex-align slider__center">
                                <span className="slider__text">-</span>
                            </div>
                            <div className="flex flex-align slider__right">
                                <span className="slider__text slider__text--right">
                                    {forecast?.team2.title}
                                </span>
                                <Image
                                    src={`${staticPath}logos/teams/80x80/${forecast?.team2.logo}.png`}
                                    alt={forecast?.team2.title}
                                    className="slider__ava"
                                    width={40}
                                    height={40}
                                />
                            </div>
                        </div>
                    </Link>
                    <p className="slider__time">
                        {t('match.date_start', {
                            date: new Date(forecast?.date_start),
                        })}
                    </p>
                </div>
            )}
            <div
                className={clsx(
                    'slider__slide-part slider__slide-part--bottom flex flex-column grid--16',
                    fullText && 'slider__slide-part--bottom--full'
                )}
            >
                <div className="bet-badge flex flex-center flex-align">
                    <span className="bet-badge__text">
                        {forecast?.title_prediction}
                    </span>
                </div>
                <div
                    className={clsx(
                        'slider__forecast-text-wrapper flex flex-column grid--16',
                        fullText && 'slider__forecast-text-wrapper--full'
                    )}
                >
                    <div className={clsx('forecast__bets flex grid--16', !full && 'forecast__bets--sidebar')}>
                        {!forecast?.odd?.display && !forecast?.result && (
                            <div className="no-content no-content--forecast">
                                <Image
                                    src={calculator}
                                    alt="calculator"
                                    width={24}
                                    height={24}
                                />
                                <p className="no-content__title">
                                    Ставки не доступны
                                </p>
                            </div>
                        )}
                        {forecast?.result && (
                            <div className="forecast-result">
                                <p className="forecast-result__title">
                                    {getResult(forecast?.result)}
                                </p>
                                <div className={`forecast-result__bet forecast-result__bet--${forecast?.result}`}>
                                    1.23
                                </div>s
                            </div>
                        )}
                        {forecast?.odd?.display && (
                            <div className='flex flex-column flex-grow grid--4'>
                                <h5 className="forecast__bets-title  forecast__bets-title--upper">
                                    {t('global.best_odd')}
                                </h5>
                                <div className="forecast__bet-wrapper forecast__bet-wrapper--middle">
                                    <div className="flex flex-align">
                                        <BookmakerLinkRedirect
                                            bookmakerId={forecast?.odd.bookmaker.id}
                                            bookmakerLogoId={forecast?.odd.bookmaker.logo_id}
                                            bookmakerColor={forecast?.odd.bookmaker.color}
                                        >
                                            <div
                                                className="bookmaker-badge"
                                                style={{
                                                    backgroundColor: `#${forecast?.odd.bookmaker?.color}`,
                                                }}
                                            >
                                                <Logo
                                                    type='bookmaker_original'
                                                    logoId={forecast?.odd.bookmaker.id}
                                                    size={72}
                                                    height={24}
                                                />
                                            </div>
                                        </BookmakerLinkRedirect>
                                    </div>
                                    <div className="flex flex-align grid grid--8">
                                        <div className="bet">
                                            <span className="bet__title">
                                                {forecast?.odd.display}
                                            </span>
                                        </div>
                                        <BookmakerLinkRedirect
                                            bookmakerId={forecast?.odd.bookmaker.id}
                                            bookmakerLogoId={forecast?.odd.bookmaker.logo_id}
                                            bookmakerColor={forecast?.odd.bookmaker.color}
                                            matchId={forecast.match_id}
                                            oddType={forecast?.odd.odd_type}
                                            oddSelect={forecast?.odd.odd_select}
                                            period={forecast?.odd.period}
                                            handicap={forecast?.odd.handicap}
                                            className="button button--rounded button--upper button--primary-color button--forecast-bet"
                                        >
                                            <span>
                                                {t('global.place_bet')}
                                            </span>
                                        </BookmakerLinkRedirect>
                                    </div>
                                </div>
                            </div>
                        )}
                        {forecast?.odd?.display && forecast?.maker && (
                            <div className='flex flex-column flex-grow grid--4'>
                                <h5 className="forecast__bets-title forecast__bets-title--upper">
                                    {t('global.betting_contest')}
                                </h5>
                                <div className="forecast__bet-wrapper">
                                    <Image
                                        className="flex flex-align"
                                        src={logo_book_ocean}
                                        alt="odds ocean"
                                        height={30}
                                        width={80}
                                    />
                                    {forecast?.maker.value ? (
                                        <div className="flex flex-align grid grid--8">
                                            {forecast?.maker.value && (
                                                <div className="bet">
                                                    <span className="bet__title">
                                                        {forecast?.maker.display}
                                                    </span>
                                                </div>
                                            )}
                                            <button className="button button--rounded button--upper button--primary-color button--forecast-bet button--virt-book">
                                                <span>
                                                    {t('global.to_betslip')}
                                                </span>
                                            </button>
                                        </div>
                                    ) : (
                                        <div className="flex flex-align">
                                            <div className="button button--rounded button--upper  button--forecast-bet button--not-avalaible">
                                                <span>
                                                    {t('global.odd_unavailable')}
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                    <h5 className="forecast__bets-title slider__title-forecast">
                        {forecast?.title}
                    </h5>
                    <div  className="slider__text-forecast flex flex-column grid--16">
                        {text.map((i, index) => (
                            <p
                                key={index}
                                className={clsx('slider__text-paragraph', fullText && 'color-white')}
                            >
                                {i}
                            </p>
                        ))}
                    </div>
                    {!fullText &&
                        (!full ? (
                            <Link
                                className="button button--forecast"
                                href={forecast?.url + '#forecast-block'}
                            >
                                <span>{t('tips.read_more')}</span>
                            </Link>
                        ) : (
                            <button
                                onClick={() => setFullText(true)}
                                className="button button--forecast"
                            >
                                <span>{t('tips.read_more')}</span>
                            </button>
                        ))}
                </div>
            </div>
        </div>
    )
}
