import React, { useRef, useState, useEffect } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import arrow_black from '../public/img/arrow_black.svg'
import Breadcrumbs from './Breadcrumbs'
import { useCustomTranslation } from '../hooks/useCustomTranslation'
import {BookmakerLinkRedirect} from './BookmakerLinkRedirect';
import { Logo } from './logo'

type Props = {
    surebet: any
    type: 'match' | 'matchday'
}

export default function Surebet({ surebet, type }: Props) {
    const staticPath = 'https://i.oddsocean.com/img/logos/'

    // Uncontrolled Components
    const totalStakeRef = useRef(null)
    const returnStakeRef = useRef(null)

    const block = useRef(null)

    // refs of stake
    const stakeRef = useRef([])

    const { t } = useCustomTranslation('common')
    const badge = useRef(null)
    const [totalStake, setTotalStake] = useState<number>(100)

    const [stakes, setStakes] = useState<any>({})

    const [returnStake, setReturnStake] = useState<number>(100)

    const [expanded, setExpanded] = useState<boolean>(false)

    const handleKeyPress = (event: any) => {
        // Регулярное выражение для проверки символов
        const regex = new RegExp('^[0-9]+$')

        // Получаем символ клавиши
        const key = String.fromCharCode(event.charCode)

        // Если символ не является цифрой, отменяем ввод
        if (!regex.test(key)) {
            event.preventDefault()
        }
    }

    const onChangeReturnStake = (event: any) => {
        // zero check
        const newValue =
            event.target.value == '' ? 0 : parseFloat(event.target.value)
        setReturnStake(newValue)
        // recalculate
        const calcTotalStake = newValue / (1 + surebet?.percentage / 100)
        setTotalStake(calcTotalStake)

        // recalculate ref
        totalStakeRef.current.value = calcTotalStake.toFixed(2)

        calculateStakes(newValue)
    }
    const onChangeTotalStake = (event: any) => {
        // zero check
        const newValue =
            event.target.value == '' ? 0 : parseFloat(event.target.value)
        setTotalStake(newValue)
        // recalculate
        const calcReturnStake =
            newValue + (newValue * surebet?.percentage) / 100

        // recalculate ref
        returnStakeRef.current.value = calcReturnStake.toFixed(2)

        setReturnStake(calcReturnStake)
        calculateStakes(calcReturnStake)
    }

    const onChangeStake = (event: any, index: number) => {
        const newValue =
            event.target.value == '' ? 0 : parseFloat(event.target.value)
        setStakes((state: any) => ({ ...state, [event.target.name]: newValue }))

        // recalculate
        const _returnStake = newValue * surebet?.surebet?.odds[index].value

        returnStakeRef.current.value = _returnStake.toFixed(2)

        setReturnStake(_returnStake)

        const _totalStake = _returnStake / (1 + surebet?.percentage / 100)

        totalStakeRef.current.value = _totalStake.toFixed(2)

        setTotalStake(_totalStake)

        // recalculate other stakes
        surebet?.surebet?.odds?.map((bet: any, i: number) => {
            if (index !== i) {
                setStakes((state: any) => ({
                    ...state,
                    [`stake-${i}`]: _returnStake / bet.value,
                }))
                // refs
                stakeRef.current[i].value = (_returnStake / bet.value).toFixed(
                    2
                )
            }
        })
    }

    const calculateStakes = (returnStake: number) => {
        surebet?.surebet?.odds?.map((bet: any, index: number) => {
            setStakes((state: any) => ({
                ...state,
                [`stake-${index}`]: returnStake / bet.value,
            }))
            stakeRef.current[index].value = (returnStake / bet.value).toFixed(2)
        })
    }

    // init not zero value
    useEffect(() => {
        const calcReturnStake = totalStake * (1 + surebet?.percentage / 100)
        returnStakeRef.current.value = calcReturnStake.toFixed(2)
        setReturnStake(calcReturnStake)
        calculateStakes(calcReturnStake)
    }, [])

    return (
        <div
            ref={block}
            className={`block block--match-day block--surebet ${
                expanded ? 'block--surebet-expanded' : ''
            } ${type != 'matchday' ? 'block--surebet-match' : ''}`}
        >
            {type == 'matchday' && (
                <nav aria-label="breadcrumb" className="surebet__header">
                    <Breadcrumbs items={surebet?.competition} />
                    <h5 className="match-day__title match-day__title--surebet">
                        {t('match.date_start', {
                            date: new Date(surebet.date_start),
                        })}
                    </h5>
                </nav>
            )}
            <div
                className={`match-day match-day--surebet ${
                    type == 'match' ? 'match-day--surebet-match' : ''
                }`}
            >
                {type == 'matchday' && (
                    <Link href={surebet?.url ? surebet?.url : '#'}>
                        <div className="match-day__content match-day__content--surebet flex flex-align">
                            <Image
                                src={`${staticPath}teams/80x80/${surebet?.team1?.logo}.png`}
                                alt={surebet?.team1?.title}
                                width={40}
                                height={40}
                            />
                            <div onClick={() => setExpanded(false)}>
                                <p className="match-day__subtitle">
                                    {surebet?.team1?.title} –{' '}
                                    {surebet?.team2?.title}
                                </p>
                            </div>
                            <Image
                                src={`${staticPath}teams/80x80/${surebet?.team2?.logo}.png`}
                                alt={surebet?.team2?.title}
                                width={40}
                                height={40}
                            />
                        </div>
                    </Link>
                )}

                <div className="surebet__title-wrapper">
                    <span
                        className="badge badge--surebet"
                        ref={badge}
                        style={{
                            position: 'absolute',
                            left: expanded ? '0' : '50%',
                            transform: expanded
                                ? 'translateX(0)'
                                : 'translateX(-50%)',
                        }}
                    >
                        {surebet?.title}
                    </span>
                    <div
                        className={`surebet__wrapper-bet surebet__wrapper-bet--stavka ${
                            expanded ? 'surebet__wrapper-bet--active' : ''
                        }`}
                    >
                        <span className="surebet__wrapper-bet-title">
                            {t('surebets.stake')}
                        </span>
                        <div className="surebet__input-wrapper surebet__input-wrapper--stavka">
                            <input
                                type="text"
                                className="surebet__input"
                                ref={totalStakeRef}
                                onKeyPress={handleKeyPress}
                                defaultValue={totalStake}
                                onChange={onChangeTotalStake}
                            />
                            <div className="surebet__input-wrapper-currency">
                                $
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className={`grid grid--8 surebet__grid ${
                        expanded ? 'surebet__grid--expanded' : ''
                    }`}
                >
                    {surebet?.surebet?.odds?.map((bet: any, index: number) => (
                        <BookmakerLinkRedirect
                            className="surebet__bet"
                            key={index}
                            bookmakerId={bet?.bookmaker.id}
                            bookmakerLogoId={bet?.bookmaker.logo_id}
                            bookmakerColor={bet?.bookmaker.color}
                            matchId={surebet?.match_id}
                            oddType={surebet?.surebet.odd_type}
                            oddSelect={surebet?.surebet?.odd_select}
                            period={surebet?.surebet?.period}
                            handicap={surebet?.surebet?.handicap}
                        >
                            <div className="surebet__main-block">
                                <div className="surebet__bet-header">
                                    {bet?.odd_select_display}
                                </div>
                                <div className="flex flex-center flex-align flex-column grid grid--8 surebet__bet-content">
                                    <div className="bet bet--surebet bet__title">
                                        {bet?.display}
                                    </div>
                                    <div
                                        className="bookmaker-badge"
                                        style={{
                                            backgroundColor: `#${bet?.bookmaker?.color}`,
                                        }}
                                    >
                                        <Logo
                                            type='bookmaker_original'
                                            logoId={bet?.bookmaker?.id}
                                            size={72}
                                            height={24}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="surebet__bet-footer">
                                <div className="surebet__input-wrapper">
                                    <input
                                        type="text"
                                        name={`stake-${index}`}
                                        ref={(el) =>
                                            (stakeRef.current[index] = el)
                                        }
                                        className="surebet__input"
                                        onChange={(event) =>
                                            onChangeStake(event, index)
                                        }
                                        onKeyPress={handleKeyPress}
                                        defaultValue={
                                            stakes[`stake-${index}`] &&
                                            stakes[`stake-${index}`].toFixed(2)
                                        }
                                    />
                                    <div className="surebet__input-wrapper-currency">
                                        $
                                    </div>
                                </div>
                            </div>
                        </BookmakerLinkRedirect>
                    ))}
                    <div className="surebet__expanded-result">
                        <div className="flex flex-align">
                            <div className="badge badge--surebet-top">
                                {surebet?.percentage}%
                            </div>
                            <span className="surebet__expanded-title">
                                {t('surebets.return')}
                            </span>
                        </div>
                        <div className="surebet__input-wrapper">
                            <input
                                type="text"
                                className="surebet__input"
                                onKeyPress={handleKeyPress}
                                defaultValue={returnStake.toFixed(2)}
                                ref={returnStakeRef}
                                onChange={onChangeReturnStake}
                            />
                            <div className="surebet__input-wrapper-currency">
                                $
                            </div>
                        </div>
                    </div>
                </div>
                <button
                    className={`button button--rounded button--upper button--primary-color button--forecast-bet button--surebet-win grid grid--4 ${
                        expanded ? 'button--surebet-win-expanded' : ''
                    }`}
                    onClick={() => setExpanded(!expanded)}
                >
                    <span>
                        {t('surebets.return')} {surebet?.percentage}%
                    </span>
                    <Image
                        src={arrow_black}
                        className="list__link-ico"
                        alt="ava"
                        width={12}
                    />
                </button>
            </div>
        </div>
    )
}
