import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import PreloadTopCountries from './PreloaderTopCountries'

type Props = {
    activeIndex?: number
    index?: number
    onClick?: Function
    preload?: Function
    content: any
    header?: any
    key: string
    countriesCompetitionLoading?: string
    data?: any
}

function AccordionSection(props: Props) {
    const [setHeight, setHeightState] = useState('0px')

    const [loader, setLoader] = useState(false)
    const [timer, setTimer] = useState(null)

    const content = useRef(null)

    // height effect
    useEffect(() => {
        setHeightState(
            props.activeIndex == props.index
                ? `${content.current.scrollHeight}px`
                : '0px'
        )
    }, [props.countriesCompetitionLoading, props.activeIndex])

    // timer clear
    useEffect(() => {
        if (props.activeIndex != props.index) {
            // clear timer
            if (timer) {
                clearTimeout(timer)
                setTimer(null)
            }
        }
    }, [props.activeIndex])

    function onClickSection() {
        // if another section click skip

        // close list
        if (props.activeIndex == props.index) {
            setHeightState('0px')
        } else {
            if (!props.data) {
                setLoader(true)
                setHeightState(`400px`)
                const timer = setTimeout(async () => {
                    await props.preload()
                    setLoader(false)
                    setHeightState(`${content.current?.scrollHeight}px`)
                }, 300)
                setTimer(timer)
            } else {
                setHeightState(`${content.current?.scrollHeight}px`)
            }
        }

        props.onClick(props.index)
    }

    return (
        <div
            key={props.key}
            className={`accordion__item ${
                props.activeIndex == props.index && 'accordion__item--active'
            }`}
            onClick={onClickSection}
        >
            {props.header}
            <div
                className="accordion__content"
                ref={content}
                style={{ maxHeight: `${setHeight}` }}
            >
                {loader && <PreloadTopCountries type="section" />}
                {props.content}
            </div>
        </div>
    )
}

export default AccordionSection
