import React from 'react'
import Image from 'next/image'
import star_white from '../../public/img/star-white.svg'
import star_active from '../../public/img/star-active.svg'
import close_matchday from '../../public/img/close-matchday.svg'
import full_screen from '../../public/img/full-screen.svg'
import minimize from '../../public/img/minimize.svg'
import withFavorite, { WithFavoriteProps } from '../../hoc/favorite';
import { useScreen } from '../../hooks'

type Props = {
    matchId: number,
    onChange: Function,
    full?: boolean,
    isLive?: boolean,
}

const MatchDayHeaderAction = withFavorite<Props>(({
    matchId,
    onChange,
    full,
    isLive,
    toggleFavorite,
    favorites,
}: Props & WithFavoriteProps) => {
    const {width} = useScreen()
    const isMobile = width <= 1150
    const controlClass = 'button button--matchday-menu button--matchday-menu-top'
    return (
        <div
            className='match-day-header__menu flex grid grid--4'
            style={{ width: 64 }}
        >
            {!isLive && (
                <button
                    className='button button--matchday-menu button--matchday-menu-top'
                    onClick={() => toggleFavorite('match', matchId)}
                >
                    <Image
                        src={favorites.match.indexOf(matchId) >= 0
                            ? star_active
                            : star_white
                        }
                        alt='Star match'
                        width={18}
                        height={18}
                    />
                </button>
            )}
            {isMobile ? (
                <button
                    className={controlClass}
                    onClick={() => onChange(false)}
                >
                    <Image
                        src={close_matchday}
                        alt='close match'
                        width={16}
                        height={16}
                    />
                </button>
            ) : (
                <>
                    {!full ? (
                        <button
                            className={controlClass}
                            onClick={() => onChange(true)}
                        >
                            <Image
                                src={full_screen}
                                alt='full screen'
                                width={16}
                                height={16}
                            />
                        </button>
                    ) : (
                        <button
                            className={controlClass}
                            onClick={() => onChange(false)}
                        >
                            <Image
                                src={minimize}
                                alt='minimize screen'
                                width={16}
                                height={16}
                            />
                        </button>
                    )}
                </>
            )}
        </div>
    )
})

export default MatchDayHeaderAction
