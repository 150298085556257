import * as RadixPopover from '@radix-ui/react-popover'
import { FU } from '../../../types'
import st from './Popover.module.scss'
import Image from 'next/image'
import close_modal from '../../../public/img/close-modal.svg'

type Props = {
    title: string,
    content: JSX.Element,
}

export const Popover: FU<Props> = ({
    children,
    title,
    content,
}) => {
    return (
        <RadixPopover.Root>
            <RadixPopover.Trigger asChild>
                {children}
            </RadixPopover.Trigger>
            <RadixPopover.Portal>
                <RadixPopover.Content
                    className={st.container}
                    sideOffset={4}
                    align='start'
                    onClick={(e) => {
                        // Keep all events inside Popover
                        e.stopPropagation()
                    }}
                >
                    <div className={st.header}>
                        {title}
                        <RadixPopover.Close
                            className={st.headerCloseButton}
                            aria-label="Close"
                        >
                            <Image
                                src={close_modal}
                                alt='X'
                                width={16}
                                height={16}
                                priority
                            />
                        </RadixPopover.Close>
                    </div>
                    {content}
                </RadixPopover.Content>
            </RadixPopover.Portal>
        </RadixPopover.Root>
    )
}