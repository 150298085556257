import React, { useRef, useState, useEffect, RefObject } from 'react'
import Image from 'next/image'
// @ts-ignore
import Slider from 'react-slick'
import arrow_right_white from '../public/img/arrow-right-white.svg'
import Forecast from './Forecast'
import { useTranslation } from 'next-i18next'
import { calculateSliderWidth } from '../utils'
import clsx from 'clsx';

type Props = {
    sidebar?: RefObject<HTMLInputElement>
    full: boolean
    forecasts: any
    typeMatch: string
    openedForecast?: boolean
}

export default React.forwardRef<HTMLDivElement, Props>(function ForecastBlock({
    sidebar,
    full,
    forecasts,
    typeMatch,
},
ref) {
    const block_el = useRef(null)
    const [widthSlider, setWidthSlider] = useState<number>(0)
    const slider_el = useRef(null)
    const { t } = useTranslation('common')

    const [openedForecast, setOpenedForecast] = useState(false)
    useEffect(() => {

    }, [])

    useEffect(() => {
        function resizeListener() {
            setWidthSlider(calculateSliderWidth(block_el))
        }
        resizeListener()

        window.addEventListener('resize', resizeListener)
        return () => {
            window.removeEventListener('resize', resizeListener)
        }
    }, [sidebar, full])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        dotsClass: 'slick-dots slick-thumb',
        appendDots: (dots: any) => (
            <div className='flex flex-align slider__footer'>
                <div className='flex flex-align slider__left-side'>
                    <div
                        className='flex flex-align slider__arrow slider__arrow--left'
                        onClick={() => slider_el.current.slickPrev()}
                    >
                        <Image
                            src={arrow_right_white}
                            alt='video'
                            width={14}
                            height={14}
                        />
                    </div>
                    <ul
                        style={{
                            display: 'flex',
                            marginLeft: 16,
                            marginRight: 16,
                        }}
                    >
                        {' '}
                        {dots}{' '}
                    </ul>
                    <div
                        className='flex flex-align slider__arrow slider__arrow--right'
                        onClick={() => slider_el.current.slickNext()}
                    >
                        <Image
                            src={arrow_right_white}
                            alt='video'
                            width={14}
                            height={14}
                        />
                    </div>
                </div>
                <div className='flex flex-align slider__right-side'>
                    <button className='button button--rounded button--rating grid grid--4'>
                        <span>{t('tips.all')}</span>
                        <Image
                            src={arrow_right_white}
                            alt='video'
                            width={14}
                            height={14}
                        />
                    </button>
                </div>
            </div>
        ),
        customPaging: () => <div className='slider__dots' />,
    }
    return (
        <div ref={ref}>
            {full && <ControllerScrollToBlock onOpenCallback={() => setOpenedForecast(true)} />}
            <div
                className='block rating-list forecast'
                ref={block_el}
                id={'forecast-block'}
            >
                <div className='rating-list__header rating-list__header--padding'>
                    <h5 className='rating-list__title'>
                        {typeMatch == 'match'
                            ? t('tips.title_one')
                            : t('tips.title_many')}
                    </h5>
                </div>
                <div
                    className={clsx('slider', forecasts.length == 1 && 'slider--one')}
                    style={{ width: widthSlider }}
                >
                    <Slider ref={slider_el} {...settings}>
                        {forecasts?.map((forecast: any) => (
                            <Forecast
                                full={full}
                                typeMatch={typeMatch}
                                openedForecast={openedForecast}
                                key={`forecast-match-${forecast.match_id}`}
                                forecast={forecast}
                            />
                        ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
})

function ControllerScrollToBlock({onOpenCallback}: {onOpenCallback: () => void}): null {
    const anchor = window.location.hash.substring(1)
    useEffect(() => {
        const anchorElement = document.getElementById(anchor)
        if (!anchor || !anchorElement) return

        onOpenCallback()
        anchorElement.scrollIntoView({ behavior: 'smooth' })
    }, [anchor])

    return null
}
