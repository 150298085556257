import React from 'react'

import { useTranslation } from 'next-i18next'
import Serie from './Serie'

type Props = {
    series: any
    match_id: string
    full: boolean
}

export default React.forwardRef<HTMLDivElement, Props>(function SeriesBlock(
    { series, match_id, full },
    ref,
) {
    const { t } = useTranslation('common')
    return (
        <div
            className="block rating-list surebet"
            ref={ref}
        >
            <div className="rating-list__header rating-list__header--padding">
                <h5 className="rating-list__title">
                    {t('match_series.title')}
                </h5>
            </div>
            <div className="rating-list__content">
                <div className="series">
                    {series?.data?.data?.series?.map(
                        (item: any, key: number) => (
                            <Serie
                                full={full}
                                key={`series-id-${key}`}
                                item={item}
                                match_id={match_id}
                            />
                        )
                    )}
                </div>
            </div>
        </div>
    )
})
