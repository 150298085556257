import React from 'react'
import {useGetCountryCompetitionsQuery} from '../../api/competitionsApi';
import PreloadTopCountries from '../PreloaderTopCountries';
import styles from './LeaguesInCountry.module.scss'
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import female_icon from '../../public/img/female.svg';
import {Logo} from '../logo';

type Props = {
    country: {
        id: number,
        title: string,
    },
    sportId: number,
    competitionCurrentId: number,
    locale: string,
}

export const LeaguesInCountry = ({country, sportId, competitionCurrentId, locale}: Props) => {
    const countryLeaguesQuery = useGetCountryCompetitionsQuery({
        locale,
        country: country.id,
        sport: sportId,
    })
    return (
        <div className='sidebar__block'>
            <h3 className="sidebar__title">
                ЛИГИ СТРАНЫ
            </h3>
            {countryLeaguesQuery.isFetching && <PreloadTopCountries type='section' />}
            {!countryLeaguesQuery.isFetching && (
                <div className='flex flex-column'>
                    <div className={clsx(styles.countryBlock, 'flex flex-align mb-4')}>
                        <Logo
                            className="list__ico"
                            type='country'
                            size={40}
                            dimension={20}
                            logoId={country.id}
                            alt={country.title}
                        />
                        {country.title}
                    </div>
                    {countryLeaguesQuery.data.data.competitions.map((item) => (
                        <Link
                            href={item.url}
                            key={item.id}
                            className={clsx(
                                styles.competition, 'flex flex-align',
                                item.id === competitionCurrentId && styles.active,
                                item.gender === 'female' && styles.withIcon,
                            )}
                        >
                            {item.gender === 'female' && (
                                <Image
                                    className="list__link--icon"
                                    src={female_icon}
                                    width={20}
                                    height={20}
                                    alt="female icon"
                                />
                            )}
                            {item.title}
                        </Link>
                    ))}
                </div>
            )}
        </div>
    )
}