import React, { useEffect, useRef, useState } from 'react'
import clsx from 'clsx';
import Image from 'next/image'
import arrow_next_comp from '../public/img/arrow_next_comp.svg'
import Link from 'next/link';

type Props = {
    competition: any
    expanded: boolean
}

const TRANSLATE_SPEED = 20
const TIME_TO_SHADOW = 200
const DELAY_SWITCH_DIRECTION = 800

export default function TitleMatch({ competition, expanded }: Props) {
    const [shadow, setShadow] = useState<'left' | 'both' | 'right' >('right')
    const [translateLeft, setTranslateLeft] = useState(0)

    const containerRef = useRef<HTMLDivElement>(null)
    const scrollableRef = useRef<HTMLDivElement>(null)

    const scrollTimeMsRef = useRef(0)
    const scrollTimeRef = useRef(0)
    const isScrollableRef = useRef(false)

    const timerTransitionRef = useRef<NodeJS.Timeout>(null)
    const timerShadowRef = useRef<NodeJS.Timeout>(null)

    const clearTimers = () => {
        if (timerTransitionRef.current) clearTimeout(timerTransitionRef.current)
        if (timerShadowRef.current) clearTimeout(timerShadowRef.current)
    }

    useEffect(() => {
        if (containerRef.current) {
            isScrollableRef.current = containerRef.current.scrollWidth > containerRef.current.clientWidth

            if (!isScrollableRef.current) return

            scrollTimeRef.current = ((containerRef.current.scrollWidth - containerRef.current.clientWidth) / TRANSLATE_SPEED)
            scrollTimeMsRef.current = scrollTimeRef.current * 1000
        }

        return () => {
            clearTimers()
        }
    }, [])

    // Set infinite scrolling for expanded title
    useEffect(() => {
        if (!isScrollableRef.current || !expanded) return

        clearTimers()

        setShadow('both')

        timerTransitionRef.current = setTimeout(
            () => setTranslateLeft((s) => s === 0
                ? (containerRef.current.scrollWidth - containerRef.current.clientWidth) * -1
                : 0
            ),
            scrollTimeMsRef.current + DELAY_SWITCH_DIRECTION
        )

        timerShadowRef.current = setTimeout(
            () => translateLeft !== 0
                ? setShadow('left')
                : setShadow('right'),
            scrollTimeMsRef.current - TIME_TO_SHADOW
        )
    }, [expanded, translateLeft])

    // Set initial translate on expand change
    useEffect(() => {
        if (!isScrollableRef.current) return

        clearTimers()

        if (expanded) {
            setTranslateLeft((containerRef.current.scrollWidth - containerRef.current.clientWidth) * -1)
            return
        }

        const dimsContainer = containerRef.current?.getBoundingClientRect()
        const dimsScrollable = scrollableRef.current?.getBoundingClientRect()
        const offsetScrollable = dimsContainer.x - dimsScrollable.x
        const time = (offsetScrollable / TRANSLATE_SPEED) * 1000

        setTranslateLeft(0)
        setShadow('both')

        timerShadowRef.current = setTimeout(
            () => setShadow('right'),
            time - TIME_TO_SHADOW
        )
    }, [expanded])

    return (
        <div
            className="table__header-title flex grid grid--2"
            ref={containerRef}
        >
            <div
                className='flex flex-align grid--2'
                ref={scrollableRef}
                style={isScrollableRef.current
                    ? {
                        transform: `translateX(${translateLeft}px)`,
                        transition: `transform ${scrollTimeRef.current}s linear`,
                    }
                    : undefined
                }
            >
                {competition?.competition.map((item: any, index: number) => {
                    if (item.type === 'country') {
                        return (
                            <React.Fragment key={item.id}>
                                <Link
                                    className='has-link-hover'
                                    href='#'
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {item.title}
                                </Link>
                                <Image
                                    src={arrow_next_comp}
                                    alt=''
                                    width={12}
                                    height={16}
                                />
                            </React.Fragment>
                        )
                    }

                    const hasImage = index < competition.competition.length - 1

                    if (item.type === 'competition') {
                        return (
                            <React.Fragment key={item.id}>
                                <Link
                                    className='has-link-hover'
                                    href={item.url}
                                    onClick={(e) => e.stopPropagation()}
                                >
                                    {item.title}
                                    {competition.stage && (
                                        <>
                                            <Image
                                                src={arrow_next_comp}
                                                alt=''
                                                width={12}
                                                height={16}
                                            />
                                            {competition.stage}
                                        </>
                                    )}
                                </Link>
                                {hasImage && (
                                    <Image
                                        src={arrow_next_comp}
                                        alt=''
                                        width={12}
                                        height={16}
                                    />
                                )}
                            </React.Fragment>
                        )
                    }

                    return (
                        <React.Fragment key={item.id}>
                            {item.title}
                            {hasImage && (
                                <Image
                                    src={arrow_next_comp}
                                    alt=''
                                    width={12}
                                    height={16}
                                />
                            )}
                        </React.Fragment>
                    )
                })}
            </div>
            {isScrollableRef.current && (
            <>
                <div
                    className={clsx(
                           'table__header-title__shadow table__header-title__shadow--left',
                           (shadow === 'left' || shadow === 'both') && 'table__header-title__shadow--active'
                       )}
                   />
                <div
                    className={clsx(
                           'table__header-title__shadow table__header-title__shadow--right',
                           (shadow === 'right' || shadow === 'both') && 'table__header-title__shadow--active'
                       )}
                   />
            </>
            )}
        </div>
    )
}
