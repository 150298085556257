import {forwardRef, useEffect, useState} from 'react'
import clsx from 'clsx';
import styles from './MatchStandings.module.scss'
import {MatchStandingsFetched} from '../../../api';
import { SwitchButtons } from '../../switch-buttons';
import { Fixtures, Playoff, Standings, Title } from './parts';
import { DISPLAY_VARIANT, DisplayVariant } from './types';
import { useCustomTranslation } from '../../../hooks/useCustomTranslation'
import {useContainerWidth} from '../../../hooks';

type Props = {
    data: MatchStandingsFetched | undefined,
}

export const MatchStandings = forwardRef<HTMLDivElement, Props>((
    { data },
    ref
) => {
    if (!data) return null

    const teams = (data.standings.groups || [])[0]?.table

    const areStandingsAvailable = teams && teams.length > 0
    const areFixturesAvailable = (data.fixtures?.matches || []).length > 0
    const isPlayoffAvailable = data.playoff && data.playoff.length > 0

    if (!areStandingsAvailable && !areFixturesAvailable && !isPlayoffAvailable) return null

    return (
        <div
            className={styles.sectionWrapper}
            ref={ref}
        >
            <MatchStandingsData
                data={data}
                areStandingsAvailable={areStandingsAvailable}
                areFixturesAvailable={areFixturesAvailable}
                isPlayoffAvailable={isPlayoffAvailable}
            />
        </div>
    )
})
MatchStandings.displayName = 'MatchStandings'

type MatchStandingsDataProps = {
    data: MatchStandingsFetched,
    areStandingsAvailable: boolean,
    areFixturesAvailable: boolean,
    isPlayoffAvailable: boolean,
}

function MatchStandingsData({
    data,
    areStandingsAvailable,
    areFixturesAvailable,
    isPlayoffAvailable,
}: MatchStandingsDataProps) {
    const [variant, setVariant] = useState<DisplayVariant>(DISPLAY_VARIANT.STANDINGS)

    const { t } = useCustomTranslation('common')

    const {
        containerRef,
        blockWidth,
    } = useContainerWidth()

    const displayOptions = [
        {
            id: DISPLAY_VARIANT.STANDINGS,
            label: t('match_table.table'),
            isVisible: areStandingsAvailable,
        },
        {
            id: DISPLAY_VARIANT.FIXTURES,
            label: t('match_table.matchday'),
            isVisible: areFixturesAvailable,
        },
        {
            id: DISPLAY_VARIANT.PLAYOFF,
            label: t('match_table.playoff'),
            isVisible: isPlayoffAvailable,
        },
    ].filter((i) => i.isVisible)

    // Show playoff first, if it's available
    const initialId = displayOptions.find((i) => i.id === DISPLAY_VARIANT.PLAYOFF)
        ? DISPLAY_VARIANT.PLAYOFF
        : displayOptions[0]?.id

    useEffect(() => {
        setVariant(initialId)
    }, [initialId])

    const competitionData = data.competition.find((i) => i.type === 'competition')

    return (
        <section
            className={styles.container}
            ref={containerRef}
        >
            <div
                className={clsx(
                    'sidebar-content-container flex flex-justify',
                    blockWidth < 450 && 'flex-column'
                )}
            >
                <Title titleParts={data.competition} />
                {displayOptions.length > 1 && (
                    <SwitchButtons
                        value={variant}
                        onChange={(value) => setVariant(value as DisplayVariant)}
                        options={displayOptions}
                    />
                )}
            </div>
            {variant === DISPLAY_VARIANT.STANDINGS && (
                <Standings
                    groups={data.standings.groups || []}
                    legends={data.standings.legend}
                    blockWidth={blockWidth}
                    competitionSeason={data.season}
                    competitionUrl={competitionData?.url}
                />
            )}
            {variant === DISPLAY_VARIANT.FIXTURES && (
                <Fixtures data={data.fixtures} />
            )}
            {variant === DISPLAY_VARIANT.PLAYOFF && (
                <Playoff
                    data={data.playoff}
                    blockWidth={blockWidth}
                />
            )}
        </section>
    )
}