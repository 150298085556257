import React from 'react'

type Props = {
    count: number
}

export default function PreloaderMatches({ count }: Props) {
    return (
        <div style={{ height: count * 60 }}>
            <div className="preloader preloader--center">
                {Array.from({
                    length: count,
                }).map((it, index) => (
                    <div
                        key={`preloader-center-matches-match-${index}`}
                        className="preloader__row"
                    >
                        <div className="preloader__left-group flex flex-align">
                            <div className="preloader__block preloader__block--time"></div>
                            <div className="preloader__group grid grid--4">
                                <div className="preloader__block preloader__block--team"></div>
                                <div className="preloader__block preloader__block--team"></div>
                            </div>
                            <div className="preloader__group preloader__group--fixed grid grid--10">
                                <div className="preloader__block preloader__block--title preloader__block--title-one"></div>
                                <div className="preloader__block preloader__block--title"></div>
                            </div>
                        </div>
                        <div className="preloader__right-group flex flex-align">
                            <div className="preloader__group">
                                <div className="preloader__block preloader__block--forecast"></div>
                            </div>
                            <div className="preloader__group grid grid--4">
                                <div className="preloader__block preloader__block--score"></div>
                                <div className="preloader__block preloader__block--score"></div>
                            </div>
                            <div className="preloader__block preloader__block--favorite preloader__block--favorite-first"></div>
                            <div className="preloader__block preloader__block--favorite"></div>

                            <div className="preloader__block preloader__block--bet"></div>
                            <div className="preloader__block preloader__block--bet"></div>
                            <div className="preloader__block preloader__block--bet"></div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
