import Image from 'next/image'
import { useTranslation } from 'next-i18next'
import NoSSR from './NoSsr'
import Link from 'next/link'

type Props = {
    related: any
}

export default function TwoMatchSerie({ related }: Props) {
    const { t } = useTranslation('common')
    const staticPath = 'https://i.oddsocean.com/img/logos/teams/60x60/'
    return (
        <div className='block rating-list surebet'>
            <>
                <div className='rating-list__header rating-list__header--padding'>
                    <h5 className='rating-list__title'>
                        {t('match_legs.title')}
                    </h5>
                </div>
                <Link
                    className='rating-list__content'
                    href={related.url}
                >
                    <div className='two-series'>
                        <div className='two-series__header'>
                            <p className='two-series__title'>
                                {related?.title}
                            </p>
                            {related?.status ? (
                                <p className='two-series__title'>
                                    {related.status}
                                </p>
                            ) : null}
                            <NoSSR>
                                <p className='two-series__title'>
                                    {t('match_legs.date', {
                                        date: new Date(related?.date_start),
                                    })}
                                </p>
                            </NoSSR>
                        </div>
                        <div className='two-series__content'>
                            <div className='two-series__col two-series__col--first'>
                                <p className='two-series__team-title'>
                                    {related?.team1?.title}
                                </p>
                                <Image
                                    src={`${staticPath}${related?.team1?.logo}.png`}
                                    alt={related?.team1?.title}
                                    width={26}
                                    height={26}
                                />
                            </div>
                            <div className='two-series__score'>
                                <span>
                                    {(related?.score && related?.score[0]) ||
                                    related?.score[0] == 0
                                        ? related?.score[0]
                                        : '–'}
                                </span>
                                <span>:</span>
                                <span>
                                    {(related?.score && related?.score[1]) ||
                                    related?.score[1] == 0
                                        ? related?.score[1]
                                        : '–'}
                                </span>
                            </div>
                            <div className='two-series__col'>
                                <Image
                                    src={`${staticPath}${related?.team2?.logo}.png`}
                                    alt={related?.team2?.title}
                                    width={26}
                                    height={26}
                                />
                                <p className='two-series__team-title'>
                                    {related?.team2?.title}
                                </p>
                            </div>
                        </div>
                    </div>
                </Link>
            </>
        </div>
    )
}
