import moment from 'moment/moment'
import { CompetitionStandingsMatch } from '../../../../api'
import st from './StandingsMatch.module.scss'
import { Logo } from '../../../../components/logo'
import { TeamName } from '../../../../components/team'
import Link from 'next/link'
import clsx from 'clsx'
import NoSsr from '../../../../components/NoSsr'
import { checkWinScore } from '../../../../utils'
import { PopoverOrDrawer } from '../../../../components/ui'
import { BestOffersPopup } from '../../../../components/BestOffersPopup'

type Props = {
    className: string,
    match: CompetitionStandingsMatch,
}

export const StandingsMatch = ({className, match}: Props) => {
    const today = moment(new Date())
    const dateStart = moment(match.date_start * 1000)

    function getColTitleTime(match: CompetitionStandingsMatch) {
        if (match.score.livetime) {
            return match.score.livetime[1] // Use only shorthand label
        }

        // check upcoming match
        return today < dateStart
            ? dateStart.format('DD.MM')
            : ''
    }
    const titleTime = getColTitleTime(match)

    const isLive = match.live
    const isFinished = !isLive && today > dateStart
    const matchScore = match.score.result

    //@todo Discuss selects
    const oddSelects = ['1', 'x', '2']

    return (
        <Link
            href={match.url}
            className={className}
        >
            <div className='flex grid--4 flex-align'>
                <div className={clsx(st.dateStart, isLive && 'opacity-1')}>
                    <span
                        className={clsx(
                            'show-desktop',
                            'text-with-overflow', 'txt-align-center',
                            isLive && 'table__text--red table__text--bold'
                        )}
                    >
                        <NoSsr>{titleTime}</NoSsr>
                    </span>
                    <span
                        className={clsx(
                            'show-mobile',
                            'text-with-overflow', 'txt-align-center',
                            match.live && 'table__text--red table__text--bold'
                        )}
                    >
                        <NoSsr>{titleTime}</NoSsr>
                    </span>
                </div>
                <div className='flex flex-column grid--4'>
                    <div className='grid-with-overflow-ltr grid--8 flex-align'>
                        <Logo
                            type='teams'
                            size={40}
                            dimension={22}
                            logoId={match.team1.logo}
                            withBorder
                        />
                        <TeamName
                            team={match.team1}
                            font='regular'
                        />
                    </div>
                    <div className='grid-with-overflow-ltr grid--8 flex-align'>
                        <Logo
                            type='teams'
                            size={40}
                            dimension={22}
                            logoId={match.team2.logo}
                            withBorder
                        />
                        <TeamName
                            team={match.team2}
                            font='regular'
                        />
                    </div>
                </div>
                <div className='flex flex-column grid--8 ml-auto'>
                    <span
                        className={clsx(
                            st.teamScore,
                            isLive && 'table__text--red',
                            checkWinScore(matchScore, 0) && st.isWinner,
                        )}
                    >
                        {matchScore ? matchScore[0] : '-'}
                    </span>
                    <span
                        className={clsx(
                            st.teamScore,
                            isLive && 'table__text--red',
                            checkWinScore(matchScore, 1) && st.isWinner,
                        )}
                    >
                        {matchScore ? matchScore[1] : '-'}
                    </span>
                </div>
            </div>
            <div
                className='flex flex-center flex-align grid--4'
                onClick={(e) => {
                    e.preventDefault()
                }}
            >
                {!match.odds && (
                    <span>
                        Нет данных о ставках
                    </span>
                )}
                {match.odds && match.odds.map((odd, index) => (
                    <PopoverOrDrawer
                        key={index}
                        title='лучшие предложения'
                        content={(
                            <BestOffersPopup
                                matchId={match.id}
                                oddType='3w'
                                oddSelect={oddSelects[index]}
                                period={null}
                                handicap={null}
                            />
                        )}
                    >
                        <button
                            className={clsx(
                                st.coeffBadge,
                                isFinished && st.isMatchFinished,
                                odd.won && st.isWinner,
                            )}
                            disabled={isFinished}
                        >
                            {odd.display}
                        </button>
                    </PopoverOrDrawer>
                ))}
            </div>
        </Link>
    )
}