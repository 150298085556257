import { useScreen } from '../../../hooks'
import { FU } from '../../../types'
import { Popover } from '../popover'
import { Drawer } from '../drawer'

type Props = {
    title: string,
    content: JSX.Element,
    contentPopover?: JSX.Element,
    contentDrawer?: JSX.Element,
}

export const PopoverOrDrawer: FU<Props> = ({
    children,
    title,
    content,
    contentPopover,
    contentDrawer,
}) => {
    const { width } = useScreen()

    return width > 500
        ? (
            <Popover
                title={title}
                content={contentPopover ?? content}
            >
                {children}
            </Popover>
        )
        : (
            <Drawer
                title={title}
                content={contentDrawer ?? content}
            >
                {children}
            </Drawer>
        )
}