import CountriesBlock from '../../../components/CountriesBlock'
import Sidebar from '../../../components/Sidebar'
import { useAppSelector } from '../../../hooks'
import { MatchDetailBlocks } from '../../../components/match-details'
import clsx from 'clsx'
import { RatingByCountries } from '../containers'
import { useSelector } from 'react-redux'
import { getMatchIdSelected } from '../../../features/matches'

export const PageFifaRanking = () => {
    const isSidebarFullSize = useAppSelector((state) => state.app.full)
    const matchIdSelected = useSelector(getMatchIdSelected)
    return (
        <div className='main-wrapper wrapper-with-sidebar-left'>
            <Sidebar className='mt-0' side='left'>
                <CountriesBlock />
            </Sidebar>
            <div className={clsx('layout-main-with-sidebar mt-0', isSidebarFullSize && 'layout-main-with-sidebar--full')}>
                <div
                    className={clsx(
                        'content__main content__main__keep-padding',
                        isSidebarFullSize && 'content__main--hidden'
                    )}
                >
                    <RatingByCountries />
                </div>
                <MatchDetailBlocks
                    key={matchIdSelected}
                    matchId={matchIdSelected}
                    isBlockFullSize={isSidebarFullSize}
                />
            </div>
        </div>
    )
}