import React from 'react'
import styles from './Title.module.css';
import {MatchTitlePart} from '../../../../api';

type Props = {
    titleParts: MatchTitlePart[]
}

export const Title = ({titleParts}: Props) => (
    <h5 className={styles.title}>
        {titleParts.map((part, index) => (
            index < titleParts.length - 1
                ? (
                    <React.Fragment key={part.id}>
                        <a
                            href={part.url}
                            target='_blank'
                            rel='noreferrer'
                        >
                            <span>
                                {part.title}.
                            </span>
                        </a>
                        {' '}
                    </React.Fragment>
                )
                : (
                    <a
                        key={part.id}
                        href={part.url}
                        target='_blank'
                        rel='noreferrer'
                    >
                        {part.title}
                    </a>
                )
        ))}
    </h5>
)