import React from 'react'
import Image from 'next/image'
import football_substitution_team_1 from '../../public/img/incindent_ico_color/football_substitution_team_1.svg'
import football_substitution_team_2 from '../../public/img/incindent_ico_color/football_substitution_team_2.svg'

interface IProps {
    incindent: any
    index: number
    indexIncindent: number
}

const MatchIncidents = ({ incindent, indexIncindent, index }: IProps) => {
    function getIcoSubstitution(value: number) {
        switch (value) {
            case 0:
                return football_substitution_team_1
            case 1:
                return football_substitution_team_2
        }
    }

    return (
        <div className="match-events__tooltip-content grid grid--4">
            {incindent.full.map((row: string, indexRow: number) => (
                <div
                    key={`incindents-period-${index}-incindent-${indexIncindent}-substitute-${indexRow}`}
                    className="match-events__tooltip-row grid grid--8"
                >
                    {incindent.type == 'football_substitution' && (
                        <Image
                            src={getIcoSubstitution(indexRow)}
                            alt="ico"
                            width={16}
                            height={16}
                            priority
                        />
                    )}
                    <span>{row}</span>
                </div>
            ))}
        </div>
    )
}

export default MatchIncidents
