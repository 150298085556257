import React from 'react'
import st from './PageBettingContest.module.scss'
import clsx from 'clsx';
import arrow_first from '../../public/img/arrow_black.svg'
import Image from 'next/image';
import Link from 'next/link';
import { APP_ROUTES } from '../../const'

export const PageBettingContest = () => {
    return (
        <div className={st.container}>
            <h1 className={st.title}>
                Betting Contest
            </h1>
            <div className={clsx(st.description, 'flex flex-column flex-align grid--8')}>
                Page is still under development!
            </div>
            <Link href={APP_ROUTES.MAIN}>
                <button className={clsx(st.button, 'button button--primary-color grid--4')}>
                    <Image
                        src={arrow_first}
                        className={st.buttonIcon}
                        width={12}
                        height={12}
                        alt=''
                    />
                    go back to main page
                </button>
            </Link>
        </div>
    )
}