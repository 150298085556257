import React from 'react'

export const LoaderTemp = () => (
    <div className='preloader'>
        <div className="preloader__block-sidebar-wrapper">
            <div className="preloader__block-sidebar-header">
                <div className="preloader__block-sidebar preloader__block-sidebar--title"></div>
                <div className="preloader__block-sidebar preloader__block-sidebar--subtitle"></div>
            </div>
            <div className="preloader__block-sidebar-content grid grid--4">
                <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
                <div className="preloader__block-sidebar preloader__block-sidebar--row"></div>
            </div>
            <div className="flex preloader__wrapper-button">
                <div className="preloader__block-sidebar preloader__block-sidebar--button"></div>
            </div>
        </div>
    </div>
)