import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
    getBothTeamsMatchResults,
    getFirstTeamMatchResults,
    getSecondTeamMatchResults,
    resultsTeamDataSelector,
    clearDataInStore,
} from '../../features/results-team'
import { MatchItem } from '../MatchItem'
import { useAppDispatch } from '../../hooks'
import { ResultsTeamTypes } from '../../types'
import Image from 'next/image'
import { useCustomTranslation } from '../../hooks/useCustomTranslation'
import {TeamInMatch} from '../../api';
import { Button } from '../Button'
import clsx from 'clsx'

type Props = {
    team1: TeamInMatch
    team2: TeamInMatch
}

export default React.forwardRef<HTMLDivElement, Props>(function LastMatches({
    team1,
    team2,
}, ref) {
    const logoPath: string = 'https://i.oddsocean.com/img/logos/teams/36x36/'

    const dispatch = useAppDispatch()
    const lastMatches = useSelector(resultsTeamDataSelector)

    const [activeType, setActiveType] = useState<string>(ResultsTeamTypes.ALL)
    const [activeCommandId, setActiveCommandId] = useState<number>()
    const [inited, setInited] = useState<boolean>(false)

    const inner = useRef(null)

    const { t } = useCustomTranslation('common')

    const isBigScreen = useMemo(() => {
        return inner?.current?.clientWidth >= 742
    }, [inner?.current?.clientWidth])

    const applyFilter = async (
        type: string,
        commandId?: number
    ): Promise<void> => {
        const currentId = commandId ? commandId : activeCommandId
        setActiveType(type)
        if (type === ResultsTeamTypes.H2H) {
            dispatch(
                getBothTeamsMatchResults({
                    team1Id: team1.id,
                    team2Id: team2.id,
                    type,
                })
            )
        } else {
            if (isBigScreen) {
                dispatch(
                    getFirstTeamMatchResults({
                        team1Id: team1.id,
                        type,
                    })
                )
                dispatch(
                    getSecondTeamMatchResults({
                        team2Id: team2.id,
                        type,
                    })
                )
            } else {
                const getMatchResults =
                    currentId === team1.id
                        ? getFirstTeamMatchResults
                        : getSecondTeamMatchResults
                const args =
                    currentId === team1.id
                        ? {
                            team1Id: currentId,
                            type: type || 'all',
                        }
                        : {
                            team2Id: currentId,
                            type: type || 'all',
                        }
                dispatch(getMatchResults(args))
            }
        }
    }

    const types: { option: ResultsTeamTypes; text: string }[] = [
        {
            option: ResultsTeamTypes.ALL,
            text: t('match_matches.all'),
        },
        {
            option: ResultsTeamTypes.HOME,
            text: t('match_matches.home'),
        },
        {
            option: ResultsTeamTypes.AWAY,
            text: t('match_matches.away'),
        },
        {
            option: ResultsTeamTypes.H2H,
            text: t('match_matches.h2h'),
        },
    ]

    const teams: TeamInMatch[] = [team1, team2]

    const changeCommand = (id: number): void => {
        setActiveCommandId(id)
        if (activeType !== ResultsTeamTypes.H2H) {
            applyFilter(activeType, id)
        }
    }

    const renderMatches = (matches: any[]) => (matches ?? []).map(
        (match) => <MatchItem match={match} key={match.match_id} />
    )

    useEffect(() => {
        if (!inited) {
            clearDataInStore()
            setActiveCommandId(team1.id)
            dispatch(
                getFirstTeamMatchResults({ team1Id: team1.id, type: 'all' })
            )
            dispatch(
                getSecondTeamMatchResults({ team2Id: team2.id, type: 'all' })
            )
            setInited(true)
        }
    }, [])

    const renderResult = useMemo(() => {
        if (activeType === ResultsTeamTypes.H2H) {
            return renderMatches(lastMatches.bothTeamsResults)
        }
        if (activeCommandId === team1.id) {
            return renderMatches(lastMatches.firstTeamResults)
        }
        if (activeCommandId === team2.id) {
            return renderMatches(lastMatches.secondTeamResults)
        }

        return null
    }, [lastMatches, activeType, activeCommandId])

    return (
        <div ref={ref}>
            <div
                ref={inner}
                className={clsx('last-matches', !isBigScreen && 'last-matches--small')}
            >
                <div className={clsx('flex flex-justify flex-align grid--4', !isBigScreen && 'mb-8')}>
                    <h3 className='last-matches__title'>
                        {t('match_matches.title')}
                    </h3>
                    {isBigScreen && (
                        <div className="flex grid--8">
                            {types.map((type) => (
                                <Button
                                    key={type.option}
                                    mode="dark"
                                    isActive={type.option === activeType}
                                    onClick={() => applyFilter(type.option)}
                                >
                                    {type.text}
                                </Button>
                            ))}
                        </div>
                    )}
                </div>
                <div
                    className={clsx(
                        'last-matches__commands',
                        activeType === ResultsTeamTypes.H2H && 'last-matches__commands--all',
                    )}
                >
                    {teams.map((team, index) => team && (
                        <Button
                            key={index}
                            mode="dark"
                            isActive={ResultsTeamTypes.H2H === activeType || team.id === activeCommandId}
                            onClick={() => changeCommand(team.id)}
                        >
                            <Image
                                height={26}
                                width={26}
                                src={`${logoPath}${team.logo}.png`}
                                alt={team.title}
                            />
                            {team.title}
                        </Button>
                    ))}
                </div>
                {!isBigScreen && (
                    <div className='flex flex-wrap grid--8'>
                        {types.map((type) => (
                            <Button
                                key={type.option}
                                mode='dark'
                                isActive={type.option === activeType}
                                onClick={() => applyFilter(type.option)}
                            >
                                {type.text}
                            </Button>
                        ))}
                    </div>
                )}
                <div className='last-matches__inner'>
                    {lastMatches.status === 'loading' && (
                        <div className='last-matches__skeleton preloader--matchday'>
                            <div className='last-matches__skeleton-bar'/>
                            <div className='last-matches__skeleton-bar'/>
                            <div className='last-matches__skeleton-bar'/>
                            <div className='last-matches__skeleton-bar'/>
                            <div className='last-matches__skeleton-bar'/>
                        </div>
                    )}
                    {lastMatches.status === 'succeeded' && (
                        <>
                            <div className='last-matches__wrapper'>
                                {renderResult}
                            </div>
                            {activeType !== ResultsTeamTypes.H2H && isBigScreen && (
                                <div className='last-matches__wrapper last-matches__wrapper--second'>
                                    {renderMatches(lastMatches.secondTeamResults)}
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
        </div>
    )
})
