import React from 'react'

type Props = {
    count?: number,
}

export default function PreloadBestOffers({ count }: Props) {
    return (
        <div className={`preloader grid grid--4`}>
            {Array.from({
                length: count,
            }).map((item: any, index: number) => <div key={`preload-item-bo-${index}`} className='preloader__item preloader__item--medium'></div>)}
        </div>
    )
}
