import React, { useRef, useState, useEffect, RefObject } from 'react'
import Image from 'next/image'
import { useTranslation } from 'next-i18next'
// @ts-ignore
import Slider from 'react-slick'
import arrow_right_white from '../public/img/arrow-right-white.svg'
import Surebet from './Surebet'
import { calculateSliderWidth } from '../utils'
type Props = {
    sidebar?: RefObject<HTMLInputElement>
    surebets: []
    full?: boolean
    typeMatch?: 'match' | 'matchday'
}

export default React.forwardRef<HTMLDivElement, Props>(function SurebetBlock({
    sidebar,
    surebets,
    full,
    typeMatch,
},
ref) {
    const slider_el = useRef(null)

    const block_el = useRef(null)

    const [widthSlider, setWidthSlider] = useState<number>(0)

    const { t } = useTranslation('common')

    const resizeSlider = () => {
        setWidthSlider(calculateSliderWidth(block_el))
    }

    useEffect(() => {
        resizeSlider()
        const resizeListener = () => {
            // change width from the state object
            resizeSlider()
        }

        window.addEventListener('resize', resizeListener)

        // clean up function
        return () => {
            // remove resize listener
            window.removeEventListener('resize', resizeListener)
        }
    }, [sidebar, full])

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        centerPadding: 50,
        slidesToScroll: 1,
        swipe: false,
        arrows: false,
        dotsClass: 'slick-dots slick-thumb',
        appendDots: (dots: any) => (
            <div className="flex flex-align slider__footer">
                <div className="flex flex-align slider__left-side">
                    <div
                        className="flex flex-align slider__arrow slider__arrow--left"
                        onClick={() => slider_el.current.slickPrev()}
                    >
                        <Image
                            src={arrow_right_white}
                            alt="video"
                            width={14}
                            height={14}
                        />
                    </div>
                    <ul
                        style={{
                            display: 'flex',
                            marginLeft: 16,
                            marginRight: 16,
                        }}
                    >
                        {' '}
                        {dots}{' '}
                    </ul>
                    <div
                        className="flex flex-align slider__arrow slider__arrow--right"
                        onClick={() => slider_el.current.slickNext()}
                    >
                        <Image
                            src={arrow_right_white}
                            alt="video"
                            width={14}
                            height={14}
                        />
                    </div>
                </div>
                <div className="flex flex-align slider__right-side">
                    <button className="button button--rounded button--rating grid grid--4">
                        <span>{t('surebets.all')}</span>
                        <Image
                            src={arrow_right_white}
                            alt="video"
                            width={14}
                            height={14}
                        />
                    </button>
                </div>
            </div>
        ),
        customPaging: () => <div className="slider__dots"></div>,
    }
    return (
        <div ref={ref}>
            <div className="block rating-list surebet" ref={block_el}>
                <div className="rating-list__header rating-list__header--padding">
                    <h5 className="rating-list__title">{t('surebets.title')}</h5>
                </div>
                <div className="slider" style={{ width: widthSlider }}>
                    <Slider ref={slider_el} {...settings}>
                        {surebets?.map((surebet: any) => (
                            <Surebet
                                type={typeMatch}
                                surebet={surebet}
                                key={`surebet-match-${surebet.match_id}`}
                           />
                       ))}
                    </Slider>
                </div>
            </div>
        </div>
    )
})
