// lib
import Image from 'next/image'
import React from 'react'
// components

// recources
import arrow_black from '../public/img/arrow_black.svg'
import star from '../public/img/star.svg'
import star_active from '../public/img/star-active.svg'
import { useEffect, useRef, useState } from 'react'
import TitleMatch from './TitleMatch'
import MatchRow from './MatchRow'
import PreloaderMatches from './PreloaderMatches'
import withFavorite from '../hoc/favorite';
import clsx from 'clsx';

function MatchesBlock({
    onClickMatch,
    index,
    odds_selects,
    competition,
    favorites,
    toggleFavorite,
    onClickCompetition,
    oddType,
}: any) {
    const [expanded, setExpanded] = useState<boolean>(true)
    const [hidden, setHidden] = useState<boolean>(false)
    const [animated, setAnimated] = useState<boolean>(false)

    // check expanded first load
    const timer: any = null
    const el = useRef(null)

    useEffect(() => {
        if (competition.matches.length == 0) setExpanded(false)
        // else {
        //     // setExpanded(true)
        // }
    }, [competition.matches])

    useEffect(() => {
        el.current.addEventListener(
            'transitionend',
            function (e: any) {
                if (
                    expanded &&
                    e.propertyName == 'max-height' &&
                    e.target.offsetHeight > 0
                ) {
                    setHidden(false)
                }
            },
            false
        )
    })

    useEffect(() => {
        clearTimeout(timer)
        if (!expanded) {
            setHidden(true)
        }
    }, [expanded])

    function _onClickFavorite(e: any, type: string, id: string) {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        toggleFavorite(type, id as string)
    }

    async function toggleCompetition() {
        setAnimated(true)
        setExpanded(expanded ? false : true)
        if (competition.matches.length == 0) {
            onClickCompetition(competition.id)
        }
    }

    const isFavorite = favorites.competition.indexOf(competition.id) >= 0
    return (
        <div
            className={`content-block ${animated ? '' : 'no-animate'} ${index === 0 ? 'content-block--first' : ''
            } ${index >= 7 ? 'content-block--visibility-auto' : ''}`}
        >
            <div className="table table--match">
                <div
                    onClick={toggleCompetition}
                    className={`table__header ${expanded ? 'table__header--expanded' : ''
                    } ${competition.matches.length > 0
                        ? 'table__header--opened'
                        : ''
                    } table__header--match flex flex-align`}
                >
                    <div
                        className="flex flex-align grid grid--8"
                        style={{ overflow: 'hidden', width: '100%' }}
                    >
                        <button
                            className={clsx('button button--view', isFavorite && 'button--active-favorite')}
                            onClick={(e) =>
                                _onClickFavorite(
                                    e,
                                    'competition',
                                    competition.id,
                                )
                            }
                        >
                            <Image
                                src={isFavorite ? star_active : star}
                                className="button__ico"
                                alt="Star"
                                loading="eager"
                                width={16}
                                height={16}
                            />
                        </button>
                        <Image
                            src={`https://i.oddsocean.com/img/ocean/40x40/${competition.logo}.png`}
                            alt="Logo"
                            width={20}
                            height={20}
                        />
                        <div className='mask-container'>
                            <div className="mask">
                                <TitleMatch
                                    expanded={expanded}
                                    competition={competition}
                                    key={`title-match-${competition.id}-${index}`}
                                />
                            </div>
                        </div>
                    </div>
                    {competition.matches.length > 0 && (
                        <div className="flex flex-align grid grid--4 matches-bets">
                            {odds_selects.map((select: any, _index: number) => (
                                <div
                                    key={`competition-${competition.id}-odd-${_index}`}
                                    className="table__header-col table__header-col--bet"
                                >
                                    {select.odd_select_display}
                                </div>
                            ))}
                        </div>
                    )}

                    <Image
                        src={arrow_black}
                        className="list__link-ico list__link-ico--competitions"
                        alt="ava"
                        width={12}
                    />
                </div>
                {index == 0 && (
                    <div
                        className={`subheader subheader--mobile ${expanded ? 'subheader--expanded' : ''
                        }`}
                    >
                        <div className="flex flex-align grid grid--4 subheader__container">
                            {odds_selects.map((select: any, _index: number) => (
                                <div
                                    key={`competition-${competition.id}-odd-${_index}`}
                                    className="table__header-col table__header-col--bet"
                                >
                                    {select.odd_select_display}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                <div
                    ref={el}
                    className={`table__content ${hidden ? 'table__content--hidden' : ''
                    }`}
                    style={{
                        maxHeight: expanded
                            ? competition.matches_count * 60
                            : 0,
                    }}
                >
                    {competition.matches.map((match: any, index: number) => (
                        <MatchRow
                            competition={competition}
                            key={`comp-index-${index}`}
                            match={match}
                            index={index}
                            onClickMatch={onClickMatch}
                            _onClickFavorite={_onClickFavorite}
                            odds_selects={odds_selects}
                            favorites={favorites}
                            oddType={oddType}
                        />
                    ))}

                    {competition.matches.length == 0 ? (
                        <PreloaderMatches count={competition.matches_count} />
                    ) : null}
                </div>
            </div>
        </div>
    )
}

const MatchesBlockWithFavorite = withFavorite<any>(MatchesBlock)
export default MatchesBlockWithFavorite