import React from 'react'
import clsx from 'clsx';
import Link from 'next/link';
import Image from 'next/image';
import { MatchPlayoffPair, PlayoffTeamIcon, TeamInMatch } from '../../../../../api';
import { Logo } from '../../../../logo';
import {Tooltip} from '../../../../Tooltip';
import { getTeamIcon, getTeamIconDescription } from './helpers';
import styles from './PlayoffDesktop.module.scss';

type Props = {
    pair: MatchPlayoffPair,
}

export const PlayoffPairCard = ({ pair }: Props) => {
    const matchFirst = pair.matches && pair.matches[0]
    const matchSecond = pair.matches && pair.matches[1]
    return (
        <div className={clsx(styles.card, pair.live && styles.live)}>
            <div className="flex flex-column flex-grow grid--8">
                <Team
                    team={pair.team1}
                    icons={pair.icons[0]}
                    isWinner={pair.next_round === 1 || pair.next_round === null}
                />
                <Team
                    team={pair.team2}
                    icons={pair.icons[1]}
                    isWinner={pair.next_round === 2 || pair.next_round === null}
                />
            </div>
            {/*Display thumb, if no matches found*/}
            {!matchFirst && (
                <div className={styles.scoreBox}>
                    <span className={styles.score}>
                        -
                    </span>
                    <span className={styles.score}>
                        -
                    </span>
                </div>
            )}
            {matchFirst && (
                <Link
                    className={clsx(styles.scoreBox, styles.hover, pair.live === 1 && styles.live)}
                    href={matchFirst.url}
                >
                    <span className={styles.score}>
                        {matchFirst.score[0] ?? '‒'}
                    </span>
                    <span className={styles.score}>
                        {matchFirst.score[1] ?? '‒'}
                    </span>
                </Link>
            )}
            {matchSecond && (
                <Link
                    className={clsx(styles.scoreBox, styles.hover, pair.live === 2 && styles.live)}
                    href={matchSecond.url}
                >
                    <span className={styles.score}>
                        {matchSecond.score[0] ?? '‒'}
                    </span>
                    <span className={styles.score}>
                        {matchSecond.score[1] ?? '‒'}
                    </span>
                </Link>
            )}
            <div className={clsx(styles.connector, styles.connectorIn)}/>
            <div className={clsx(styles.connector, styles.connectorOut)}/>
        </div>
    )
}

type TeamProps = {
    team: TeamInMatch | null,
    icons: PlayoffTeamIcon[] | undefined,
    isWinner: boolean,
}

function Team({team, icons, isWinner}: TeamProps) {
    return (
        <div className="flex flex-align grid--8">
            {team
                ? (
                    <>
                        <Logo
                            type="teams"
                            size={36}
                            logoId={team.logo}
                            dimension={26}
                            withBorder
                        />
                        <div className={styles.teamTitleContainer}>
                            <span className={clsx(styles.teamTitle, isWinner && styles.isWinner)}>
                                {team.title}
                            </span>
                        </div>
                    </>
                )
                : <TeamThumbnail />
            }
            {icons.map((icon) => (
                <Tooltip
                    content={getTeamIconDescription(icon)}
                    key={icon}
                >
                    <div>
                        <Image
                            src={getTeamIcon(icon)}
                            alt=''
                            width={16}
                            height={16}
                        />
                    </div>
                </Tooltip>
            ))}
        </div>
    )
}

function TeamThumbnail() {
    return (
        <>
            <div className={styles.teamLogoThumb} />
            <div className={styles.teamTitleThumb} />
        </>
    )
}