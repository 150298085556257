import React, { useState } from 'react'
import Image from 'next/image'
import minus_ico from '../public/img/minus-ico.svg'
import plus_ico from '../public/img/plus-ico.svg'
type Props = {
    team1: string
    team2: string
    onChange?: Function
}

export default function ScoreSwitcher({ team1, team2, onChange }: Props) {
    const [score1, setScore1] = useState(0)
    const [score2, setScore2] = useState(0)

    function onClickScoreMinus(index: number) {
        const scoreCopy = index == 0 ? score1 : score2
        if (scoreCopy == 0) return

        index == 0 ? setScore1(scoreCopy - 1) : setScore2(scoreCopy - 1)

        onChange(`${score1}-${score2}`)
    }

    function onClickScorePlus(index: number) {
        const scoreCopy = index == 0 ? score1 : score2

        index == 0 ? setScore1(scoreCopy + 1) : setScore2(scoreCopy + 1)

        onChange(`${score1}-${score2}`)
    }

    return (
        <div className="score-switcher">
            <div className="score-switcher__side">
                <h5 className="score-switcher__title">{team1}</h5>
                <div className="score-switcher__wrapper grid grid--8">
                    <button
                        className="button button--score-switcher"
                        onClick={() => onClickScoreMinus(0)}
                    >
                        <Image
                            src={minus_ico}
                            alt="minus"
                            width={16}
                            height={16}
                        />
                    </button>
                    <div className="score-switcher__score">{score1}</div>
                    <button
                        className="button button--score-switcher"
                        onClick={() => onClickScorePlus(0)}
                    >
                        <Image
                            src={plus_ico}
                            alt="plus"
                            width={16}
                            height={16}
                        />
                    </button>
                </div>
            </div>
            <div className="score-switcher__side">
                <h5 className="score-switcher__title">{team2}</h5>
                <div className="score-switcher__wrapper grid grid--8">
                    <button
                        className="button button--score-switcher"
                        onClick={() => onClickScoreMinus(1)}
                    >
                        <Image
                            src={minus_ico}
                            alt="minus"
                            width={16}
                            height={16}
                        />
                    </button>
                    <div className="score-switcher__score">{score2}</div>
                    <button
                        className="button button--score-switcher"
                        onClick={() => onClickScorePlus(1)}
                    >
                        <Image
                            src={plus_ico}
                            alt="plus"
                            width={16}
                            height={16}
                        />
                    </button>
                </div>
            </div>
        </div>
    )
}
