import ico_playoff_pair_winner from '../../../../../public/img/match/playoff-pair-winner.svg';
import ico_playoff_pair_penalty from '../../../../../public/img/match/playoff-pair-penalty.svg';
import ico_playoff_pair_addtime from '../../../../../public/img/match/playoff-pair-addtime.svg';
import {PlayoffTeamIcon} from '../../../../../api';

export function getTeamIcon(icon: PlayoffTeamIcon): string {
    if (icon === 'winner') return ico_playoff_pair_winner
    if (icon === 'penalty') return ico_playoff_pair_penalty
    return ico_playoff_pair_addtime
}

export function getTeamIconDescription(icon: PlayoffTeamIcon): string {
    if (icon === 'winner') return 'Победитель'
    if (icon === 'penalty') return 'Пенальти'
    return 'Дополнительное время'
}