import React, { useMemo } from 'react'
import { useCustomTranslation } from '../hooks/useCustomTranslation'
import { ICommandsMatch } from '../types'
import Link from 'next/link'
import {Logo} from './logo';
import next_period from '../public/img/period.svg';
import Image from 'next/image';

export function MatchItem({ match }: { match: ICommandsMatch }) {
    const { t } = useCustomTranslation('common')

    const competition =
        match?.competition?.find((item) => item.type === 'competition')?.title ||
        null

    const resultScore = useMemo(() => {
        if (match?.score?.result) {
            return `${match?.score?.result[0]} : ${match?.score?.result[1]}`
        }
        return '- : -'
    }, [match])

    return (
        <Link className='match-item' href={match.url || '#'}>
            <div className='match-item__top'>
                <span className='match-item__competition'>{competition}</span>
                <span className='match-item__center'>
                    {match.score.livetime[0]}
                </span>
                <span className='match-item__center match-item__center--mobile'>
                    {match.score.livetime[1]}
                </span>
                <span>
                    {t('match.match_date', {
                        date: new Date(match?.date_start),
                    })}
                </span>
            </div>
            <div className='match-item__inner'>
                <div className='match-item__team'>
                    <div className='grid-with-overflow-rtl grid--4 flex-align'>
                        <span className='match-item__name'>
                            {match?.team1?.title}
                        </span>
                        {match.next_round === 1 && (
                            <Image
                                src={next_period}
                                width={16}
                                height={16}
                                alt='ven'
                            />
                        )}
                    </div>
                    <Logo
                        type='teams'
                        size={36}
                        logoId={match?.team1?.logo}
                        dimension={26}
                        loading='lazy'
                        withBorder
                    />
                </div>
                <div className={`match-item__score match-item__score--${match.color}`}>
                    {resultScore}
                </div>
                <div className='match-item__team'>
                    <Logo
                        type='teams'
                        size={36}
                        logoId={match?.team2?.logo}
                        dimension={26}
                        loading='lazy'
                        withBorder
                    />
                    <div className='grid-with-overflow-ltr grid--4 flex-align'>
                        {match.next_round === 2 && (
                            <Image
                                src={next_period}
                                width={16}
                                height={16}
                                alt='ven'
                            />
                        )}
                        <span className='match-item__name'>
                            {match?.team2?.title}
                        </span>
                    </div>
                </div>
            </div>
        </Link>
    )
}
