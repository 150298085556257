import React, { useEffect, useRef, useState, forwardRef } from 'react'
import clsx from 'clsx';

export default forwardRef(function SidebarMenu(
    {
        indexActive,
        onChange,
        menuList,
        full,
        className,
    }: any,
    ref: any
) {
    const scrollableRef = useRef<HTMLDivElement>(null)
    const itemsRef = useRef<Record<string, HTMLDivElement>>({})

    const [sizeHighlight, setSizeHighlight] = useState(0)
    const [leftHighlight, setLeftHighlight] = useState(0)
    const [showFadeout, setShowFadeout] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            if (itemsRef.current && itemsRef.current[indexActive]) {
                setSizeHighlight(itemsRef.current[indexActive].clientWidth)
                setLeftHighlight(itemsRef.current[indexActive].offsetLeft)
            }
        }, 500)
    }, [])

    useEffect(() => {
        if (!scrollableRef.current) return

        const containerScrollable = scrollableRef.current

        if (containerScrollable.clientWidth === containerScrollable.scrollWidth) {
            setShowFadeout(false)
            return
        }

        setShowFadeout(true)

        const listenScroll = () => {
            const scrollPosition = containerScrollable.clientWidth + containerScrollable.scrollLeft
            setShowFadeout(scrollPosition <= containerScrollable.scrollWidth - 50)
        }

        containerScrollable.addEventListener('scroll', listenScroll)
        return () => {
            containerScrollable.removeEventListener('scroll', listenScroll)
        }
    }, [full]);

    useEffect(() => {
        if (!scrollableRef.current) return

        setTimeout(() => {
            if (!scrollableRef.current) return

            const scrollPosition = scrollableRef.current.clientWidth + scrollableRef.current.scrollLeft
            if (itemsRef.current[indexActive]) {
                const itemActive = itemsRef.current[indexActive]
                const itemOffset = itemActive.offsetLeft + itemActive.clientWidth

                // Auto-scroll if active menu item is hidden on screen
                if (scrollPosition < itemOffset) {
                    scrollableRef.current.scrollLeft = scrollableRef.current.scrollWidth - itemActive.offsetLeft + itemActive.clientWidth
                } else if (scrollableRef.current.scrollLeft > 0 && itemActive.offsetLeft < scrollPosition) {
                    scrollableRef.current.scrollLeft = itemActive.offsetLeft - 12
                }

                setSizeHighlight(itemActive.clientWidth)
                setLeftHighlight(itemActive.offsetLeft)

                if (scrollableRef.current.clientWidth !== scrollableRef.current.scrollWidth) {
                    setShowFadeout(!(menuList[menuList.length - 1].id === indexActive))
                }
            }
        }, 0)
    }, [menuList.length, indexActive, full])

    if (menuList.length < 2) return null

    return (
        <nav
            className={clsx(
                'menu menu-sidebar sticky',
                'menu-sidebar--bg',
                showFadeout && 'menu-sidebar--text-fadeout',
                className
            )}
            ref={ref}
        >
            <div
                ref={scrollableRef}
                className="menu menu--white menu--sidebar flex grid grid--40"
            >
                {menuList.map((item: any) => (
                    <div
                        onClick={() => onChange(item.id)}
                        key={item.id}
                        ref={(el) => (itemsRef.current[item.id] = el)}
                        className={clsx('menu__item', indexActive == item.id && 'menu__item--active')}
                    >
                        <span className="menu__title">{item.label}</span>
                    </div>
                ))}
                {sizeHighlight > 0 && (
                    <div
                        className="menu__highlight"
                        style={{
                            width: sizeHighlight,
                            transform: `translateX(${leftHighlight}px)`,
                        }}
                    />
                )}
            </div>
        </nav>
    )
})
