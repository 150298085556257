import Image from 'next/image'
import arrow_first from '../public/img/arrow_first.svg'
import { useTranslation } from 'next-i18next'

export default function HeaderDropdownQual({ className, subtitle }: any) {
    const { t } = useTranslation('common')

    return (
        <div
            className={`dropdown-matches ${className} dropdown-matches--disabled`}
        >
            <span className="dropdown-matches__title dropdown-matches__title--disabled">
                {t('match_odds.no_choice')}
            </span>
            <div className="flex flex-align grid grid--8">
                {subtitle !== undefined && (
                    <div className="handicap-wrapper">{subtitle}</div>
                )}
                <Image
                    src={arrow_first}
                    className="dropdown-matches__ico"
                    width={12}
                    height={12}
                    alt="arrow-first"
                />
            </div>
        </div>
    )
}
