import * as React from 'react'
import { useState } from 'react'

type Props = {
    children: any
    className?: string
}

function Accordion(props: Props) {
    const [activeIndex, setActiveIndex] = useState(null)

    function _setActiveindex(index: number) {
        if (index == activeIndex) setActiveIndex(null)
        else setActiveIndex(index)
    }

    return (
        <div className={`accordion ${props.className}`}>
            {props.children.map((child: any, index: number) =>
                React.cloneElement(child, {
                    key: 'ac' + index,
                    onClick: _setActiveindex,
                    index: index,
                    activeIndex: activeIndex,
                })
            )}
        </div>
    )
}

export default Accordion
