import React from 'react'
import clsx from 'clsx';
import {TeamInMatch} from '../../api';

type Props = {
    team: TeamInMatch,
    displayShort?: boolean,
    highlight?: boolean,
    displayDefault?: boolean,
    font?: 'regular',
    className?: string,
}

export const TeamName = ({
    team,
    displayShort,
    highlight,
    displayDefault,
    font,
    className,
}: Props) => (
    <>
        <span
            className={clsx(
                'TeamName',
                displayShort && 'TeamName--hidden',
                displayDefault && 'TeamName--show',
                highlight && 'color-yellow',
                font && `TeamName--font-${font}`,
                className,
            )}
        >
            {team.title}
        </span>
        <span
            className={clsx(
                'TeamNameShort',
                displayShort && 'TeamNameShort--show',
                displayDefault && 'TeamNameShort--hidden',
                highlight && 'color-yellow',
                font && `TeamNameShort--font-${font}`,
                className
            )}
        >
            {team.short}
        </span>
    </>
)