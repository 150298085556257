import React, {useState} from 'react'
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';
import arrow_right_white from '../../../../public/img/arrow-right-white.svg'
import {MatchLegend, MatchBreakLine, MatchResultCode, MatchStandingsGroup} from '../../../../api';
import { Logo } from '../../../logo';
import styles from './Standings.module.scss';
import { TeamName } from '../../../team';
import { useCustomTranslation } from '../../../../hooks/useCustomTranslation'
import {Tooltip} from '../../../Tooltip';
import Dropdown from '../../../Dropdown';
import HeaderDropdown from '../../../HeaderDropdown';

type Props = {
    groups: MatchStandingsGroup[],
    legends: MatchLegend[],
    blockWidth: number,
    competitionUrl?: string,
    competitionSeason: string,
}

export const Standings = ({  groups, legends, blockWidth, competitionUrl, competitionSeason }: Props) => {
    const { t } = useCustomTranslation('common')

    const [groupActiveIndex, setGroupActiveIndex] = useState(0)
    const groupVariants = groups.map((g, index) => ({
        value: String(index),
        title: g.group_title,
    }))

    const TABLE_LABELS = [
        t('match_table.team'),
        t('match_table.played'),
        t('match_table.w'),
        t('match_table.d'),
        t('match_table.l'),
        [t('match_table.w'), t('match_table.d'), t('match_table.l')],
        t('match_table.goals'),
        t('match_table.points'),
        t('match_table.matches'),
    ]
    return (
        <>
            {groupVariants.length > 1 && (
                <div className='sidebar-content-container flex'>
                    <Dropdown
                        value={String(groupActiveIndex)}
                        options={groupVariants}
                        onChange={(item: any) => setGroupActiveIndex(Number(item.value))}
                        header={<HeaderDropdown />}
                    />
                </div>
            )}
            <div
                className={clsx(
                    'sidebar-content-container flex flex-column',
                    styles.container,
                    blockWidth < 580 && styles.isWidthBelow580,
                    blockWidth < 371 && styles.isWidthBelow371,
                )}
            >
                <div className={clsx(styles.grid, 'px-12 mb-4')}>
                    {TABLE_LABELS.map((label, index) => (
                        <span
                            key={index}
                            className={styles.gridHeaderLabel}
                        >
                            {Array.isArray(label)
                                ? (
                                    label.map((i, index) => (
                                        <span
                                            key={index}
                                            className={styles.labelWithSlash}
                                        >
                                            {i}
                                        </span>
                                    ))
                                )
                                : label
                            }
                        </span>
                    ))}
                </div>
                {(groups[groupActiveIndex]?.table ?? []).map((team) => {
                    const legend = legends.find((i) => i.id === team.legend_id)
                    return (
                        <div
                            className={clsx(styles.grid, styles.team, getBreakLineClass(team.breakline))}
                            key={team.team.id}
                        >
                            <div className={styles.teamContainer}>
                                <Tooltip
                                    content={legend?.tilte}
                                    skipTooltip={!legend}
                                >
                                    <div
                                        className={styles.teamPosition}
                                        style={{
                                            borderColor: legend
                                                ? '#' + legend.color
                                                : undefined,
                                        }}
                                    >
                                        {team.position}
                                    </div>
                                </Tooltip>
                                <Logo
                                    className={styles.teamLogo}
                                    type='teams'
                                    size={36}
                                    logoId={team.team.logo}
                                    dimension={26}
                                    withBorder
                                />
                                <TeamName
                                    team={team.team}
                                    highlight={team.highlight}
                                    displayDefault
                                />
                            </div>
                            <span className='txt-align-center'>{team.data.played}</span>
                            <span className='txt-align-center'>{team.data.won}</span>
                            <span className='txt-align-center'>{team.data.draw}</span>
                            <span className='txt-align-center'>{team.data.lost}</span>
                            <span className='txt-align-center'>
                                <span className={styles.labelWithSlash}>
                                    {team.data.won}
                                </span>
                                <span className={styles.labelWithSlash}>
                                    {team.data.draw}
                                </span>
                                <span className={styles.labelWithSlash}>
                                    {team.data.lost}
                                </span>
                            </span>
                            <span className='txt-align-center'>{team.data.goals}</span>
                            <span className='txt-align-center font-bold'>{team.data.points}</span>
                            <div className={styles.matchesResults}>
                                {team.last_matches.map((match, index) => (
                                    <div
                                        className={clsx(styles.matchLogo, getMatchResultColor(match))}
                                        key={index}
                                    >
                                        {match.toUpperCase()}
                                    </div>
                                ))}
                                {team.next_match ?
                                    <Link href={team.next_match.url}>
                                        <div className={clsx(styles.matchLogo, styles.nextMatch)}>
                                            <Logo
                                                type="teams"
                                                size={36}
                                                logoId={team.next_match.team.logo}
                                                dimension={12}
                                            />
                                        </div>
                                    </Link>
                                    : <div className={clsx(styles.matchLogo)} />
                                }
                            </div>
                        </div>
                    )
                })}
                {competitionUrl && (
                    // @todo Use enum for competition filter - "fixtures" string
                    <Link href={`${competitionUrl}/${competitionSeason}/fixtures`}>
                        <button className='button button--more-odds grid--4'>
                            <span>
                                {t('match_table.full')}
                            </span>
                            <Image
                                src={arrow_right_white}
                                alt=''
                                width={14}
                                height={14}
                            />
                        </button>
                    </Link>
                )}
            </div>
        </>
    )
}

//@todo Replace onto MatchesLastBadges
function getMatchResultColor(matchResult: MatchResultCode) {
    return matchResult === 'w'
        ? styles.colorWin
        : matchResult === 'l'
            ? styles.colorLose
            : ''
}

function getBreakLineClass(breakLine: MatchBreakLine) {
    if (breakLine === 'above' || breakLine === true) return styles.lineTop
    if (breakLine === 'below') return styles.lineBottom
    if (breakLine === 'both') return styles.lineTop + ' ' + styles.lineBottom
    return false
}