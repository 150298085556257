import React from 'react'
import Image from 'next/image'
import StatusBadge from './StatusBadge'
import star_white from '../public/img/star-white.svg'
import star_active from '../public/img/star-active.svg'
import close_matchday from '../public/img/close-matchday.svg'
import full_screen from '../public/img/full-screen.svg'
import minimize from '../public/img/minimize.svg'
import { TeamName } from './team';
import withFavorite, { WithFavoriteProps } from '../hoc/favorite';
import clsx from 'clsx';

type Props = {
    match?: any,
    show: boolean,
    full?: boolean,
    direct?: boolean,
    onToggleSidebar?: (flag: boolean) => void,
}

const MatchInfo = withFavorite<Props>(({
    match,
    show,
    full,
    direct,
    onToggleSidebar,
    favorites,
    toggleFavorite,
}: Props & WithFavoriteProps) => {
    const staticPath: string = 'https://i.oddsocean.com/img/logos/teams/68x68/'
    const isInSidebar = !!onToggleSidebar && !full
    return (
        <div
            className={clsx(
                'wrapper-match-info',
                show && 'wrapper-match-info--open',
                match.live && 'wrapper-match-info--live',
                isInSidebar && 'wrapper-match-info--sidebar-narrow',
            )}
        >
            <div className={clsx('match-info match-info--sticky', show && 'match-info--open')}>
                <div className="flex flex-align">
                    <div className="two-series__content two-series__content--info">
                        {match.live && (
                            <div className="two-series__live">
                                <StatusBadge match={match} dark />
                            </div>
                        )}
                        <div className="two-series__col two-series__col--first">
                            <TeamName
                                team={match.team1}
                                displayShort={isInSidebar}
                            />
                            <Image
                                src={`${staticPath}${match?.team1?.logo}.png`}
                                alt={match?.team1.title}
                                width={26}
                                height={26}
                            />
                        </div>
                        <div className="two-series__score">
                            <span className="two-series__score-t">
                                {(match?.score?.result_short || [])[0] ?? '-'}
                            </span>
                            <span>:</span>
                            <span className="two-series__score-t">
                                {(match?.score?.result_short || [])[1] ?? '-'}
                            </span>
                        </div>
                        <div className="two-series__col">
                            <Image
                                src={`${staticPath}${match?.team2?.logo}.png`}
                                alt={match?.team2.title}
                                width={26}
                                height={26}
                            />
                            <TeamName
                                team={match.team2}
                                displayShort={isInSidebar}
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-align grid grid--4 block-controll">
                    {!match.live && (
                        <button
                            className="button button--matchday-menu button--matchday-menu-top"
                            onClick={() => toggleFavorite('match', match.match_id)}
                        >
                            <Image
                                src={favorites.match.indexOf(match.match_id) >= 0
                                    ? star_active
                                    : star_white
                                }
                                alt=''
                                width={18}
                                height={18}
                            />
                        </button>
                    )}
                    {direct && (
                        <button
                            className='button button--matchday-menu button--matchday-menu-top'
                            onClick={() => onToggleSidebar(false)}
                        >
                            <Image
                                src={close_matchday}
                                alt=''
                                width={16}
                                height={16}
                            />
                        </button>
                    )}
                    {!direct && (
                        <button
                            className='button button--matchday-menu button--matchday-menu-top'
                            onClick={() => onToggleSidebar(!full)}
                        >
                            <Image
                                src={full ? minimize : full_screen}
                                alt=''
                                width={16}
                                height={16}
                            />
                        </button>
                    )}
                </div>
            </div>
        </div>
    )
})

export default MatchInfo