export * from './common'
export * from './matches'

export interface ICompetition {
    type: string
    id: number
    title: string
    url: string
    stage: string
}

export interface ITeam {
    id: number
    logo: string
    title: string
    short: string
}

export interface IScore {
    livetime: string[]
    result: [number, number]
}

export interface ICommandsMatch {
    match_id: boolean | number | string
    url: string
    competition: ICompetition[]
    date_start: number
    team1: ITeam
    team2: ITeam
    color: string
    next_round: number
    score: IScore
}

export enum ResultsTeamTypes {
    HOME = 'home',
    AWAY = 'away',
    ALL = 'all',
    H2H = 'h2h',
}
