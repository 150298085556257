import React from 'react'

const array = [0, 1, 2, 3, 4, 5, 6]
const array2 = [7, 8]
const array3 = [9, 10, 11]

export default function PreloadCenterContent() {
    return (
        <div className="preloader preloader--center">
            <div className="preloader__header"></div>
            {array.map((index: number) => (
                <div
                    key={`content-center-preloader-${index}`}
                    className="preloader__row"
                >
                    <div className="preloader__left-group flex flex-align">
                        <div className="preloader__block preloader__block--time"></div>
                        <div className="preloader__group grid grid--4">
                            <div className="preloader__block preloader__block--team"></div>
                            <div className="preloader__block preloader__block--team"></div>
                        </div>
                        <div className="preloader__group preloader__group--fixed grid grid--10">
                            <div className="preloader__block preloader__block--title preloader__block--title-one"></div>
                            <div className="preloader__block preloader__block--title"></div>
                        </div>
                    </div>
                    <div className="preloader__right-group flex flex-align">
                        <div className="preloader__group">
                            <div className="preloader__block preloader__block--forecast"></div>
                        </div>
                        <div className="preloader__group grid grid--4">
                            <div className="preloader__block preloader__block--score"></div>
                            <div className="preloader__block preloader__block--score"></div>
                        </div>
                        <div className="preloader__block preloader__block--favorite preloader__block--favorite-first"></div>
                        <div className="preloader__block preloader__block--favorite"></div>

                        <div className="preloader__block preloader__block--bet"></div>
                        <div className="preloader__block preloader__block--bet"></div>
                        <div className="preloader__block preloader__block--bet"></div>
                    </div>
                </div>
            ))}
            <div className="preloader__header preloader__header--space"></div>
            {array2.map((index: number) => (
                <div
                    key={`content-center-preloader-${index}`}
                    className="preloader__row"
                >
                    <div className="preloader__left-group flex flex-align">
                        <div className="preloader__block preloader__block--time"></div>
                        <div className="preloader__group grid grid--4">
                            <div className="preloader__block preloader__block--team"></div>
                            <div className="preloader__block preloader__block--team"></div>
                        </div>
                        <div className="preloader__group preloader__group--fixed grid grid--10">
                            <div className="preloader__block preloader__block--title preloader__block--title-one"></div>
                            <div className="preloader__block preloader__block--title"></div>
                        </div>
                    </div>
                    <div className="preloader__right-group flex flex-align">
                        <div className="preloader__group">
                            <div className="preloader__block preloader__block--forecast"></div>
                        </div>
                        <div className="preloader__group grid grid--4">
                            <div className="preloader__block preloader__block--score"></div>
                            <div className="preloader__block preloader__block--score"></div>
                        </div>
                        <div className="preloader__block preloader__block--favorite preloader__block--favorite-first"></div>
                        <div className="preloader__block preloader__block--favorite"></div>

                        <div className="preloader__block preloader__block--bet"></div>
                        <div className="preloader__block preloader__block--bet"></div>
                        <div className="preloader__block preloader__block--bet"></div>
                    </div>
                </div>
            ))}
            <div className="preloader__header preloader__header--space"></div>
            <div className="preloader__row">
                <div className="preloader__left-group flex flex-align">
                    <div className="preloader__block preloader__group--fixed preloader__block--time"></div>
                    <div className="preloader__group grid grid--4">
                        <div className="preloader__block preloader__block--team"></div>
                        <div className="preloader__block preloader__block--team"></div>
                    </div>
                    <div className="preloader__group grid grid--10">
                        <div className="preloader__block preloader__block--title preloader__block--title-one"></div>
                        <div className="preloader__block preloader__block--title"></div>
                    </div>
                </div>
                <div className="preloader__right-group flex flex-align">
                    <div className="preloader__group">
                        <div className="preloader__block preloader__block--forecast"></div>
                    </div>
                    <div className="preloader__group grid grid--4">
                        <div className="preloader__block preloader__block--score"></div>
                        <div className="preloader__block preloader__block--score"></div>
                    </div>
                    <div className="preloader__block preloader__block--favorite preloader__block--favorite-first"></div>
                    <div className="preloader__block preloader__block--favorite"></div>

                    <div className="preloader__block preloader__block--bet"></div>
                    <div className="preloader__block preloader__block--bet"></div>
                    <div className="preloader__block preloader__block--bet"></div>
                </div>
            </div>
            <div className="preloader__header preloader__header--space"></div>
            {array3.map((index: number) => (
                <div
                    key={`content-center-preloader-${index}`}
                    className="preloader__row"
                >
                    <div className="preloader__left-group flex flex-align">
                        <div className="preloader__block preloader__block--time"></div>
                        <div className="preloader__group grid grid--4">
                            <div className="preloader__block preloader__block--team"></div>
                            <div className="preloader__block preloader__block--team"></div>
                        </div>
                        <div className="preloader__group preloader__group--fixed grid grid--10">
                            <div className="preloader__block preloader__block--title preloader__block--title-one"></div>
                            <div className="preloader__block preloader__block--title"></div>
                        </div>
                    </div>
                    <div className="preloader__right-group flex flex-align">
                        <div className="preloader__group">
                            <div className="preloader__block preloader__block--forecast"></div>
                        </div>
                        <div className="preloader__group grid grid--4">
                            <div className="preloader__block preloader__block--score"></div>
                            <div className="preloader__block preloader__block--score"></div>
                        </div>
                        <div className="preloader__block preloader__block--favorite preloader__block--favorite-first"></div>
                        <div className="preloader__block preloader__block--favorite"></div>

                        <div className="preloader__block preloader__block--bet"></div>
                        <div className="preloader__block preloader__block--bet"></div>
                        <div className="preloader__block preloader__block--bet"></div>
                    </div>
                </div>
            ))}
        </div>
    )
}
